addEventListener("direct-upload:initialize", (event) => {
  const { target, detail } = event as CustomEvent;
  const { id, file } = detail;

  if (target) {
    (target as HTMLElement).insertAdjacentHTML(
      "beforebegin",
      `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <span class="direct-upload__filename">${file.name}</span>
      </div>
    `
    );
  }
});

addEventListener("direct-upload:start", (event) => {
  const { id } = (event as CustomEvent).detail;
  const element = document.getElementById(`direct-upload-${id}`);

  element?.classList.remove("direct-upload--pending");
});

addEventListener("direct-upload:progress", (event) => {
  const { id, progress } = (event as CustomEvent).detail;
  const progressElement = document.getElementById(`direct-upload-progress-${id}`);

  if (progressElement) {
    progressElement.style.width = `${progress}%`;
  }
});

addEventListener("direct-upload:error", (event) => {
  event.preventDefault();
  const { id, error } = (event as CustomEvent).detail;
  const element = document.getElementById(`direct-upload-${id}`);

  element?.classList.add("direct-upload--error");
  element?.setAttribute("title", error);
});

addEventListener("direct-upload:end", (event) => {
  const { id } = (event as CustomEvent).detail;
  const element = document.getElementById(`direct-upload-${id}`);

  element?.classList.add("direct-upload--complete");
});

export {};
