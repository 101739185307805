import { Controller } from "@hotwired/stimulus";
import { destroy } from "@rails/request.js";

// Connects to data-controller="attachment-delete"
export default class extends Controller {
  static values = {
    url: String,
    entityId: { type: Number, default: null }
  };

  declare urlValue: string;
  declare entityIdValue: number;

  deleteAttachment(event: Event) {
    if (!(confirm("Are you sure you want to delete this attachment?"))) {
      event.preventDefault();
      event.stopImmediatePropagation();
    } else {
      destroy(
        this.urlValue,
        {
          body: JSON.stringify({
            entity_id: this.entityIdValue
          }),
          responseKind: "turbo-stream",
        }
      ).then((_response) => {
        this.element.closest('li').remove();
      });
    }
  }
}
