import { Toggle } from "tailwindcss-stimulus-components";

// Connects to data-controller="toggle-permissions"
export default class extends Toggle {
  declare openValue: boolean;

  toggle(event) {
    const input = event.target as HTMLInputElement;

    if (input.dataset.open === "true" && !this.openValue) {
      super.show();
    } else if (this.openValue) {
      super.hide();
    }
  }
}
