import { Controller } from "@hotwired/stimulus"
import { toggleButton } from "../helpers/button_helpers"

// Connects to data-controller="payable-type"
export default class extends Controller {
  declare buttonTargets: HTMLButtonElement[]
  declare hiddenTypeInputTarget: HTMLInputElement;

  static targets = ['button', 'hiddenTypeInput']

  selectedButton: HTMLButtonElement | null = null;

  toggleButton(event: Event) {
    if (this.selectedButton === event.target) {
      this.selectedButton = this.buttonTargets[0];
      toggleButton(this.buttonTargets, { target: this.buttonTargets[0] });
      this.hiddenTypeInputTarget.value = null;
    } else {
      this.selectedButton = event.target as HTMLButtonElement;
      toggleButton(this.buttonTargets, event);
      this.hiddenTypeInputTarget.value = this.selectedButton.id.replace("payables-status-", "");
    }

    this.dispatch("statusUpdated");
  }
}