import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="ownership-to-partnership"
export default class extends Controller {
  static targets = [
    "ownershipRelationships",
    "entity",
    "startDate",
    "endDate",
    "partnershipId",
    "addAllocationsCheckbox",
    "destroy",
    "fullConsolidationOwnerAlert",
    "fullConsolidationOwnerSelect",
    "consolidationMethodRadio"
  ];
  static values = {
    entity: Number,
  };

  declare ownershipRelationshipsTargets: HTMLElement[];
  declare partnershipIdTarget: HTMLInputElement;
  declare entityValue: number;
  declare addAllocationsCheckboxTarget: HTMLInputElement;
  declare fullConsolidationOwnerAlertTarget: HTMLElement;
  declare fullConsolidationOwnerSelectTarget: HTMLSelectElement;
  declare consolidationMethodRadioTargets: HTMLInputElement[];

  connect(): void {
    this.identifyOwnerships();
  }

  scrollTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  scrollTopSlideOver(): void {
    const target: HTMLElement = document.querySelector('[data-id="slideover-target"]');
    target.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  clearValidationErrors(): void {
    this.clearErrorHints()
    this.clearErrorClasses()
    this.clearMainErrorDescription()
  }

  clearErrorHints(): void {
    const hintElements: NodeList = document.querySelectorAll('.form-hint.error')
    hintElements.forEach((element: HTMLElement) => { element.remove() })
  }

  clearErrorClasses(): void {
    const inputElements: NodeList = document.querySelectorAll('.error')
    if (inputElements.length <= 0) return
    inputElements.forEach((element: HTMLElement) => { element.classList.remove('error') })
  }

  clearMainErrorDescription(): void {
    const errorMessageContainer: HTMLElement = document.getElementById('error-messages')
    if (errorMessageContainer == null) return
    errorMessageContainer.remove()
  }

  identifyOwnerships() {
    const entityIdsAndNamesForOptions = {};
    const selectedEntityId = this.fullConsolidationOwnerSelectTarget.value;

    this.ownershipRelationshipsTargets.forEach((ownershipRelationship) => {
      const entityId = this.getValueForRelationship(ownershipRelationship, "entityId");

      // If there is an entity id, we allow the user to specify allocations/partnerships
      if (entityId) {
        this.addAllocationsCheckboxTarget.disabled = false;

        const destroy = this.getValueForRelationship(ownershipRelationship, "destroy");
        if (destroy != "true") { // Add the entity name and id as options to the full consolidation owner select
          const entityName = this.getSelectTextForRelationship(ownershipRelationship, "entityId");
          entityIdsAndNamesForOptions[entityId] = entityName;
        }
      }
    });

    if (this.ownershipRelationshipsTargetsNotSetToBeDestroyed().length > 1 && this.getSelectedConsolidationMethod() === "full") {
      this.reloadConsolidationOwnerSelect(entityIdsAndNamesForOptions, selectedEntityId);
      this.fullConsolidationOwnerAlertTarget.classList.remove("hidden");
    } else {
      this.hideAndClearConsolidationOwner();
    }
  }

  async addPartnership() {
    const ownerships = [];

    this.ownershipRelationshipsTargetsNotSetToBeDestroyed().forEach((ownershipRelationship) => {
      if (ownershipRelationship.children.length == 0) {
        return;
      }
      const entityId = this.getValueForRelationship(ownershipRelationship, "entityId");

      if (entityId) {
        ownerships.push({
          entity_id: entityId,
          starts_at: this.getValueForRelationship(ownershipRelationship, "startDate"),
          ends_at: this.getValueForRelationship(ownershipRelationship, "endDate"),
          remove: this.getValueForRelationship(ownershipRelationship, "destroy"),
        });
      }
    });

    if (ownerships.length > 0) {
      await post("/entity_management/partnership_form", {
        body: {
          id: this.partnershipIdTarget.value || null,
          entity_id: this.entityValue,
          has_partnership_checked: this.addAllocationsCheckboxTarget.checked,
          ownerships: ownerships,
        },
        responseKind: "turbo-stream",
      });
    }
  }

  getValueForRelationship(ownershipRelationship: HTMLElement, dataTarget: string): string {
    return ownershipRelationship.querySelector<HTMLInputElement>(
      `[data-ownership-to-partnership-target="${dataTarget}"]`
    ).value;
  }

  getSelectTextForRelationship(ownershipRelationship: HTMLElement, dataTarget: string): string {
    const targetNode = ownershipRelationship.querySelector<HTMLSelectElement>(
      `[data-ownership-to-partnership-target="${dataTarget}"]`
    );

    const selectedOption = targetNode.options[targetNode.selectedIndex];
    return selectedOption.text;
  }

  async removeOwnership(event: Event) {
    const ownershipRelationship = (event.target as HTMLElement).closest<HTMLElement>(
      "[data-ownership-to-partnership-target='ownershipRelationships']"
    );

    const entityId = this.getValueForRelationship(ownershipRelationship, "entityId");

    if (entityId) {
      // remove the option for this entity from the full consolidation owner select
      const option = this.fullConsolidationOwnerSelectTarget.querySelector(`option[value="${entityId}"]`);
      option?.remove();

      // if there are no options left, disable the checkbox
      if (this.fullConsolidationOwnerSelectTarget.options.length == 0) {
        this.addAllocationsCheckboxTarget.disabled = true;
      }
    }

    if (ownershipRelationship) {
      ownershipRelationship.classList.add("hidden");
    }

    this.setValueForRelationship(ownershipRelationship, "destroy", "true");

    // hide the full consolidation owner select if there are less than 2 owners after removing this one
    if (this.ownershipRelationshipsTargetsNotSetToBeDestroyed().length < 2) {
      this.hideAndClearConsolidationOwner();
    }

    this.addPartnership();
  }

  ownershipRelationshipsTargetsNotSetToBeDestroyed(): HTMLElement[] {
    return this.ownershipRelationshipsTargets.filter((ownershipRelationship) => {
      return this.getValueForRelationship(ownershipRelationship, "destroy") !== "true";
    });
  }

  setValueForRelationship(ownershipRelationship: HTMLElement, dataTarget: string, value: string) {
    const targetNode = ownershipRelationship.querySelector(
      `[data-ownership-to-partnership-target="${dataTarget}"]`
    ) as HTMLInputElement;

    targetNode.value = value;
  }

  addOptionToFullConsolidationOwnerSelect(value, text, selected: boolean = false) {
    const option = document.createElement("option");
    option.value = value;
    option.text = text;
    option.selected = selected;
    this.fullConsolidationOwnerSelectTarget.add(option);
  }

  hideAndClearConsolidationOwner() {
    this.fullConsolidationOwnerSelectTarget.innerHTML = "";
    this.addOptionToFullConsolidationOwnerSelect("", "");
    this.fullConsolidationOwnerSelectTarget.value = "";
    this.fullConsolidationOwnerAlertTarget.classList.add("hidden");
  }

  reloadConsolidationOwnerSelect(entityIdsAndNamesForOptions: object, selectedEntityId: string) {
    if (Object.keys(entityIdsAndNamesForOptions).length > 0) {
      this.fullConsolidationOwnerSelectTarget.innerHTML = "";

      const entityIdsToAddToOptions = Object.keys(entityIdsAndNamesForOptions);
      entityIdsToAddToOptions.forEach((entityId) => {
        const isSelected = entityId === selectedEntityId;
        this.addOptionToFullConsolidationOwnerSelect(entityId, entityIdsAndNamesForOptions[entityId], isSelected);
      });
    }
  }

  getSelectedConsolidationMethod(): string | undefined {
    return Array.from(this.consolidationMethodRadioTargets).find(r => r.checked)?.value;
  }
}
