import { post, get } from "@rails/request.js";
import { getSelectValues } from "./index";
import { ReportContext } from "../../types";
import { ValueGetterParams } from "ag-grid-enterprise";

export function addAutoLoadParamToUrl() {
  // Skip if the url has the auto_load or report_template_id params
  if (window.location.search.includes("auto_load") || window.location.search.includes("report_template_id")) {
    return;
  }

  const url = new URLSearchParams(window.location.search);
  url.set("auto_load", "true");
  window.history.replaceState({}, document.title, window.location.origin + window.location.pathname + "?" + url);
}

export function calculatePercentChangeTotal({ priorPeriodTotal, changeTotal }) {
  let percentChangeTotal;

  if (!priorPeriodTotal || priorPeriodTotal == 0) {
    percentChangeTotal = "N/A";
  } else {
    percentChangeTotal = changeTotal / Math.abs(priorPeriodTotal);
  }

  return percentChangeTotal;
}

export function validateDateSelected(context: ReportContext) {
  const parentNode = context.dateRangeTarget.parentNode as HTMLElement;

  if (context.endDateTarget.value === "") {
    context.dateErrorsTarget.classList.remove("hidden");
    context.dateErrorsTarget.innerText = "Please select a reporting period";
    parentNode.classList.add("border-2", "border-rose-500", "rounded-md");
    context.startDateTarget.classList.add("border-2", "border-rose-500", "rounded-md");
    context.endDateTarget.classList.add("border-2", "border-rose-500", "rounded-md");
    context.gridApi.showNoRowsOverlay();

    return false;
  } else {
    if (!context.dateErrorsTarget.classList.contains("hidden")) {
      context.dateErrorsTarget.classList.add("hidden");
    }
    parentNode.classList.remove("border-2", "border-rose-500", "rounded-md");
    context.startDateTarget.classList.remove("border-2", "border-rose-500", "rounded-md");
    context.endDateTarget.classList.remove("border-2", "border-rose-500", "rounded-md");
  }

  return true;
}

// Sets the date and options display in the rendered report
export function setDateAndAlertDisplay(context, data) {
  if (context.endDateTarget.value) {
    context.dateDisplayTarget.innerText = context.reportDateHeader();
    context.dateDisplayTarget.classList.remove("hidden");
  }

  const optionsArray = [];
  context.optionTargets.forEach((option) => {
    if (option.checked && option.dataset.alertName) {
      optionsArray.push(option.dataset.alertName);
    }
  });

  let alertsArray = [];

  if (optionsArray.length > 0) {
    alertsArray.push(`Includes ${optionsArray.join(", ")}`);
  }

  if (data && data["alerts"]) {
    alertsArray = alertsArray.concat(data.alerts);
  }

  if (alertsArray.length > 0) {
    context.alertDisplayTarget.innerHTML = alertsArray
      .map(
        (alert) =>
          `<span class="px-3 py-1 font-medium text-sm bg-orange-100 text-orange-600 rounded-full">${alert}</span>`,
      )
      .join(" ");
    context.alertDisplayTarget.classList.remove("hidden");
  } else {
    context.alertDisplayTarget.innerHTML = "";
    context.alertDisplayTarget.classList.add("hidden");
  }
}

export function clearNotices() {
  const reportNoticesElement = document.querySelector("#report_notices");
  if (reportNoticesElement) {
    document.querySelector("#report_notices").innerHTML = "";
  }
}

export async function requestReportData(body) {
  clearNotices();
  const url = `${window.location.origin + window.location.pathname}_data`;
  const result = await post(url, {
    body: body,
    responsekind: "json",
  });
  return result;
}

export async function processReportDataResponse(context: ReportContext, response, callback) {
  if (response.ok) {
    hideErrorOverlay(context);
    const data = await response.json;
    return callback(data);
  } else if (response.statusCode === 500) {
    showErrorOverlay(context);
  } else {
    const data = await response.json;
    renderReportNotices(context, data);
  }
}

function createErrorOverlay(): HTMLElement {
  const overlay = document.createElement("div");
  overlay.id = "error-overlay";
  overlay.className = "flex";
  const overlayTemplate = document.getElementById("error-overlay-template") as HTMLTemplateElement;
  overlay.innerHTML = overlayTemplate.content.firstElementChild.outerHTML;
  return overlay;
}

function showErrorOverlay(context: ReportContext) {
  let overlay = document.getElementById("error-overlay");

  if (!overlay) {
    overlay = createErrorOverlay();
  } else {
    overlay.remove();
  }

  if (context.gridTarget) {
    context.gridTarget.style.position = "relative";
    if (!context.gridTarget.contains(overlay)) {
      context.gridTarget.appendChild(overlay);
    }
    overlay.style.display = "flex";
  }
}

function hideErrorOverlay(context: ReportContext) {
  const overlay = document.getElementById("error-overlay");

  if (overlay && context.gridTarget.contains(overlay)) {
    context.gridTarget.removeChild(overlay);
  }
}

function renderReportNotices(context: ReportContext, data) {
  context.gridApi.showNoRowsOverlay();
  const generalNoticeElement = document.querySelector("#general_notice");
  if (generalNoticeElement) {
    generalNoticeElement.classList.remove("hidden");
    document.querySelector("#general_notice p").innerHTML = data.errors.join("<br>");
  }
}

export function validateEntitySet(context: ReportContext) {
  if (getSelectValues(context.ledgersTarget).filter((v) => v).length === 0) {
    context.ledgerErrorsTarget.classList.remove("hidden");
    context.ledgerErrorsTarget.innerText = "This field is required";
    context.ledgersTarget.nextElementSibling.classList.add("border-2", "border-rose-500", "rounded-md");

    context.gridApi.showNoRowsOverlay();
    return false;
  } else {
    if (!context.ledgerErrorsTarget.classList.contains("hidden")) {
      context.ledgerErrorsTarget.classList.add("hidden");
    }
    context.ledgersTarget.nextElementSibling.classList.remove("border-2", "border-rose-500", "rounded-md");
  }

  return true;
}

export function hasOption(context: ReportContext, optionName: string): boolean {
  return context.optionTargets.some((option) => option.name === optionName);
}

export function getOptionState(context: ReportContext, optionName: string): boolean {
  return context.optionTargets.find((option) => option.name === optionName).checked;
}

export function numberValueGetter(params: ValueGetterParams) {
  const columnField = params.colDef.field;

  // Coerce strings to numbers in order for the aggregation function to work
  if (params.data) {
    const val = Number(params.data[columnField]);

    if (Number.isNaN(val)) {
      return null;
    } else {
      return val;
    }
  }
}

export function updateBreadcrumb(reportTemplateId: string | null, element: HTMLElement) {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set("report_template_id", reportTemplateId);
  queryParams.set("report_type", element.dataset.railsAction);

  get(`/reporting/featured_reports/breadcrumb?${queryParams.toString()}`, {
    responseKind: "turbo-stream",
  });
}

function arraysEqual(a: string[], b: string[]): boolean {
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}

export function shouldRefreshGrouping(
  existingRowData: { id: string; accountBreadCrumbs: string[] }[],
  newRowData: { id: string; accountBreadCrumbs: string[] }[],
): boolean {
  // Create a map of existing data for quick lookup
  const existingDataMap = new Map(existingRowData.map((row) => [row.id, row]));

  for (const newRow of newRowData) {
    const existingRow = existingDataMap.get(newRow.id);

    // If the row doesn't exist in the existing data, we need to refresh
    if (!existingRow) {
      return true;
    }

    // Compare accountBreadCrumbs
    if (!arraysEqual(existingRow.accountBreadCrumbs, newRow.accountBreadCrumbs)) {
      return true;
    }
  }

  // If we've made it this far, no changes were found
  return false;
}
