import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="tax-classification-select"
export default class extends Controller {
  static targets = ["section", "source", "taxClassificationSelect"];

  static values = {
    taxClassificationSelectUrl: String
  };

  declare sourceTarget: HTMLInputElement;
  declare sectionTargets: HTMLDivElement[];
  declare taxClassificationSelectTarget: HTMLSelectElement;
  declare hasTaxClassificationSelectTarget: boolean;
  declare taxClassificationSelectUrlValue: string;

  connect() {
    const startingValue = this.handleInputAmbiguity(this.sourceTarget);

    this.toggleVisibility(startingValue);
  }

  handleChange(event) {
    const selectedValue = this.handleInputAmbiguity(event.target);

    this.toggleVisibility(selectedValue);

    if (this.hasTaxClassificationSelectTarget) {
      this.taxClassificationSelectTarget.innerHTML = "";
    }
  }

  toggleVisibility(value) {
    this.sectionTargets.forEach((section) => {
      const showWhens = section.dataset.showWhen.split(",").map((showWhen) => showWhen.trim());

      if (this.willDisplaySection(value, showWhens) && this.shouldMakeRequest(section)) {
        this.getTaxClassificationSelectOptions(value);
      }

      showWhens.forEach(() => {
        if (this.willDisplaySection(value, showWhens)) {
          section.classList.remove("hidden");
        } else {
          section.classList.add("hidden");
        }
      });
    });
  }

  shouldMakeRequest(section) {
    return section.dataset.requestSelect === "true";
  }

  willDisplaySection(value, showWhens) {
    return value === true || (showWhens.includes(value) && value !== "");
  }

  getTaxClassificationSelectOptions(value) {
    const url = new URL(this.taxClassificationSelectUrlValue);
    const params = new URLSearchParams(url.search);
    params.append("entity_type", value);
    url.search = params.toString();
    get(url.toString(), {
      responseKind: "turbo-stream",
    });
  }

  handleInputAmbiguity(target) {
    // If target is a checkbox, use that
    if (target.getAttribute("type") === "checkbox") {
      return target.checked;
    } else {
      return target.value;
    }
  }
}
