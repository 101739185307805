import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

// Connects to data-controller="account-select-for-ledger"
export default class extends Controller {
  static targets = ["accountSelect", "ledgerSelect"];

  static values = {
    url: String,
    classes: Array,
    posted: Boolean,
    cashAccountsOnly: { type: Boolean, default: false },
    accountTemplateId: Number
  };

  declare accountSelectTargets: HTMLSelectElement[];
  declare ledgerSelectTarget: HTMLSelectElement;
  declare urlValue: string;
  declare hasClassesValue: boolean;
  declare classesValue: string[];
  declare postedValue: boolean;
  declare cashAccountsOnlyValue: boolean;
  declare accountTemplateIdValue: number;

  ledgerChanged(_event: Event) {
    this.accountSelectTargets.forEach((selectTarget: HTMLSelectElement) => {
      this.getSelectTargetTurboFrame(selectTarget);
    });
  }

  getSelectTargetTurboFrame(element: HTMLSelectElement) {
    const selectedLedgerId = this.ledgerSelectTarget.hasChildNodes()
      ? this.ledgerSelectTarget.selectedOptions[0].value
      : this.ledgerSelectTarget.value;
    const selectedAccountId = element.hasChildNodes() ? element.selectedOptions[0].value : "";

    if (selectedLedgerId === "") {
      return;
    }

    // Don't reload if the ledger hasn't changed
    if (selectedLedgerId === element.dataset.ledgerId) {
      return;
    }

    const query = {
      ledger_id: selectedLedgerId,
      target: element.id,
      name: element.name,
      selected_account_id: selectedAccountId,
      cash_accounts_only: this.cashAccountsOnlyValue,
      account_template_id: this.accountTemplateIdValue,
    };

    if (this.hasClassesValue) {
      query["classes[]"] = this.classesValue;
    }

    get(this.urlValue, {
      query: query,
      responseKind: "turbo-stream",
    });
  }

  accountSelectTargetConnected(element: HTMLElement) {
    if (this.postedValue) {
      return;
    }

    this.getSelectTargetTurboFrame(element as HTMLSelectElement);
  }
}
