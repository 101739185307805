import CheckboxSelectAll from "stimulus-checkbox-select-all";
import SelectAllTopCheckboxController from "./select_all_top_checkbox_controller";

// Overriding some of the functionality of the StimulusCheckboxSelectAll library so we can include a select all checkbox at the top of the list
// The logic for that Select All checkbox will be controlled by the SelectAllTopCheckboxController
export default class extends CheckboxSelectAll {
  static outlets = ["select-all-top-checkbox"]

  declare selectAllTopCheckboxOutlet: SelectAllTopCheckboxController;
  declare hasSelectAllTopCheckboxOutlet: boolean;
  declare checkboxTargets: HTMLInputElement[];

  connect(): void {
    this.updateParentCheckboxState(null);
  }

  get allCheckboxTargets(): HTMLInputElement[] {
    return this.findAllCheckboxes(this.element as HTMLElement);
  }

  findAllCheckboxes(element: HTMLElement): HTMLInputElement[] {
    const checkboxes: HTMLInputElement[] = Array.from(element.querySelectorAll('input[type="checkbox"]'));
    element.querySelectorAll('[data-controller="checkbox-select-all"]').forEach(childControllerElement => {
      checkboxes.push(...this.findAllCheckboxes(childControllerElement as HTMLInputElement));
    });
    return checkboxes;
  }

  triggerInputEvent(checkbox: HTMLInputElement): void {
    const event = new Event('input', { bubbles: false, cancelable: true })

    checkbox.dispatchEvent(event);
  }

  toggle(e: Event): void {
    e.preventDefault()

    this.allCheckboxTargets.forEach(checkbox => {
      // @ts-ignore
      checkbox.checked = e.target.checked
      this.triggerInputEvent(checkbox)
    })
    if (this.hasSelectAllTopCheckboxOutlet) {
      // @ts-ignore
      this.selectAllTopCheckboxOutlet.refresh()
    }

    this.updateParentCheckboxState(null);

    // @ts-ignore
    this.dispatch("checked")
  }

  updateParentCheckboxState(checkbox: HTMLInputElement): void {
    const parentCheckbox = this.element.querySelector('input[type="checkbox"]') as HTMLInputElement;

    if (parentCheckbox) {
      const allCheckboxes = this.allCheckboxTargets.filter(checkbox => checkbox !== parentCheckbox);

      const allChecked = allCheckboxes.every(checkbox => checkbox.checked);
      const someChecked = allCheckboxes.some(checkbox => checkbox.checked);

      if (parentCheckbox !== checkbox) {
        parentCheckbox.checked = allChecked;
        parentCheckbox.indeterminate = !allChecked && someChecked;
      }
    }

    const parentControllerElement = (this.element.parentNode as HTMLElement).closest('[data-controller="checkbox-select-all toggle"]');
    if (parentControllerElement && parentControllerElement !== this.element) {
      const parentController = this.application.getControllerForElementAndIdentifier(parentControllerElement, 'checkbox-select-all');
      if (parentController && typeof (parentController as this).updateParentCheckboxState === 'function') {
        (parentController as this).updateParentCheckboxState(null);
      }
    }
  }

  checkboxUpdated(event: CustomEvent): void {
    this.updateParentCheckboxState(event.detail.checkbox);
    event.stopPropagation();
  }
}
