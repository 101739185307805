addEventListener("turbo:frame-missing", async (event: CustomEvent) => {
  event.preventDefault();
  // Latest PR hides this https://github.com/hotwired/turbo/pull/863
  const response = event.detail.response;

  // Show a helpful error message if the frame is missing, but redirect
  // if theres a server error or if the response was redirected.
  if (response.status < 500) {
    const target = event.target as HTMLDivElement;

    console.error(`A matching frame for #${target.id} was missing from response.`);

    if (response.redirected) {
      console.warn(`Turbo redirecting to ${response.url}`);

      event.detail.visit(response);
    } else {
      target.innerHTML = `<strong class="turbo-frame-error bg-red-300">There was an error in this request. Error code: ${target.id}-turbo-frame-missing.</strong>`;
    }
  } else {
    event.detail.visit(response);
  }
});

export {};
