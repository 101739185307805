// Example usage:
// <div data-controller="stimulus-tooltip" data-tippy-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus";
import tippy, { Instance as TippyInstance } from "tippy.js";
import "tippy.js/themes/light.css";

export default class extends Controller {
  static targets = ["button"];

  static values = {
    content: String,
    theme: String,
    allowHtml: Boolean,
    maxWidth: Number,
  };

  declare tippy: TippyInstance;
  declare hasContentValue: boolean;
  declare themeValue: string;
  declare hasThemeValue: boolean;
  declare allowHtmlValue: boolean;
  declare hasAllowHtmlValue: boolean;
  declare contentValue: string | undefined;
  declare hasMaxWidthValue: boolean;
  declare maxWidthValue: number | undefined;
  declare hasButtonTarget: boolean;
  declare buttonTarget: HTMLButtonElement | undefined;

  connect() {
    const options = {};

    if (this.hasContentValue) {
      options["content"] = this.contentValue;
    }

    if (this.hasThemeValue) {
      options["theme"] = this.themeValue;
    }

    if (this.hasMaxWidthValue) {
      options["maxWidth"] = this.maxWidthValue;
    }

    if (this.hasAllowHtmlValue) {
      options["allowHTML"] = this.allowHtmlValue;
    }

    options["onCreate"] = (instance) => {
      const tooltip = instance.popper.querySelector('.tippy-content');
      if (tooltip) {
        tooltip.style.fontSize = (this.element as HTMLElement).dataset.tippyFontSize || "inherit";
      }
    }

    if (this.hasButtonTarget) {
      this.tippy = tippy(this.buttonTarget, options);
    } else {
      this.tippy = tippy(this.element, options);
    }
  }

  handleToggleEvent(event) {
    const enabled = event.detail.enabled;

    if (enabled) {
      this.enable();
    } else {
      this.disable();
    }
  }

  show() {
    this.tippy.show();
  }

  hide() {
    this.tippy.hide();
  }

  disable() {
    this.tippy.disable();
  }

  enable() {
    this.tippy.enable();
  }

  disconnect() {
    this.tippy.destroy();
  }
}
