import { IOneSchemaOverridesResponseData } from "../../types";

export const processSchemaOverridesResponse = (responseData: IOneSchemaOverridesResponseData) => {
  const { template_overrides } = responseData;
  const { accounts, entities, tags, account_codes } = template_overrides;

  const accountOverrides = accounts.map((account) => {
    return {
      value: account.number_and_name,
      color: "#028698",
      alternative_names: [account.number, account.name, account.number_and_name],
      prefix_id: account.prefix_id,
    };
  });

  const entityOverrides = entities.map((entity) => {
    return { value: entity.name, color: "#595959", prefix_id: entity.prefix_id };
  });

  const tagOverrides = tags.map((tag) => {
    return {
      value: tag.full_name,
      color: tag.color_bg,
      alternative_names: [tag.name, ` ${tag.name}`, `${tag.name} `, ` ${tag.name} `, tag.full_name],
      prefix_id: tag.prefix_id,
    };
  });

  const accountCodeOverrides = account_codes.map((accountCode) => {
    return {
      value: accountCode.full_name,
      color: "#2F7AEB",
      alternative_names: [accountCode.name, accountCode.full_name],
      prefix_id: accountCode.prefix_id,
    };
  });

  return {
    accountOverrides: accountOverrides,
    entityOverrides: entityOverrides,
    tagOverrides: tagOverrides,
    accountCodeOverrides: accountCodeOverrides,
  };
};
