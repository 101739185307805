import { Controller } from "@hotwired/stimulus";
import { post, destroy } from "@rails/request.js";

// Connects to data-controller="payable-line-items"
export default class extends Controller {
  static targets = ["vendorSelect", "lineItem", "accountCodeInput", "tagInput"];

  declare vendorSelectTarget: HTMLSelectElement;
  declare selectedVendorIdValue: string;
  declare lineItemTargets: HTMLElement[];
  declare accountCodeInputTargets: HTMLElement[];
  declare tagInputTargets: HTMLElement[];
  declare hasLineItemTarget: boolean;

  vendorSelectTargetConnected(element) {
    const value = element.selectedOptions[0].value;
    this.selectedVendorIdValue = value;
  }

  vendorSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    this.selectedVendorIdValue = value;
    this.updateLineItems(event);
  }

  lineItemId(element) {
    const input = element.querySelector(
      `#payable_payable_line_items_attributes_${element.dataset.lineItemIndex}_id`
    ) as HTMLInputElement
    return input.value;
  }

  updateLineItems(_event?: Event) {
    let lineItemIndexes = [];
    let lineItemIds = [];
    if (this.hasLineItemTarget) {
      lineItemIndexes = this.lineItemTargets.map( (lineItem: HTMLDivElement) => {
        return parseInt(lineItem.dataset.lineItemIndex);
      });
      lineItemIds = this.lineItemTargets.map( (lineItem: HTMLDivElement) => {
        return parseInt(this.lineItemId(lineItem));
      });
    }

    post(this.vendorSelectTarget.dataset.lineItemUrl, {
      body: {
        turbo_stream_action: "replace",
        indexes: lineItemIndexes,
        line_item_ids: lineItemIds,
        vendor_id: this.selectedVendorIdValue,
      },
      responseKind: "turbo-stream",
    });
  }

  addLineItem(event: Event) {
    event.preventDefault();
    const button = event.currentTarget as HTMLButtonElement;

    post(button.dataset.url, {
      body: {
        turbo_stream_action: "append",
        index: parseInt(button.dataset.index),
        vendor_id: this.selectedVendorIdValue,
      },
      responseKind: "turbo-stream",
    });
  }

  removeLineItem(event: Event) {
    event.preventDefault();
    const button = event.currentTarget as HTMLButtonElement;

    destroy(button.dataset.url, {
      body: { line_item_id: parseInt(button.dataset.lineItemId) },
      responseKind: "turbo-stream",
    });
  }
}
