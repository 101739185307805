import { Turbo } from "@hotwired/turbo-rails";
import { post, put, get } from "@rails/request.js";
import { requestReportData, processReportDataResponse } from "./utils";
import { ReportContext } from "../../types";
import { addRelativeDatesToParams } from "../relative_dates";

export async function saveReportTemplate(
  reportTemplateData: { report_type: string; report_options: object },
  reportTemplateType: string,
  reportTemplateName: string,
) {
  const response = await post("/reporting/report_templates", {
    body: {
      report_template: {
        name: reportTemplateName,
        report_type: reportTemplateType,
        options: reportTemplateData.report_options,
      },
    },
    responseKind: "turbo-stream",
  });

  if (response.statusCode === 201) return Turbo.visit("/reporting/saved_reports");

  if (response.statusCode === 422) {
    const responseText = await response.text;

    Turbo.renderStreamMessage(responseText);
  }
}

export function updateSavedReportTemplate(
  reportTemplateData: { report_type: string; report_options: object },
  reportTemplateId: string,
) {
  if (reportTemplateId) {
    put(`/reporting/report_templates/${reportTemplateId}`, {
      body: {
        report_template: {
          report_type: reportTemplateData.report_type,
          options: reportTemplateData.report_options,
        },
      },
      responseKind: "turbo-stream",
    });
  }
}

export function processSavedReport(context: ReportContext, savedReportId) {
  requestSavedReport(savedReportId)
    .then((response) => {
      if (response.ok) {
        return response.json;
      } else {
        throw new Error("Response not ok.");
      }
    })
    .then((savedReport) => {
      redirectToCorrectReport(savedReport.report_type);

      loadSavedReportGridData(context, new Event("autoload:saved_report"), savedReport);
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function loadSavedReportGridData(context: ReportContext, _event: Event, savedReport = null) {
  savedReport.options = addRelativeDatesToParams(savedReport.options);

  // In order to set the ag grid state, we need to destroy the grid first
  if (context.gridApi) {
    context.gridApi.destroy();
  }

  context.loadAgGridState(savedReport.options.ag_grid_state);
  context.createGrid();

  context.totalCols.clear();
  context.gridApi.setGridOption("loading", true);

  context.loadValuesToInputs(savedReport.options);
  sessionStorage.setItem(context.sessionStorageKey("report_options"), JSON.stringify(savedReport?.options));

  requestReportData(savedReport.options)
    .then((response) => {
      processReportDataResponse(context, response, (data) => {
        context.renderGrid(data, true, savedReport.options);
      });
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function requestSavedReport(savedReportId) {
  const savedReport = await get(`/reporting/report_templates/${savedReportId}`, {
    responseKind: "json",
  });
  return savedReport;
}

export function redirectToCorrectReport(reportType) {
  if (!window.location.pathname.includes(reportType)) {
    Turbo.visit("/reporting/featured_reports/" + reportType + window.location.search);
  }
}
