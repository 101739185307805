import { Controller } from "@hotwired/stimulus"
import { initFlowbite } from "flowbite";

// Connects to data-controller="flowbite-init"
export default class extends Controller {
  // Use this controller to update Flowbite's JS behavior after a Turbo visit.
  // https://github.com/themesberg/flowbite/issues/499#issuecomment-1483139308
  initialize() {
    initFlowbite();
  }
}
