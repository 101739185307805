import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="select-toggle"
export default class extends Controller {
  static targets = ["field"];

  declare fieldTarget: HTMLInputElement;

  click(event: Event): void {
    event.preventDefault();
    this.fieldTarget.click();
  }
}
