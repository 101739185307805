import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { TomInput, TomOption } from "tom-select/dist/types/types";
import { get } from "@rails/request.js";
import { loadingIcon } from "../helpers";

// Defined in app/views/ledger_management/accounts/_account.json.jbuilder
interface Account {
  id: number;
  name: string;
  number: string;
  label: string;
  type: string;
}

// Connects to data-controller="account-typeahead-search"
export default class extends Controller<TomInput> {
  static values = {
    url: String,
    queryParam: { type: String, default: "q" },
    classes: Array,
    maxOptions: { type: Number, default: 100 }
  };

  declare select: TomSelect;
  declare hasClassesValue: boolean;
  declare classesValue: string[];
  declare urlValue: string;
  declare queryParamValue: string;
  declare maxOptionsValue: number;

  connect() {
    this.select = new TomSelect(this.element, {
      plugins: ["remove_button"],
      preload: "focus",
      load: this.loadOptions.bind(this),
      labelField: "label",
      valueField: "id",
      searchField: ["label"],
      optgroupField: "type",
      optgroupLabelField: "type",
      optgroupValueField: "type",
      maxOptions: this.maxOptionsValue,
      onBlur: () => {
        // We want the options to reload when the user focuses on the select
        this.select.wrapper.classList.remove("preloaded");
        this.select.clearOptions();
        this.select.settings.load = this.loadOptions.bind(this);
      },
      render: {
        optgroup_header: function (data, escape) {
          const style = `style="background-color: #F1F2F5;"`;
          return `
            <div class="optgroup-header" ${style}>
              ${escape(data.label)}
            </div>
          `;
        },
        item: function (item, escape) {
          return `
            <div class="flex p-1 border mr-2 bg-gray-50 font-medium rounded w-fit">
              ${escape(item.label)}
            </div>
          `;
        },
        loading: (_data, _escape) => {
          return `
            <div class="p-1 flex flex-row gap-2 items-center">
              ${loadingIcon()}
              <span>Loading...</span>
            </div>
          `;
        },
      },
    });

    if (this.hasClassesValue) {
      this.classesValue.forEach((className) => {
        this.select.control.classList.add(className);
      });
    }
  }

  buildURL(): string {
    const url = new URL(this.urlValue, window.location.href);

    return url.toString();
  }

  unfocusSelect() {
    this.select.blur();
  }

  disconnect() {
    this.select.destroy();
  }

  buildOptGroups(accounts: Account[]): TomOption[] {
    const accountGroups = accounts.reduce((groups, account) => {
      if (!groups[account.type]) {
        groups[account.type] = {
          label: account.type,
          type: account.type,
        };
      }
      return groups;
    }, {});

    return Object.values(accountGroups);
  }

  loadOptions(query: string, callback: (accounts: Account[] | void, optionGroups: TomOption[] | void) => void) {
    if (this.select.loading > 1) {
      callback();
      return;
    }

    get(this.buildURL(), { responseKind: "json" })
      .then((response: Response) => response.json)
      .then((payload: Account[]) => {
        const optionGroups = this.buildOptGroups(payload);

        callback(payload, optionGroups);

        // We only want to load the data once, so we set the load function to null
        this.select.settings.load = null;
      })
      .catch((error) => {
        console.error(error);

        callback();
      });
  }
}
