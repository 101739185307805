import { Controller } from '@hotwired/stimulus';

interface FeatureFlags {
  on: boolean,
  colors: boolean,
  names: boolean,
};

// Connects to data-controller="action-modal"
export default class extends Controller {
  static targets = ['actionModal', 'cardWrapper', 'bodyStack', 'template'];

  declare actionModalTarget: HTMLElement;
  declare cardWrapperTarget: HTMLElement;
  declare bodyStackTarget: HTMLElement;

  declare templateTargets: Array<HTMLTemplateElement>;

  declare devFlags: FeatureFlags;

  declare handleCardTransitionEndBoundFn: (e) => void;
  declare handleActionModalTransitionEndBoundFn: (e) => void;

  connect() {
    this.devFlags = { on: false, colors: true, names: true };
    if ( this.devFlags.on ) this.startDevMode();
  }

  tempEl(templateName) {
    const template = this.templateTargets.find(element => {
      if (element.dataset.templateName === templateName) {
        return element;
      }
    });

    return template.content.firstElementChild.cloneNode(true) as HTMLElement;
  }

  push(event) {
    this.pushCardElementToStack(event.currentTarget);
  }

  pop(event) {
    if (event.currentTarget.dataset.backDropPoppable === 'false') return;

    event.preventDefault();
    this.popCardElementFromStack();

    return false;
  }

  pushCardElementToStack(buttonElement) {
    const card = JSON.parse(buttonElement.dataset.card);
    this.actionModalTarget.setAttribute('data-back-drop-poppable', card.backDropPoppable);
    this.bodyStackTarget.appendChild(this.buildTurboFrame(card));
    this.stackChanged();
  }

  popCardElementFromStack() {
    const isFirstCard = this.bodyStackTarget.children.length === 1;

    if (isFirstCard) {
      this.cardWrapperTarget.classList.remove("opacity-100");
      this.handleCardTransitionEndBoundFn = this.handleCardTransitionEnd.bind(this);
      this.actionModalTarget.addEventListener('transitionend', this.handleCardTransitionEndBoundFn)
    } else {
      this.bodyStackTarget.removeChild(this.bodyStackTarget.lastElementChild);
      this.stackChanged();
    }
  }

  handleCardTransitionEnd(event) {
    if (event.propertyName === 'opacity' && this.bodyStackTarget.children.length === 1) {
      event.currentTarget.removeEventListener('transitionend', this.handleCardTransitionEndBoundFn);
      this.bodyStackTarget.removeChild(this.bodyStackTarget.lastElementChild);
      this.stackChanged();
    }
  }

  stackChanged() {
    const topCard = this.bodyStackTarget.lastElementChild as HTMLElement

    this.cardWrapperTarget.className = 'action-modal-card-wrapper';

    if (topCard) {
      // template-full template-fat template-slidover template-wideover template-modal-sm

      // This opacity-100 doesn't need the requestAnimationFrame because of how the browser
      // processes the reflow and repaint. Since the action modal, which is the parent, is hidden
      // the browser doesn't repaint the element until the action modal is displayed. Therefore
      // we don't need to trigger a reflow and repaint with requestAnimationFrame.
      // When the action modal is displayed, the browser will trigger a reflow and repaint. The
      // reason we'll need to use requestAnimationFrame for the action modal opacity changes is because
      // when the browser triggers the reflow/repaint it'll bundle the opacity-0 and opacity-100 changes together
      // and this would cause the browser to not know what to transition from. Using requestAnimationFrame
      // will allow the browser to separate the two changes and trigger the transition from opacity-0 to opacity-100 correctly.
      this.cardWrapperTarget.classList.add("opacity-100");
      this.cardWrapperTarget.classList.add("template-" + topCard.dataset.cardTemplate);
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.element.classList.add('overflow-hidden');
    this.actionModalTarget.classList.remove('hidden');
    requestAnimationFrame(() => {
      this.actionModalTarget.classList.remove('opacity-0');
      this.actionModalTarget.classList.add('opacity-100');
    })
  }

  hide() {
    this.element.classList.remove('overflow-hidden');
    this.actionModalTarget.classList.remove('opacity-100');
    this.actionModalTarget.classList.add('opacity-0');
    this.handleActionModalTransitionEndBoundFn = this.handleActionModalTransitionEnd.bind(this);
    this.actionModalTarget.addEventListener('transitionend', this.handleActionModalTransitionEndBoundFn)
  }

  handleActionModalTransitionEnd(event) {
    if (event.propertyName === 'opacity' && this.actionModalTarget?.classList?.contains('opacity-0')) {
      this.actionModalTarget.classList.add('hidden');
      event.target.removeEventListener('transitionend', this.handleActionModalTransitionEndBoundFn);
    }
  }

  buildTurboFrame(card) {
    const turboFrame = this.tempEl('turboFrame');

    if (this.devFlags.on) {
      turboFrame.dataset.action = "turbo:frame-render->action-modal#addDevFeaturesToFrame";
    }

    turboFrame.setAttribute('id', card.turboId);
    turboFrame.setAttribute('src', card.turboSrc);
    turboFrame.dataset.cardTemplate = card.cardTemplate;

    return turboFrame
  }

  clearStack() {
    this.bodyStackTarget.innerHTML = '';

    this.stackChanged();
  }

  startDevMode() {
    if (this.devFlags.colors) this.actionModalTarget.classList.add('dev');
  }

  addDevFeaturesToFrame(event) {    
    const frame = event.target as HTMLElement;

    this.addDevNamesToChildren(frame) 
  }

  addDevNamesToChildren(parentElement) {
    if (!this.devFlags.names) return;

    parentElement.querySelectorAll(`[class*="action-modal-"]`).forEach( (element) => {
      const actionClass = element.className.match(/action-modal-[\w-]+/)[0];
      const span = document.createElement('span');
      span.textContent = actionClass.replace('action-modal-', '');
      span.classList.add('text-xs', 'text-gray-100', 'absolute', 'block', 'top-0', 'left-0', 'px-1', 'bg-blue-500', 'whitespace-nowrap', 'group-hover:hidden');

      if (actionClass.match('button$')) {
        span.classList.add('bottom-0', 'right-0');
        span.classList.remove('top-0', 'left-0');
      }

      element.appendChild(span);
      element.classList.add('relative', 'group');
    });
  }
}
