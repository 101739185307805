import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="bulk-update-ledger-entries"
export default class extends Controller {
  declare accountCodeSelectTarget: HTMLSelectElement;
  declare tagIdsSelectTarget: HTMLSelectElement;

  declare bulkEditMode: boolean;

  static targets = [
    "accountCodeSelect",
    "tagIdsSelect"
  ];

  toggleAccountCodeSelect(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    this.dispatch("toggleAccountCodeSelect", { detail: { checked: target.checked } })
  }

  toggleTagSelect(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    this.dispatch("toggleTagSelect", { detail: { checked: target.checked } })
  }
}
