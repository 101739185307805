// Vite adds some functionality for import.meta - so we reference the types here:
/// <reference types="vite/client" />

import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import LocalTime from "local-time";

// Initialize the cytoscape library and all of its extensions
import cytoscape from "cytoscape";
import elk from "cytoscape-elk";
import domNode from "cytoscape-dom-node";
import konva from "konva";
import edgeEditing from "cytoscape-edge-editing";

import { LicenseManager } from "ag-grid-enterprise";

// We need to hardcode the license key here unfortunately, not much we can do about it
// https://www.ag-grid.com/javascript-data-grid/licensing/#setting-the-license-key
LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-058734}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SumIt_Software}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{SumIt_Application}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{SumIt_Application}_need_to_be_licensed___{SumIt_Application}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{18_June_2025}____[v3]_[01]_MTc1MDIwMTIwMDAwMA==98e68128a50149df8120f79ff79f677f",
);

cytoscape.use(elk);
// @ts-ignore
cytoscape.use(domNode);
// @ts-ignore
cytoscape.use(edgeEditing, konva);

// ActionCable Channels
import "~/channels";

// Stimulus controllers
import "~/controllers";

// Jumpstart Pro & other Functionality
import.meta.glob("~/src/**/*", { eager: true });

ActiveStorage.start();
LocalTime.start();
