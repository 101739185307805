import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="active-account-selector"
export default class extends Controller {
  static targets = ["button", "cancelButton", "accountQuery", "ledgerIds", "fieldset"];
  static values = {
    accountMatrixReadonly: Boolean,
    updatePath: String
  };

  declare fieldsetTarget: HTMLFieldSetElement;
  declare buttonTarget: HTMLButtonElement;
  declare accountQueryTarget: HTMLInputElement;
  declare ledgerIdsTarget: HTMLSelectElement;
  declare accountMatrixReadonlyValue: boolean;
  declare updatePathValue: string;

  connect() {}

  toggleEditing() {
    if (this.accountMatrixReadonlyValue) {
      this.enableEditing();
    } else {
      this.disableEditing();
    }
  }

  fieldsetTargetConnected() {
    this.setCheckboxesAndButton();
  }

  enableEditing() {
    this.accountMatrixReadonlyValue = false;
    this.setCheckboxesAndButton();
  }

  disableEditing() {
    this.accountMatrixReadonlyValue = true;
    this.setCheckboxesAndButton();
  }

  setCheckboxesAndButton() {
    this.setCheckboxes(this.accountMatrixReadonlyValue);

    if (this.accountMatrixReadonlyValue) {
      this.buttonTarget.textContent = "Edit Accounts";
      this.buttonTarget.classList.remove("btn-white");
      this.buttonTarget.classList.add("btn-primary");
    } else {
      this.buttonTarget.textContent = "Stop Editing";
      this.buttonTarget.classList.add("btn-white");
      this.buttonTarget.classList.remove("btn-primary");
    }
  }

  setCheckboxes(checkboxDisabled: boolean) {
    this.fieldsetTarget.disabled = checkboxDisabled;
  }

  async updateHiddenInput(event: Event) {
    this.setCheckboxes(true);
    this.buttonTarget.disabled = true;
    const clickedCheckbox = event.target as HTMLInputElement;

    await post(this.updatePathValue, {
      body: {
        id: clickedCheckbox.id,
        active: clickedCheckbox.checked,
        ledger_ids: this.ledgerIds(),
      },
      responseKind: "turbo-stream",
    });

    this.buttonTarget.disabled = false;
    this.setCheckboxes(false);
  }

  ledgerIds(): string[] {
    return Array.from(this.ledgerIdsTarget.options)
      .filter((option) => option.selected)
      .map((option) => option.value);
  }
}
