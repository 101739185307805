import CheckboxSelectAll from "stimulus-checkbox-select-all";
import { post } from "@rails/request.js";

export default class extends CheckboxSelectAll {
  declare checkboxTargets: HTMLInputElement[];

  static values = {
    updateSelectedUrl: String
  };

  declare hasUpdateSelectedUrlValue: boolean;
  declare updateSelectedUrlValue: string | undefined;

  toggle(e: Event): void {
    e.preventDefault()

    // @ts-ignore
    const isChecked = e.target.checked;

    const ledgerEntryIds = [];
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked;
      ledgerEntryIds.push(checkbox.value);
    })

    post(this.updateSelectedUrlValue, {
      body: JSON.stringify(
        {
          "reconciliation": {
            "ledger_entry_ids": ledgerEntryIds,
            "selected": isChecked
          }
        }
      ),
      responseKind: "turbo-stream",
    });
    // @ts-ignore
    this.dispatch("checked")
  }
}
