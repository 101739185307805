import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle-disable"
export default class extends Controller {
  static targets = ["primaryCheckbox", "dependentInput"];
  declare primaryCheckboxTarget: HTMLInputElement;
  declare dependentInputTargets: HTMLInputElement[];

  static values = {
    dependent: String,
  };

  declare dependentValue: string;

  connect() {
    this.toggleDependentInputs();
  }

  disableDependentIfCheckboxIsChecked() {
    const checkboxToDisable: HTMLInputElement = document.querySelector(this.dependentValue);

    if (this.primaryCheckboxTarget.checked) {
      checkboxToDisable.disabled = false;
    } else {
      checkboxToDisable.checked = false;
      checkboxToDisable.disabled = true;
    }
  }

  toggleDependentInputs() {
    this.dependentInputTargets.forEach((dependentInput) => {
      if (this.primaryCheckboxTarget.checked) {
        dependentInput.disabled = false;
      } else {
        dependentInput.checked = false;
        dependentInput.value = "";
        dependentInput.disabled = true;
      }
    });
  }
}
