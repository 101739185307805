import { Controller } from "@hotwired/stimulus";
import oneschemaImporter, { OneSchemaImporterClass } from "@oneschema/importer";
import { post, get } from "@rails/request.js";
import { IOneSchemaOverridesResponseData, IOneSchemaPicklistOption } from "../types";
import { processSchemaOverridesResponse } from "../helpers/one_schema";
import { Turbo } from "@hotwired/turbo-rails";

interface IOneSchemaImportedRecord {
  rank: number;
  addepar_field: string;
  addepar_value: string;
  sumit_record: string;
  prefix_id?: string;
}

// Connects to data-controller="mapping-manager-importer"
export default class extends Controller {
  declare importer: OneSchemaImporterClass | undefined;
  declare picklistOptions: IOneSchemaPicklistOption[];

  static targets = ["importButton"];

  static values = {
    jwtToken: String,
    overridesUrl: String,
    importUrl: String,
    clientId: String,
    sessionToken: {
      type: String,
      default: "",
    },
    devMode: {
      type: Boolean,
      default: false,
    },
  };

  declare hasJwtTokenValue: boolean;
  declare jwtTokenValue: string;

  declare overridesUrlValue: string;
  declare importUrlValue: string;
  declare clientIdValue: string;
  declare sessionTokenValue: string;
  declare devModeValue: boolean;

  declare importButtonTarget: HTMLButtonElement;

  connect(): void {}

  async launchImporter(_event: Event): Promise<void> {
    this.showLoader();

    const schemaOverrides = await get(this.overridesUrlValue, { responseKind: "json" });

    if (!this.importer) {
      this.initializeImporter();
    }

    if (schemaOverrides.ok) {
      this.picklistOptions = this.processOverrides(await schemaOverrides.json);
    } else {
      console.warn("error fetching overrides");
    }

    this.importer.launch({
      userJwt: this.userJwt,
      templateKey: "addepar_mapping_manager",
      sessionToken: this.sessionTokenValue,
      templateOverrides: {
        columns_to_update: [
          {
            key: "sumit_record",
            validation_options: { picklistOptions: this.picklistOptions },
          },
        ],
      },
    });
  }

  initializeImporter(): void {
    this.importer = oneschemaImporter({
      clientId: this.clientIdValue,
      templateKey: "addepar_mapping_manager",
      importConfig: { type: "local" },
      devMode: this.devModeValue,
      className: "oneschema-importer",
      styles: {
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        zIndex: "1000",
      },
    });

    this.importer.on("launched", (data: { sessionToken: string }) => {
      this.hideLoader();

      this.sessionTokenValue = data.sessionToken;
    });

    this.importer.on("success", async (data) => {
      this.showLoader();

      data.records = this.postProcessImportedRecords(data.records);

      const response = await post(this.importUrlValue, {
        responseKind: "turbo-stream",
        body: JSON.stringify({ one_schema: { session_token: this.sessionTokenValue, ...data } }),
      });

      Turbo.renderStreamMessage(await response.text);

      if (!response.ok) {
        console.error("There was an error importing the file.");
      }

      this.hideLoader();
    });

    this.importer.on("cancel", () => {
      console.warn("Import cancelled");

      this.sessionTokenValue = "";
    });

    this.importer.on("error", (error) => {
      console.error(error);
    });
  }

  processOverrides(overrides: IOneSchemaOverridesResponseData): IOneSchemaPicklistOption[] {
    const processedData = processSchemaOverridesResponse(overrides);

    const picklistOptions = [];

    processedData.accountOverrides.forEach((account) => {
      picklistOptions.push({
        ...account,
        value: `Account: ${account.value}`,
      });
    });

    processedData.tagOverrides.forEach((tag) => {
      picklistOptions.push({
        ...tag,
        value: `Tag: ${tag.value}`,
      });
    });

    processedData.accountCodeOverrides.forEach((accountCode) => {
      picklistOptions.push({
        ...accountCode,
        value: `Account Code: ${accountCode.value}`,
      });
    });

    return picklistOptions;
  }

  disconnect(): void {
    this.importer?.close(true);
  }

  showLoader() {
    this.importButtonTarget.disabled = true;
    this.importButtonTarget.querySelector("div#processing_spinner").classList.remove("hidden");
    this.importButtonTarget.querySelector("div#tray_icon").classList.add("hidden");
  }

  hideLoader() {
    this.importButtonTarget.querySelector("div#processing_spinner").classList.add("hidden");
    this.importButtonTarget.querySelector("div#tray_icon").classList.remove("hidden");
    this.importButtonTarget.disabled = false;
  }

  postProcessImportedRecords(records: IOneSchemaImportedRecord[]): IOneSchemaImportedRecord[] {
    return records.map((record) => {
      const prefixId = this.picklistOptions.find((option) => option.value === record.sumit_record)?.prefix_id;

      return {
        ...record,
        prefix_id: prefixId,
      };
    });
  }

  get userJwt(): string {
    return this.hasJwtTokenValue ? this.jwtTokenValue : "unknown";
  }
}
