import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="compare-to-last-period-button"
export default class extends Controller {
  declare ledgersTarget: HTMLSelectElement;
  declare isCompareableTarget: HTMLInputElement;
  declare buttonTarget: HTMLInputElement;
  declare infoTarget: HTMLDivElement;
  declare textTarget: HTMLSpanElement;
  declare colTagTargets: HTMLInputElement[];

  declare hasIntervalSelectTarget: boolean;
  declare intervalSelectTarget: HTMLSelectElement;
  
  declare hasConsolidatedBreakdownTarget: boolean;
  declare consolidatedBreakdownTarget: HTMLInputElement;

  declare hasMergeEntitiesToggleTarget: boolean;
  declare mergeEntitiesToggleTarget: HTMLInputElement;

  static targets = [
    "ledgers",
    "isCompareable",
    "button",
    "info",
    "text",
    "intervalSelect",
    "consolidatedBreakdown",
    "colTag",
    "mergeEntitiesToggle"
  ];

  canCompare() {
    const hasOneLedger =
      Array.from(this.ledgersTarget.children).filter((ledger: HTMLOptionElement) => ledger.selected).length == 1;

    const isComparableDate = this.isCompareableTarget.value !== "false" && this.isCompareableTarget.value !== "";
    const isNotInterval = this.hasIntervalSelectTarget && this.intervalSelectTarget.value === "none";
    const hasNoColTags = Array.from(this.colTagTargets).every(checkbox => checkbox.checked === false);
    const mergeEntities = this.hasMergeEntitiesToggleTarget && this.mergeEntitiesToggleTarget.checked === true;

    if (this.hasConsolidatedBreakdownTarget) {
      const isOnlyConsolidated = !this.consolidatedBreakdownTarget.checked;

      return hasOneLedger && isComparableDate && isOnlyConsolidated && isNotInterval && hasNoColTags;
    } else {
      return (hasOneLedger && isComparableDate && isNotInterval && hasNoColTags) || (isComparableDate && isNotInterval && hasNoColTags && mergeEntities);
    }
  }

  setButton() {
    if (this.canCompare()) {
      this.buttonTarget.disabled = false;
      this.infoTarget.classList.add("hidden");
      this.textTarget.classList.remove("text-gray-400");
    } else {
      this.buttonTarget.disabled = true;
      this.buttonTarget.checked = false;
      this.infoTarget.classList.remove("hidden");
      this.textTarget.classList.add("text-gray-400");
    }
  }

  connect() {
    this.setButton();
  }
}


