import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="payable"
export default class extends Controller {
  static targets = [
    "differenceAmountFooter",
    "invoiceIdentifier",
    "payableLineItemAmount",
    "payee",
    "totalAmount",
    "totalAmountFooter"
  ];

  declare differenceAmountFooterTarget: HTMLElement;
  declare invoiceIdentifierTarget: HTMLInputElement;
  declare payableLineItemAmountTargets: HTMLInputElement[];
  declare payeeTarget: HTMLSelectElement;
  declare totalAmountTarget: HTMLInputElement;
  declare totalAmountFooterTarget: HTMLElement;

  connect() {
    const payeeName = this.payeeTarget.selectedOptions[0].text;
    const invoiceIdentifier = this.invoiceIdentifierTarget.value;

    if (payeeName != "" && invoiceIdentifier == "") {
      this.generateInvoiceIdentifier();
    }
  }

  generateInvoiceIdentifier() {
    const payeeName = this.payeeTarget.selectedOptions[0].text;

    if (payeeName != "") {
      const payeeNamePrefix = payeeName.substring(0, 3).toUpperCase();
      const randomHexString = [...Array(6)].map(() => Math.floor(Math.random() * 16).toString(16)).join('').toUpperCase();
      this.invoiceIdentifierTarget.value = `${payeeNamePrefix}-${randomHexString}`;
    }
  }

  payableLineItemAmountTargetDisconnected() {
    this.updateDifferenceAmountFooter();
  }

  updateTotalAmountFooter() {
    this.totalAmountFooterTarget.textContent = `Total: ${this.totalAmountTarget.value}`;
    this.updateDifferenceAmountFooter();
  }

  updateDifferenceAmountFooter() {
    const payableLineItemsSum = this.payableLineItemAmountTargets.reduce((total, input) => {
      const value = this.convertCurrencyStringToNumber(input.value);
      return total + (isNaN(value) ? 0 : value);
    }, 0);
    const totalAmount = this.convertCurrencyStringToNumber(this.totalAmountTarget.value);
    const differenceAmount = totalAmount - payableLineItemsSum;

    if (differenceAmount == 0) {
      this.differenceAmountFooterTarget.textContent = "";
    } else {
      const label = differenceAmount > 0 ? "Missing" : "Excess";
      const formattedDifferenceAmount = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(Math.abs(differenceAmount));
  
      this.differenceAmountFooterTarget.textContent = `${label}: ${formattedDifferenceAmount}`;
    }
  }

  convertCurrencyStringToNumber(value: string) {
    return Number(value.replace(/[^0-9.-]+/g,""));
  }
}
