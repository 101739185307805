import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bank-transaction"
export default class extends Controller {
  declare accountTargets: HTMLSelectElement[];
  declare destinationAccountSelectTargets: HTMLSelectElement[];
  declare amountValue: number;
  declare creditsTargets: HTMLInputElement[];
  declare debitsTargets: HTMLInputElement[];
  declare rowPositionTargets: HTMLElement[];
  declare rowTargets: HTMLElement[];
  declare rowTemplateTarget: HTMLTemplateElement;
  declare submitTarget: HTMLInputElement;
  declare targetTarget: HTMLButtonElement;
  declare multiLedgerTransactionTarget: HTMLElement;

  static targets = [
    "account",
    "destinationAccountSelect",
    "credits",
    "debits",
    "row",
    "rowPosition",
    "rowTemplate",
    "submit",
    "target",
    "multiLedgerTransaction",
  ];

  static values = {
    amount: Number,
  };

  connect() {
    this.initializeDestinationAccountSelectsAmounts();
  }

  initializeDestinationAccountSelectsAmounts() {
    this.destinationAccountSelectTargets.forEach( (select) => {
      if (select.value) {
        this.setAmountWithRow(select.closest("[data-bank-transaction-target='row']"));
      }
    });
  }

  multiLedgerTransactionTargetConnected() {
    this.validate();
  }

  disableSubmit() {
    this.submitTarget.disabled = true;
  }

  add() {
    const content = this.rowTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());

    this.targetTarget.insertAdjacentHTML("beforebegin", content);
    this.validate();
  }

  remove(event) {
    const row = event.target.closest("[data-bank-transaction-target='row']");
    row.style.display = "none";
    this.dispatch("handleUpdates");
    row.remove();
    this.validate();
  }

  setAmount(event) {
    const row = event.target.closest("[data-bank-transaction-target='row']");
    this.setAmountWithRow(row);
  }

  setAmountWithRow(row) {
    const initialDebitsValue = parseFloat(this.debitsTargets[0].value);
    const initialCreditsValue = parseFloat(this.creditsTargets[0].value);

    if (initialDebitsValue > 0) {
      const creditsTotal = this.sumInputAmounts(this.creditsTargets);
      const remainingCreditsAmount = Math.max(initialDebitsValue - creditsTotal, 0).toFixed(2);
      const rowCreditsTarget = row.querySelector("[data-bank-transaction-target='credits']");
      if (this.cleanseCurrency(rowCreditsTarget.value) == 0) {
        rowCreditsTarget.value = this.formatCurrency(remainingCreditsAmount);
        rowCreditsTarget.dispatchEvent(new Event('input', { bubbles: true }));
      }
    } else if (initialCreditsValue > 0) {
      const debitsTotal = this.sumInputAmounts(this.debitsTargets);
      const remainingDebitsAmount = Math.max(initialCreditsValue - parseFloat(debitsTotal), 0).toFixed(2);
      const rowDebitsTarget = row.querySelector("[data-bank-transaction-target='debits']");

      if (this.cleanseCurrency(rowDebitsTarget.value) == 0) {
        rowDebitsTarget.value = this.formatCurrency(remainingDebitsAmount);
        rowDebitsTarget.dispatchEvent(new Event('input', { bubbles: true }));
      }
    }

    this.dispatch("handleUpdates");
  }

  sumInputAmounts(inputTargets) {
    return inputTargets.reduce((sum, input) => {
      if (!isNaN(this.cleanseCurrency(input.value))) {
        sum += this.cleanseCurrency(input.value);
      }
      return sum;
    }, 0);
  }

  validate() {
    const accountsHaveValues = this.accountTargets.every((account) => account.value !== "");
    const debitsAreNumbers = this.debitsTargets.every((input) => !isNaN(this.cleanseCurrency(input.value)));
    const creditsAreNumbers = this.creditsTargets.every((input) => !isNaN(this.cleanseCurrency(input.value)));

    if (accountsHaveValues && debitsAreNumbers && creditsAreNumbers) {
      const creditsTotal = this.sumInputAmounts(this.creditsTargets).toFixed(2);
      const debitsTotal = this.sumInputAmounts(this.debitsTargets).toFixed(2);
      const debitsEqualCredits = debitsTotal === creditsTotal;

      if (debitsEqualCredits) {
        this.submitTarget.disabled = false;
      } else {
        this.disableSubmit();
      }
    } else {
      this.disableSubmit();
    }
  }

  formatCurrency(amount: string) {
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(parseFloat(amount));

    return formattedValue;
  }

  cleanseCurrency(amount: string) {
    return parseFloat(amount.replace(/^0+|[^0-9.]/g, ''));
  }
}
