import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="entity-relationship-form"
export default class extends Controller<HTMLElement> {
  static targets = [
    "percentageBasedButton",
    "percentageBasedRelationshipRow",
    "relationshipTypeButton",
    "relationshipTypeInput",
    "units",
    "unitBasedButton",
    "unitBasedRelationshipRow",
  ];

  static values = {
    relationshipTypeClassesToAdd: { type: String, default: "bg-cyan-600 focus:ring-cyan-500 text-white pointer-events-none" },
    relationshipTypeClassesToRemove: { type: String, default: "btn-white" }
  }

  declare percentageBasedButtonTarget: HTMLButtonElement;
  declare percentageBasedRelationshipRowTargets: HTMLElement[];
  declare relationshipTypeButtonTargets: HTMLButtonElement[];
  declare relationshipTypeClassesToAddValue: string;
  declare relationshipTypeClassesToRemoveValue: string;
  declare relationshipTypeInputTargets: HTMLInputElement[];
  declare unitsTargets: HTMLInputElement[];
  declare unitBasedButtonTarget: HTMLButtonElement;
  declare unitBasedRelationshipRowTargets: HTMLElement[];

  percentageReadOnlyTargetConnected() {
    this.updatePercentageReadOnlyValues();
  }

  showRelationshipRow(target) {
    target.classList.remove('hidden');
    const input = target.querySelector("input[type='number']") as HTMLInputElement;
    input.removeAttribute('disabled');
  }

  hideRelationshipRow(target) {
    target.classList.add('hidden');
    const input = target.querySelector("input[type='number']") as HTMLInputElement;
    input.setAttribute('disabled', '');
  }

  showUnitBasedRelationshipRows() {
    this.unitBasedRelationshipRowTargets.forEach(target => this.showRelationshipRow(target));
    this.percentageBasedRelationshipRowTargets.forEach(target => this.hideRelationshipRow(target));
    this.relationshipTypeInputTargets.forEach(target => target.value = "unit_based");
  }

  showPercentageBasedRelationshipRows() {
    this.unitBasedRelationshipRowTargets.forEach(target => this.hideRelationshipRow(target));
    this.percentageBasedRelationshipRowTargets.forEach(target => this.showRelationshipRow(target));
    this.relationshipTypeInputTargets.forEach(target => target.value = "percentage_based");
  }

  toggleRelationshipType(event) {
    this.relationshipTypeButtonTargets.forEach(target => {
      if (target === event.target) {
        target.setAttribute('disabled', '');
        target.classList.remove(...this.relationshipTypeClassesToRemoveValue.split(' '));
        target.classList.add(...this.relationshipTypeClassesToAddValue.split(' '));
      } else {
        target.removeAttribute('disabled');
        target.classList.remove(...this.relationshipTypeClassesToAddValue.split(' '));
        target.classList.add(...this.relationshipTypeClassesToRemoveValue.split(' '));
      }
    });

    if (event.target === this.unitBasedButtonTarget) {
      this.showUnitBasedRelationshipRows();
    } else if (event.target === this.percentageBasedButtonTarget) {
      this.showPercentageBasedRelationshipRows();
    }
  }

  toggleRelationshipTypeOnNewRelationshipRow() {
    if (this.unitBasedButtonTarget.hasAttribute('disabled')) {
      this.showRelationshipRow(this.unitBasedRelationshipRowTargets.at(-1))
      this.hideRelationshipRow(this.percentageBasedRelationshipRowTargets.at(-1))
      this.relationshipTypeInputTargets.at(-1).value = "unit_based";
    } else if (this.percentageBasedButtonTarget.hasAttribute('disabled')) {
      this.showRelationshipRow(this.percentageBasedRelationshipRowTargets.at(-1))
      this.hideRelationshipRow(this.unitBasedRelationshipRowTargets.at(-1))
      this.relationshipTypeInputTargets.at(-1).value = "percentage_based";
    }
  }

  updatePercentageReadOnlyValues() {
    const shareInputs = this.unitsTargets.map(input => parseInt(input.value));
    const totalShares = shareInputs.reduce((accumulator, currentValue) => {
      return accumulator + (isNaN(currentValue) ? 0 : currentValue);
    }, 0);

    this.unitBasedRelationshipRowTargets.forEach((row, index) => {
      const percentageReadOnlyElement = row.querySelector(
        "[data-entity-relationship-form-target='percentageReadOnly']"
      ) as HTMLInputElement;
      const shareInput = shareInputs[index];
      const percentage = isNaN(shareInput) || totalShares === 0 ? 0 : ((shareInput / totalShares) * 100).toFixed(6);
      percentageReadOnlyElement.value = `${percentage}%`;
    });
  }
}
