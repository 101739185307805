import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="account-number-generator"
export default class extends Controller {
  static targets = ["parentAccount"];
  static values = { url: String, disabled: Boolean };

  declare urlValue: string;
  declare disabledValue: boolean;

  declare parentAccountTarget: HTMLInputElement;
  declare hasParentAccountTarget: boolean;

  fetchAccountNumber() {
    if (!this.validParentAccountInput || this.disabledValue) {
      return;
    }

    const queryParams = new URLSearchParams({
      account_template_id: this.parentAccountTarget.value,
    }).toString();

    const fullUrl = this.buildAccountNumberUrl(queryParams);

    get(`${fullUrl}`, {
      responseKind: "turbo-stream",
    });
  }

  fetchNewNumber(event: Event) {
    event.preventDefault();
    this.fetchAccountNumber();
  }

  buildAccountNumberUrl(queryParams: string) {
    const url = new URL(this.urlValue, window.location.href);
    url.search = queryParams.toString();

    return url.toString();
  }

  get validParentAccountInput(): boolean {
    return this.hasParentAccountTarget && this.parentAccountTarget.value !== "";
  }
}
