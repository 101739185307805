import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="transaction-payables-match"
export default class extends Controller {
  static targets = [
    "payableIdInput",
    "payableIdInputTemplate",
    "payableIdInputContainer",
    "checkbox",
    "checkboxAll"
  ];

  declare payableIdInputTemplateTarget: HTMLTemplateElement;
  declare payableIdInputTargets: HTMLInputElement[];
  declare payableIdInputContainerTarget: HTMLDivElement;

  declare checkboxTargets: HTMLInputElement[];
  declare checkboxAllTarget: HTMLInputElement;

  updateMatches() {
    this.setHiddenTransactionInputs();
  }

  setHiddenTransactionInputs() {
    const selectedCheckboxes = this.getSelectedCheckboxes();

    this.payableIdInputContainerTarget.innerHTML = "";

    selectedCheckboxes.forEach((checkbox) => {
      const payableIdInput = document.importNode(this.payableIdInputTemplateTarget.content, true);
      const payableIdInputEl = payableIdInput.querySelector("input");
      payableIdInputEl.value = checkbox.value;

      this.payableIdInputContainerTarget.appendChild(payableIdInput);
    });
  }

  getSelectedCheckboxes() {
    return this.checkboxTargets.filter((checkbox) => {
      return checkbox.checked;
    });
  }

  selectAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = true;
    });
  }

  deselect(checkboxes: HTMLInputElement[]) {
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }
}
