// Custom TailwindCSS modals for confirm dialogs
//
// Example usage:
//
//   <%= button_to "Delete", my_path, method: :delete, form: {
//     data: {
//       turbo_confirm: "Are you sure?",
//       turbo_confirm_description: "This will delete your record. Enter the record name to confirm.",
//       turbo_confirm_text: "record name"
//     }
//   } %>

import * as Turbo from "@hotwired/turbo";

function insertConfirmModal(message: string, element: HTMLFormElement, button: HTMLElement) {
  let confirmInput = "";

  // button is nil if using link_to with data-turbo-confirm
  const confirmText = button?.dataset?.turboConfirmText || element.dataset.turboConfirmText;
  const description = button?.dataset?.turboConfirmDescription || element.dataset.turboConfirmDescription || "";

  if (confirmText) {
    confirmInput = `<input type="text" class="mt-4 form-control" data-behavior="confirm-text" />`;
  }
  const id = `confirm-modal-${new Date().getTime()}`;

  const content = `
    <div id="${id}" class="z-[1001] bg-black/80 animated fadeIn fixed top-0 left-0 w-full h-full table backdrop-blur-sm">
      <div class="table-cell align-middle">

        <div class="bg-white mx-auto rounded shadow p-8 max-w-md">
          <h4>${message}</h4>
          <p class="mt-4 text-sm text-gray-700">${description}</p>

          ${confirmInput}

          <div class="flex justify-end items-center flex-wrap gap-2 mt-6">
            <button data-behavior="cancel" class="btn btn-white">Cancel</button>
            <button data-behavior="commit" class="btn btn-danger focus:outline-none">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  `;

  document.body.insertAdjacentHTML("beforeend", content);
  const modal = document.getElementById(id);

  // Focus on the first button in the modal after rendering
  modal.querySelector("button").focus();

  // Disable commit button until the value matches confirmText
  if (confirmText) {
    const commitButton = modal.querySelector("[data-behavior='commit']") as HTMLButtonElement;
    commitButton.disabled = true;

    modal.querySelector("input[data-behavior='confirm-text']").addEventListener("input", (event) => {
      commitButton.disabled = (event.target as HTMLButtonElement).value != confirmText;
    });
  }

  return modal;
}

Turbo.setConfirmMethod((message, element, button) => {
  const dialog = insertConfirmModal(message, element, button);

  return new Promise((resolve, _reject) => {
    dialog.querySelector("[data-behavior='cancel']").addEventListener(
      "click",
      (_event) => {
        dialog.remove();
        resolve(false);
      },
      { once: true },
    );
    dialog.querySelector("[data-behavior='commit']").addEventListener(
      "click",
      (_event) => {
        dialog.remove();
        resolve(true);
      },
      { once: true },
    );
  });
});
