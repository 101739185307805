import CheckboxSelectAll from "stimulus-checkbox-select-all";

export default class extends CheckboxSelectAll {
  declare checkboxTargets: HTMLInputElement[];
  declare triggerInputEvent: (checkbox: HTMLInputElement) => void;

  toggle(e: Event): void {
    e.preventDefault()

    this.checkboxTargets.forEach(checkbox => {
      checkbox.indeterminate = false;
      // @ts-ignore
      checkbox.checked = e.target.checked
      this.triggerInputEvent(checkbox)
    })
  }
}
