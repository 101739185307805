import { ValueGetterParams } from "ag-grid-enterprise";

const convertColToNumber = (params: ValueGetterParams, col: string) => {
  if (!params.node.data) {
    return 0;
  }

  const val = Number(params.node.data[col]);

  if (val && !Number.isNaN(val)) {
    return val;
  } else {
    return 0;
  }
};

export const aggColumnValues = (params: ValueGetterParams, cols: string[]) => {
  return cols.reduce((acc, col) => acc + convertColToNumber(params, col), 0);
};

export const getCheckboxValues = (checkboxes: HTMLInputElement[]) => {
  const checkboxesIds = []
  checkboxes.forEach((checkbox) => {
    if(checkbox.checked) checkboxesIds.push(checkbox.value)
  })
  return checkboxesIds;
};

export const setOptionsCheckboxValue = (checkboxes: HTMLInputElement[], name: string, value: boolean) => {
  const checkbox = checkboxes.find(target => target.name === name);
  if (checkbox) {
    checkbox.checked = value
  }
};

export const setCheckboxValues = (checkboxes: HTMLInputElement[], selectedCheckboxes: number[]) => {
  checkboxes.forEach((checkbox) => {
    if(selectedCheckboxes.includes(parseInt(checkbox.value))) {
      checkbox.checked = true;
      checkbox.dispatchEvent(new Event('change'));
    }
  });
};

export const setSelectValues = (select, selectedIds: number[]) => {
  if (select.tomselect) {
    select.tomselect.setValue(selectedIds)
  } else {
    select.options.forEach((option) => {
      if (selectedIds.includes(parseInt(option.value))) {
        option.selected = true
      }
    })
  }
};

export const getSelectValues = (select: HTMLSelectElement) => {
  const options = select && select.options;

  const selectedOptions = Array.from(options).filter((opt: HTMLOptionElement) => opt.selected);
  const result = selectedOptions.map((opt: HTMLOptionElement) => opt.value || opt.text);

  return result;
};
