import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="auto-reload"
export default class extends Controller {
  static values = {
    interval: { type: Number, default: 5000 },
    active: { type: Boolean, default: true },
  };

  declare intervalValue: number;
  declare activeValue: boolean;

  declare reloadTimer: NodeJS.Timeout;

  connect() {
    if (this.activeValue) {
      this.startAutoReload();
    }
  }

  disconnect() {
    this.stopAutoReload();
  }

  startAutoReload() {
    this.reloadTimer = setInterval(() => {
      this.reloadPage();
    }, this.intervalValue);
  }

  stopAutoReload() {
    if (this.reloadTimer) {
      clearInterval(this.reloadTimer);
    }
  }

  reloadPage() {
    Turbo.visit(window.location.href);
  }
}
