import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="account-select-for-intercompany"
export default class extends Controller {
  static targets = ["debitAccount", "creditAccount", "select", "submitButton"];

  declare debitAccountTarget: HTMLInputElement;
  declare creditAccountTarget: HTMLInputElement;
  declare submitButtonTarget: HTMLButtonElement;
  declare hasSubmitButtonTarget: boolean;
  declare selectTarget: HTMLSelectElement;

  static values = {
    submitId: String
  };
  declare submitIdValue: string;

  connect() {
    this.updateAccounts();
  }

  updateAccounts() {
    const select = this.selectTarget;
    const selectedOption = select[select.selectedIndex];

    if (selectedOption) {
      this.updateTargetValue(this.debitAccountTarget, selectedOption.dataset.intercompanyDebitAccountTemplateId);
      this.updateTargetValue(this.creditAccountTarget, selectedOption.dataset.intercompanyCreditAccountTemplateId);
    } else {
      this.debitAccountTarget.value = "";
      this.creditAccountTarget.value = "";
    }

    this.toggleSubmitButton();
  }

  // Updates the targets value with the value from the passed in argument. Verifies that
  // the value is a string that can be coerced into a number.
  updateTargetValue(target: HTMLInputElement, value: string) {
    if (value === "") {
      target.value = "";
    } else {
      const number = Number(value);

      if (isNaN(number)) {
        target.value = "";
      } else {
        target.value = number.toString();
      }
    }
  }

  toggleSubmitButton() {
    if (this.debitAccountTarget.value === "" && this.creditAccountTarget.value === "") {
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = true;
      } else {
        this.dispatch("toggleSubmit", { detail: { enabled: false, id: this.submitIdValue } });
      }
    } else {
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = false;
      } else {
        this.dispatch("toggleSubmit", { detail: { enabled: true, id: this.submitIdValue } });
      }
    }
  }
}
