import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { patch } from "@rails/request.js";
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="drag"
export default class extends Controller {
  declare handleValue: string;
  declare positionTargets: HTMLElement[];
  declare sortable: Sortable;

  declare objectNameValue: string;
  declare objectAttributeValue: string;

  declare disabledValue: boolean;

  static targets = ["position"];

  static values = {
    handle: String,
    objectName: String,
    objectAttribute: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  };

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: this.handleValue,
      group: "shared",
      disabled: this.disabledValue,
      animation: 150,
      onEnd: this.end.bind(this),
    });
  }

  async end(event) {
    const url = event.item.getAttribute("data-drag-url");
    const data = this.buildPayload(event);

    if (event.newIndex === event.oldIndex) return;

    const response = await patch(url, {
      body: data,
      contentType: "application/json",
      responseKind: "json",
    });

    if (response.ok) {
      this.positionTargets.forEach((el, index) => (el.textContent = (index + 1).toString()));
    } else {
      Turbo.visit(window.location.href, { action: "replace" });
    }
  }

  buildPayload(event) {
    const data = {};
    data[this.objectNameValue] = {};
    data[this.objectNameValue][this.objectAttributeValue] = event.newIndex + 1;

    return data;
  }

  disconnect() {
    this.sortable.destroy();
  }
}
