import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle-all"
export default class extends Controller {
  static targets = ["toggleable", "toggleButton", "actionIcon"];
  static values = {
    open: { type: Boolean, default: false },
  };

  declare toggleableTargets: HTMLElement[];

  declare actionIconTarget: HTMLElement;
  declare hasActionIconTarget: boolean;

  declare openValue: boolean;

  connect() {
    if (this.hasActionIconTarget) {
      this.actionIconTarget.classList.add("transition-transform", "duration-200", "ease-in-out");

      if (this.openValue) {
        this.actionIconTarget.classList.add("rotate-0");
      } else {
        this.actionIconTarget.classList.add("-rotate-90");
      }
    }
  }

  toggleAll(_e: Event): void {
    this.openValue = !this.openValue;
    this.animate();
  }

  collapseAll(_e: Event): void {
    this.openValue = false;
    this.animate();
  }

  expandAll(_e: Event): void {
    this.openValue = true;
    this.animate()
  }

  animate() {
    if (this.hasActionIconTarget) {
      if (this.openValue) {
        this.actionIconTarget.classList.add("rotate-0");
        this.actionIconTarget.classList.remove("-rotate-90");
      } else {
        this.actionIconTarget.classList.add("-rotate-90");
        this.actionIconTarget.classList.remove("rotate-0");
      }
    }

    this.toggleableTargets.forEach((toggleable: HTMLElement) => {
      if (this.openValue) {
        this.dispatch("expand", { target: toggleable });
      } else {
        this.dispatch("collapse", { target: toggleable });
      }
    });
  }
}
