import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle-readonly"
export default class extends Controller {
  connect() {}

  static targets = ["numberInput"];

  declare numberInputTarget: HTMLInputElement;

  toggleReadOnly(event: Event): void {
    event.preventDefault();
    this.numberInputTarget.readOnly = !this.numberInputTarget.readOnly;

    this.updateButtonText(event.target as HTMLElement);
  }

  updateButtonText(button: HTMLElement): void {
    if (button) {
      button.textContent = this.numberInputTarget.readOnly
        ? "Edit"
        : "Lock";
    }
  }
}
