import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="payment-terms"
export default class extends Controller {
  static targets = ["paymentTerms", "invoiceDate", "dueDate"];

  declare hasPaymentTermsTarget: boolean;
  declare paymentTermsTarget: HTMLSelectElement;
  declare hasInvoiceDateTarget: boolean;
  declare invoiceDateTarget: HTMLInputElement;
  declare hasDueDateTarget: boolean;
  declare dueDateTarget: HTMLInputElement;

  connect() {}

  getPaymentTermsNetDays() {
    if (!this.hasPaymentTermsTarget) { return null; }
    const netDays = this.paymentTermsTarget.selectedOptions[0].dataset.netDays;
    return parseInt(netDays) || null;
  }

  calculateNetDate(invoiceDate, daysToAdd) {
    const netDate = invoiceDate;
    netDate.setDate(netDate.getDate() + daysToAdd);
    return netDate;
  }

  dateToISO(date) {
    return date.toISOString().split('T')[0];
  }

  convertToDate(dateString) {
    const parts = dateString.split('-');
    return new Date(parts[0], parts[1] - 1, parts[2]); // Note: Subtracting 1 from the month because JavaScript months are 0-based
  }

  updatePaymentTerms(option) {
    if (this.hasPaymentTermsTarget) {
      this.paymentTermsTarget.value = option
    }
  }

  updateDueDate() {
    if (!this.hasInvoiceDateTarget || !this.invoiceDateTarget.value) { return; }

    const invoiceDate = this.convertToDate(this.invoiceDateTarget.value);

    if (this.hasPaymentTermsTarget && this.paymentTermsTarget.value == 'due_on_receipt') {
      this.dueDateTarget.value = this.dateToISO(invoiceDate);
      return;
    }

    const paymentTermsDays = this.getPaymentTermsNetDays();
    const netDate = this.calculateNetDate(invoiceDate, paymentTermsDays);

    if (!paymentTermsDays) { return; }

    if (this.hasDueDateTarget) {
      this.dueDateTarget.value = this.dateToISO(netDate);
    }
  }

  handlePaymentTermsChange() {
    this.updateDueDate();
  }

  handleDueDateChange() {
    this.updatePaymentTerms('other');
  }

  handleInvoiceDateChange() {
    this.updateDueDate();
  }
}
