import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

// Connects to data-controller="addepar-transaction-filter"
export default class extends Controller {
  static targets = ["addeparFieldSelect", "operatorSelect", "value", "updateContainer"];

  static values = {
    url: String,
    addeparImportId: Number
  };

  declare addeparFieldSelectTarget: HTMLSelectElement;
  declare operatorSelectTarget: HTMLSelectElement;
  declare valueTarget: HTMLInputElement;
  declare urlValue: string;
  declare addeparImportIdValue: number;
  declare updateContainerTarget: HTMLDivElement;

  addeparFieldChanged(_event: Event) {
    this.getSelectTargetTurboFrame(this.addeparFieldSelectTarget);
  }

  getSelectTargetTurboFrame(element: HTMLSelectElement) {
    const selectedAddeparField = this.addeparFieldSelectTarget.value;

    // Don't reload if the field hasn't changed or is empty
    if (selectedAddeparField === "" || selectedAddeparField === element.dataset.addeparField) {
      return;
    }

    const query = {
      addepar_import_id: this.addeparImportIdValue,
      selected_addepar_field: selectedAddeparField,
      target: this.updateContainerTarget.id,
      name: element.name,
      operator_name: this.operatorSelectTarget.name,
      value_name: this.valueTarget.name,
    };

    get(this.urlValue, {
      query: query,
      responseKind: "turbo-stream",
    });
  }
}
