import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ordinal"
export default class extends Controller {
  static targets = ["ordinal"];

  declare ordinalTargets: HTMLElement[];

  ordinalTargetConnected() {
    this.update();
  }

  ordinalTargetDisconnected() {
    this.update();
  }

  update() {
    this.ordinalTargets.forEach((el, index) => {
      el.textContent = `${index + 1}`;
    });
  }
}
