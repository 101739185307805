import { Controller } from "@hotwired/stimulus"
import { Modal, modalBackdrop, modalPlacement } from 'flowbite';

// Connects to data-controller="flowbite-modal"
export default class extends Controller {
  static values = {
    backdrop: {
      type: String,
      default: "dynamic" as modalBackdrop,
    },
  }

  declare backdropValue: "dynamic" | "static";

  static targets = ['modal']

  declare modalTarget: HTMLElement;
  declare hasModalTarget: boolean;

  declare modal: Modal | undefined;

  connect() {}

  hideModal(_event: Event) {
    this.modal?.hide();

    this.cleanup();
  }

  showModal(_event: Event) {
    this.modal?.show();
  }

  modalTargetConnected(target: HTMLElement) {
    // set the modal menu element
    const targetEl = target;

    // options with default values
    const options = {
      placement: "center" as modalPlacement,
      backdrop: this.backdropValue as modalBackdrop,
      backdropClasses: "backdrop-blur-sm bg-gray-900/50 fixed inset-0 z-40",
      closable: true,
      onHide: () => {},
      onShow: () => {},
      onToggle: () => {},
    };

    // instance options object
    const instanceOptions = {
      id: this.modalTarget.id,
      override: true,
    };

    this.modal = new Modal(targetEl, options, instanceOptions);
  }

  cleanup() {
    // this is a workaround since it looks like this is a current issue with flowbite and turbo. Ref: https://github.com/themesberg/flowbite/issues/545, https://github.com/themesberg/flowbite/issues/551, https://github.com/themesberg/flowbite/issues/579
    document.querySelectorAll("[modal-backdrop]").forEach((el: HTMLElement) => {
      el?.remove();
    });
  }
}
