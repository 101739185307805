import NestedForm from "stimulus-rails-nested-form";

// Connects to data-controller="nested-addepar-transaction-filter"
// Borrowed from https://github.com/stimulus-components/stimulus-rails-nested-form/blob/master/src/index.ts
export default class extends NestedForm {
  static targets = ["target", "template", "id", "row", "updateContainer"];

  declare templateTarget: HTMLTemplateElement;
  declare targetTarget: HTMLElement;
  declare rowTargets: HTMLElement[];
  declare updateContainerTargets: HTMLElement[];

  connect() {
    super.connect();
    this.applyClasses();
  }

  add(e: Event) {
    e.preventDefault();

    const clone = document.importNode(this.templateTarget.content, true) as DocumentFragment;
    const elementClone = clone.firstElementChild as HTMLElement | null;

    if (elementClone) {
      // Replace the NEW_RECORD placeholder with a unique number for rails to use to determine if the
      // record exists or not (it shouldn't)
      const content = elementClone.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());

      elementClone.innerHTML = content;

      this.targetTarget.insertAdjacentElement("beforebegin", elementClone);

      this.applyClasses();
    }
  }

  applyClasses() {
    this.updateContainerTargets.forEach((el, i) => {
      el.id = `addepar-transaction-filter-update-container-${i}`;
    });

    const nonDeletedRows = this.rowTargets.filter(row => (
      (row.querySelector("input[type='hidden'][name*='_destroy']") as HTMLInputElement)?.value !== '1') && row.childElementCount !== 0
    );

    if (nonDeletedRows.length === 1) {
      nonDeletedRows[0].querySelector("button")?.setAttribute("disabled", "disabled");
    } else {
      nonDeletedRows[0].querySelector("button")?.removeAttribute("disabled");
    }
  }

  remove(e: Event): void {
    super.remove(e);
    this.applyClasses();
  }
}
