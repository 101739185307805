import NestedForm from "stimulus-rails-nested-form";

// Connects to data-controller="nested-ledger-entry"
// Borrowed from https://github.com/stimulus-components/stimulus-rails-nested-form/blob/master/src/index.ts
export default class extends NestedForm {
  static targets = ["target", "template", "id", "ledgerEntryRow", "ledgerTransactionDescription", "ledgerEntryDescription", "addButton", "effectiveAtDateField"];
  static values = {
    wrapperSelector: {
      type: String,
      default: ".nested-form-wrapper",
    },
    posted: {
      type: Boolean,
      default: false,
    },
    selectedLedgerId: Number,
  };

  declare templateTarget: HTMLTemplateElement;
  declare targetTarget: HTMLElement;

  declare hasIdTarget: boolean;
  declare idTarget: HTMLSelectElement;
  declare addButtonTarget: HTMLButtonElement;
  declare effectiveAtDateFieldTarget: HTMLInputElement;

  declare ledgerEntryRowTargets: HTMLElement[];
  declare ledgerTransactionDescriptionTarget: HTMLTextAreaElement;
  declare ledgerEntryDescriptionTargets: HTMLTextAreaElement[];

  declare postedValue: string;

  connect() {
    super.connect();

    this.applyClasses();
    this.updateAccountUrls();
    this.disableIfPosted();
  }

  add(e: Event) {
    e.preventDefault();

    const clone = document.importNode(this.templateTarget.content, true) as DocumentFragment;

    const elementClone = clone.firstElementChild as HTMLElement | null;

    if (elementClone) {
      // Replace the NEW_LEDGER_ENTRY_RECORD placeholder with a unique number for rails to use to determine if the
      // record exists or no (it shouldn't)
      let content = elementClone.innerHTML.replace(/NEW_LEDGER_ENTRY_RECORD/g, new Date().getTime().toString());

      if (this.hasIdTarget) {
        content = content.replace(/LEDGER_ID/g, this.idTarget.value);
      }

      elementClone.innerHTML = content;

      this.targetTarget.insertAdjacentElement("beforebegin", elementClone);

      this.applyClasses();
    }
  }

  applyClasses() {
    this.ledgerEntryRowTargets.forEach((el, i) => {
      el.classList.add(`ledger-entry-row-${i}`);
    });
  }

  updateAccountUrls() {
    const ledgerId = this.idTarget.value;

    this.ledgerEntryRowTargets.forEach((el) => {
      el.innerHTML = el.innerHTML.replace(/LEDGER_ID/g, ledgerId);
    });
  }

  copyDescription() {
    this.ledgerEntryDescriptionTargets.forEach((el) => {
      el.value = this.ledgerTransactionDescriptionTarget.value;
    });
  }

  disableIfPosted() {
    if (this.postedValue) {
      this.idTarget.disabled = true;
    }
  }
}
