import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="ledger-name"
export default class extends Controller {
  static targets = ["ledgerSelect", "title"];

  declare ledgerSelectTarget: HTMLSelectElement;
  declare titleTarget: HTMLElement;

  connect() {
    this.updateName();
  }

  handleLedgerChange(_event) {
    this.updateName();
  }

  updateName() {
    this.titleTarget.textContent = this.ledgerSelectTarget.selectedOptions[0].text;
  }
}
