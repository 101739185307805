import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  connect() {
    super.connect()
  }

  remove(e: Event): void {
    super.remove(e);
    this.updateDescriptionSeparatorTitle();
  }

  updateDescriptionSeparatorTitle() {
    const titleContainers = document.querySelectorAll('.description-rule-title-container');

    const visibleTitleContainers = Array.from(titleContainers).filter(element => {
        return element.parentElement.style.display !== 'none';
    });

    visibleTitleContainers[0].classList.add('hidden')
  }
}
