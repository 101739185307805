import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scroll-to"
export default class extends Controller<HTMLAnchorElement> {
  static targets = ["link"];
  static values = {
    activeClass: {
      type: String,
      default: "active",
    },
  };

  declare readonly linkTargets: HTMLAnchorElement[];
  declare readonly hasLinkTarget: boolean;
  declare readonly activeClassValue: string;

  connect(): void {
    this.scrollTo(window.location.hash.replace(/^#/, ""));
  }

  handleClick(event: Event): void {
    event.preventDefault();

    const id: string = (event.target as HTMLAnchorElement).hash.replace(/^#/, "");

    this.scrollTo(id);
  }

  scrollTo(id: string): void {
    const target: HTMLElement = document.getElementById(id);

    if (!target) {
      console.warn(`[stimulus-scroll-to] The element with the id: "${id}" does not exist on the page.`);
      return;
    }

    // Replace the state to the browser's history stack
    history.replaceState(null, "", `#${id}`);

    target.scrollIntoView(<ScrollIntoViewOptions>{ behavior: "smooth" });

    this.setActiveState();
  }

  setActiveState(): void {
    if (this.hasLinkTarget) {
      this.linkTargets.forEach((link: HTMLAnchorElement) => {
        const id: string = link.hash.replace(/^#/, "");

        if (id === window.location.hash.replace(/^#/, "")) {
          link.classList.add(this.activeClassValue);
        } else {
          link.classList.remove(this.activeClassValue);
        }
      });
    }
  }
}
