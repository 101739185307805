import Dropzone from "dropzone";
import { DropzoneFile } from "dropzone";
import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import { getMetaValue, findElement, removeElement, insertAfter } from "../helpers";

interface CustomDropzoneFile extends DropzoneFile {
  controller: DirectUploadController;
}

// Pulled from https://web-crunch.com/posts/rails-drag-drop-active-storage-stimulus-dropzone

// Connects to data-controller="dropzone"
export default class DropZoneController extends Controller<HTMLDivElement> {
  static targets = ["input"];

  declare dropZone: Dropzone | undefined;
  declare inputTarget: HTMLInputElement;

  connect() {
    if (!this.dropZone) {
      this.dropZone = createDropZone(this);
    }
    this.hideFileInput();
    this.bindEvents();

    const dz = Dropzone;
    dz.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file: CustomDropzoneFile) => {
      setTimeout(() => {
        return file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", (file: CustomDropzoneFile) => {
      return file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", (file: CustomDropzoneFile) => {
      return file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url") || undefined;
  }

  get maxFiles() {
    return Number(this.data.get("maxFiles")) || 1;
  }

  get maxFileSize() {
    return Number(this.data.get("maxFileSize")) || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles") || undefined;
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") === "true";
  }

  get multiple() {
    return this.maxFiles > 1;
  }
}

class DirectUploadController {
  declare source: DropZoneController;

  declare directUpload: DirectUpload;
  declare hiddenInput: HTMLInputElement;
  declare file: CustomDropzoneFile;
  declare xhr: XMLHttpRequest;

  constructor(source: DropZoneController, file: CustomDropzoneFile) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput(): HTMLInputElement {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr: XMLHttpRequest) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) => this.uploadRequestDidProgress(event));
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100;
    (findElement(this.file.previewTemplate, ".dz-upload") as HTMLElement).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source: DropZoneController, file: CustomDropzoneFile) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file: CustomDropzoneFile, url: string, controller: DirectUploadController) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller: DropZoneController): Dropzone {
  // Other configuration options here https://docs.dropzone.dev/configuration/basics/configuration-options
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    uploadMultiple: controller.multiple,
    autoQueue: false,
  });
}
