import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="addepar-transaction-filter-preview"
export default class extends Controller {
  static targets = ["form", "previewButton", "previewPane"];

  static values = {
    url: String,
  };

  declare urlValue: string;

  declare formTarget: HTMLFormElement;
  declare previewButtonTarget: HTMLButtonElement;
  declare hasPreviewButtonTarget: boolean;

  declare previewPaneTarget: HTMLDivElement;

  connect() {
    this.getPreview();
  }

  togglePreviewPane() {
    this.previewPaneTarget.classList.toggle("hidden");
  }

  getPreview() {
    if (this.hasPreviewButtonTarget) {
      this.previewButtonTarget.disabled = true;
    }

    const formData = new FormData(this.formTarget);
    formData.delete("_method");

    const showPreview = !this.previewPaneTarget.classList.contains("hidden");

    post(this.urlValue, {
      query: {
        show_preview: showPreview,
      },
      body: formData,
      responseKind: "turbo-stream",
    });
  }
}
