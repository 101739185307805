import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import { Plaid as PlaidTypes } from "plaid-link";

const INVALID_LINK_TOKEN_ERROR_CODE = "INVALID_LINK_TOKEN";
const LINK_TOKEN_LOCAL_STORAGE_KEY = "linkTokenData";
const OAUTH_STATE_ID_PARAM = "oauth_state_id";

// Connects to data-controller="plaid"
export default class extends Controller<HTMLFormElement> {
  static targets = ["publicTokenInput", "metadataInput"];
  static values = { linkTokenUrl: String };

  declare linkHandler: PlaidTypes.LinkHandler;
  declare linkTokenUrlValue: string;
  declare publicTokenInputTarget: HTMLInputElement;
  declare metadataInputTarget: HTMLInputElement;

  connect() {
    const linkTokenData = localStorage.getItem(LINK_TOKEN_LOCAL_STORAGE_KEY);
    const oauthStateIdParam = new URL(window.location.toString()).searchParams.get(OAUTH_STATE_ID_PARAM);

    if (linkTokenData != null && oauthStateIdParam != null) {
      // This block is entered after completing the OAuth redirect flow,
      // which only happens when Link is initialized in a webview and when
      // the institution supports/requires OAuth

      // Plaid is imported through their CDN as a script tag on the HTML page. So when
      // using this be sure to add it to the header tag (as in
      // app/views/connections/index.html.erb).
      // @ts-ignore
      this.linkHandler = Plaid.create({
        token: JSON.parse(linkTokenData).link_token,
        receivedRedirectUri: window.location.href,
        onSuccess: this.onSuccess,
        onExit: this.onExit,
      }) as PlaidTypes.LinkHandler;

      this.linkHandler.open();
    } else {
      return;
    }
  }

  async addConnection() {
    // @ts-ignore
    this.linkHandler = Plaid.create({
      token: (await this.createLinkToken()).link_token,
      onSuccess: this.onSuccess,
      onExit: this.onExit,
    }) as PlaidTypes.LinkHandler;

    this.linkHandler.open();
  }

  async createLinkToken() {
    const response = await post(this.linkTokenUrlValue);
    const linkTokenData = response.json;

    if (response.ok) {
      localStorage.setItem(LINK_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(linkTokenData));
    }

    return linkTokenData;
  }

  onSuccess = (publicToken, metadata) => {
    localStorage.removeItem(LINK_TOKEN_LOCAL_STORAGE_KEY);
    // Send the public_token to server.
    this.publicTokenInputTarget.value = publicToken;
    this.metadataInputTarget.value = JSON.stringify(metadata);
    this.element.requestSubmit();
  };

  onExit = async (err, _metadata) => {
    localStorage.removeItem(LINK_TOKEN_LOCAL_STORAGE_KEY);
    // The user exited the Link flow with an INVALID_LINK_TOKEN error. This can happen if the
    // token expires or the user has attempted too many invalid logins.
    if (err != null && err.error_code === INVALID_LINK_TOKEN_ERROR_CODE) {
      this.linkHandler.destroy();
      // @ts-ignore
      this.linkHandler = Plaid.create({
        // Fetch a new link_token because the old one was invalidated.
        token: (await this.createLinkToken()).link_token,
        onSuccess: this.onSuccess,
        onExit: this.onExit,
      }) as PlaidTypes.LinkHandler;
    }
    // Note: metadata contains the most recent API request ID and the Link session ID.
    // Storing this information could be helpful for support.
  };
}
