import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import {
  FinicityConnect,
  ConnectEventHandlers,
  ConnectOptions,
  ConnectDoneEvent,
  ConnectCancelEvent,
  ConnectErrorEvent,
} from "@finicity/connect-web-sdk";

export default class extends Controller<HTMLFormElement> {
  static targets = ["connectButton", "connectButtonIcon", "loadingIcon"];
  static values = { connectUrl: String };

  declare connectButtonTarget: HTMLButtonElement;
  declare connectButtonIconTarget: SVGElement;
  declare loadingIconTarget: SVGElement;
  declare connectUrlValue: string;

  disconnect() {
    this.hideLoader();
  }

  async makeConnection() {
    this.showLoader();

    const jsonResponse = await this.generateConnectUrl();
    const connectUrl = jsonResponse.link;

    if (jsonResponse.error) {
      console.error(`Error generating Connect URL. ${jsonResponse.error}`);
      return;
    } else if (!connectUrl) {
      console.error(`Error generating Connect URL. No link returned.`);
      return;
    }

    const connectEventHandlers: ConnectEventHandlers = {
      onDone: (_event: ConnectDoneEvent) => {
        this.element.requestSubmit();
      },
      onCancel: (_event: ConnectCancelEvent) => {
        this.hideLoader();
      },
      onError: (event: ConnectErrorEvent) => {
        this.hideLoader();
        console.error(event);
      },
    };

    const connectOptions: ConnectOptions = {
      overlay: "rgba(199,201,199, 0.5)",
    };

    FinicityConnect.launch(connectUrl, connectEventHandlers, connectOptions);
  }

  async generateConnectUrl(): Promise<{ link?: string; error?: string }> {
    const response = await post(this.connectUrlValue, { body: {}, responseKind: "json" });

    return response.json;
  }

  showLoader() {
    this.connectButtonTarget.disabled = true;
    this.connectButtonIconTarget.classList.add("hidden");
    this.loadingIconTarget.classList.remove("hidden");
  }

  hideLoader() {
    this.connectButtonTarget.disabled = false;
    this.connectButtonIconTarget.classList.remove("hidden");
    this.loadingIconTarget.classList.add("hidden");
  }
}

