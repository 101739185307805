import { Controller } from "@hotwired/stimulus";


// Connects to data-controller="ignore-transaction"
export default class extends Controller {
  static targets = ["checkbox", "selectors", "applyToExisting"];

  declare checkboxTarget: HTMLInputElement;
  declare selectorsTarget: HTMLElement;
  declare applyToExistingTarget: HTMLElement;

  declare hasApplyToExistingTarget: boolean;

  connect() {
    this.update();
  }

  update() {
    this.selectorsTarget.classList.toggle("hidden", this.checkboxTarget.checked);

    if (this.hasApplyToExistingTarget) {
      this.applyToExistingTarget.classList.toggle("hidden", !this.checkboxTarget.checked);
    }
  }

}