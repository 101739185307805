import { RowNode } from "ag-grid-enterprise";

// A section header is a root-level row that is a group header like "Assets" or "Expenses"
export function isSectionHeader(node: RowNode): boolean {
  const isRootLevel = node.getGroupKeys().length === 1;

  return isRootLevel && isGroupHeader(node);
}

export function isSectionFooter(node: RowNode): boolean {
  const isRootLevel = node.getGroupKeys().length === 1;

  return isRootLevel && isGroupFooter(node);
}

// A group header is a row that is a group header for an account with sub-accounts like
// "Cash" or "Accounts Receivable"
export function isGroupHeader(node: RowNode): boolean {
  return (node.group || node.hasChildren()) && !node.footer;
}

export function isGroupFooter(node: RowNode): boolean {
  return (node.group || node.hasChildren()) && node.footer;
}
