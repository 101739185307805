import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sum-it-color-picker"
export default class extends Controller {
  // Ensure the currently selected color is highlighted
  connect() {
    const label = this.#currentlyCheckedLabel();
    this.#highlightSelectedColor(label);
  }

  hoverOption(event) {
    this.#highlightSelectedColor(event.currentTarget);
  }

  leaveOption(event) {
    const inputRadioId = event.currentTarget.querySelectorAll("label")[0].htmlFor;
    const inputRadio = document.getElementById(inputRadioId) as HTMLInputElement;

    if (!inputRadio.checked) {
      const labelBorder = event.currentTarget.querySelectorAll('.color-circle-option-border')[0];
      labelBorder.style.borderColor = 'transparent';
    }
  }

  selectOption(event) {
    const colorPickerPreview = document.getElementById('color_picker_preview');
    const colorPickerPreviewText = colorPickerPreview.querySelectorAll('.color-circle-option-text')[0] as HTMLElement;
    const labelColor = event.currentTarget.querySelectorAll('.color-circle-option')[0];
    const labelText = event.currentTarget.querySelectorAll('.color-circle-option-text')[0];

    colorPickerPreview.style.backgroundColor = labelColor.style.backgroundColor;
    labelColor.closest('.color-circle-option-border').style.borderColor = labelColor.style.backgroundColor;
    colorPickerPreviewText.style.color = labelText.style.color;

    const label = this.#currentlyCheckedLabel();
    const labelBorder = label.querySelectorAll('.color-circle-option-border')[0] as HTMLElement;
    labelBorder.style.borderColor = 'transparent';
  }

  #currentlyCheckedLabel() {
    const currentlyChecked = document.querySelector('input[name="tag_group[color]"]:checked');
    const label = document.querySelector("label[for='" + currentlyChecked.id + "']");
    return label;
  }

  #highlightSelectedColor(el) {
    const labelColor = el.querySelectorAll('.color-circle-option')[0];
    const labelBorder = el.querySelectorAll('.color-circle-option-border')[0];
    labelBorder.style.borderColor = labelColor.style.backgroundColor;
  }
}
