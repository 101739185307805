import NestedForm from "stimulus-rails-nested-form";

// Leaving this here in case we need to mess with the functionality
// https://www.stimulus-components.com/docs/stimulus-rails-nested-form
export default class extends NestedForm {
  static targets = ["ledgerTransactionDescription", "ledgerEntryDescription"];

  declare ledgerTransactionDescriptionTarget: HTMLTextAreaElement;
  declare ledgerEntryDescriptionTargets: HTMLTextAreaElement[];

  connect() {
    super.connect();
  }

  copyDescription() {
    this.ledgerEntryDescriptionTargets.forEach((el) => {
      el.value = this.ledgerTransactionDescriptionTarget.value;
    });
  }
}
