import { GridApi } from "ag-grid-enterprise";
import { post, Response } from "@rails/request.js";

export class CsvExporter {
  private gridApi: GridApi;

  constructor(gridApi: GridApi) {
    this.gridApi = gridApi;
  }

  private getDownloadPath(event: Event): string {
    return (event.currentTarget as HTMLButtonElement).form.action;
  }

  private prepareParams() {
    const filterModel = this.gridApi.getFilterModel();
    const sorting = this.gridApi.getColumnState();
    const sortObject = sorting.find((sort) => sort.sort !== null);
    const sortModel = sortObject ? [{ sort: sortObject.sort, colId: sortObject.colId }] : [];
    return { filterModel, sortModel };
  }

  private handleResponse(response: Response) {
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    return response.response.blob();
  }

  public exportCSV(event: Event, filename: string) {
    const button = event.currentTarget as HTMLButtonElement;
    const downloadPath = this.getDownloadPath(event);
    const params = this.prepareParams();

    button.disabled = true;

    post(downloadPath, {
      body: params,
      headers: { accept: "text/csv" },
    })
      .then(this.handleResponse)
      .then((blob: Blob) => {
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        const now = new Date();
        const formattedDate = now.toISOString().replace(/-|:|\.\d\d\d/g, "");
        a.download = `${filename}_${formattedDate}.csv`;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error exporting CSV:", error);
      })
      .finally(() => {
        button.disabled = false;
      });
  }
}
