import { IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-enterprise";
import { post } from "@rails/request.js";
import { IServerSideData } from "../types";
import { AgGridEvent } from "ag-grid-community";

const dispatchRowSelectedEvent = (params: IServerSideGetRowsParams, _response: IServerSideData) => {
  const event = {
    type: "rowSelected",
    api: params.api,
    node: params.parentNode,
  } as unknown as AgGridEvent;

  params.api.dispatchEvent(event);
};

export const createServerSideDatasource = (datasourceUrl: string, formData: () => FormData, pageSize: number) => {
  return {
    getRows: (params: IServerSideGetRowsParams) => {
      const queryParams = new URLSearchParams(formData() as any); // eslint-disable-line
      queryParams.append("pageSize", pageSize.toString());

      const url = new URL(datasourceUrl);
      url.search = queryParams.toString();

      post(url, {
        body: JSON.stringify(params.request),
        contentType: "application/json",
        headers: { Accept: "application/json" },
      })
        .then((httpResponse) => httpResponse.json)
        .then((response: IServerSideData) => {
          params.success({
            rowData: response.rows,
            rowCount: response.lastRow,
          });

          if (response.rows.length == 0) {
            params.api.showNoRowsOverlay();
          } else {
            params.api.hideOverlay();
          }

          dispatchRowSelectedEvent(params, response);
        })
        .catch((error) => {
          console.error(error);
          params.fail();
        });
    },
  } as IServerSideDatasource;
};
