import { Controller } from "@hotwired/stimulus"
import { get, post } from "@rails/request.js";

// Connects to data-controller="reconciliation"
export default class extends Controller {
  static targets = ["accountIdInput", "startDateInput"];

  static values = {
    startingBalanceUrl: String,
    updateSelectedUrl: String,
    startingDateUrl: String,
  };

  declare accountIdInputTarget: HTMLInputElement;
  declare hasAccountIdInputTarget: boolean;
  declare hasStartDateInputTarget: boolean;
  declare startDateInputTarget: HTMLInputElement;
  declare startingBalanceInputTarget: HTMLInputElement;

  declare hasStartingBalanceUrlValue: boolean;
  declare startingBalanceUrlValue: string;
  declare hasUpdateSelectedUrlValue: boolean;
  declare updateSelectedUrlValue: string;
  declare hasStartingDateUrlValue: boolean;
  declare startingDateUrlValue: string;

  updateSelected(event) {
    if (this.hasUpdateSelectedUrlValue) {
      post(this.updateSelectedUrlValue, {
        body: JSON.stringify({
          reconciliation: {
            ledger_entry_ids: [event.currentTarget.value],
            selected: event.currentTarget.checked,
          },
        }),
        responseKind: "turbo-stream",
      });
    }
  }

  updateStartingDate(event: Event) {
    if (event.target == this.startDateInputTarget) {
      return;
    }

    if (this.hasStartingDateUrlValue) {
      get(this.startingDateUrlValue, {
        query: {
          account_id: this.accountIdInputTarget.value,
        },
        responseKind: "turbo-stream",
      });
    }
  }

  accountIdInputTargetConnected() {
    this.updateStartingBalance();
  }

  startDateInputTargetConnected() {
    this.updateStartingBalance();
  }

  async handleAccountUpdate(event) {
    this.updateStartingDate(event);
    this.updateStartingBalance();
  }

  async updateStartingBalance() {
    if (
      this.hasStartingBalanceUrlValue &&
      this.hasAccountIdInputTarget &&
      this.hasStartDateInputTarget
    ) {
      await get(this.startingBalanceUrlValue, {
        query: { account_id: this.accountIdInputTarget.value, start_date: this.startDateInputTarget.value },
        responseKind: "turbo-stream",
      });
    }
  }
}
