import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import * as Turbo from "@hotwired/turbo";

// Connects to data-controller="bank-register"
export default class extends Controller {
  static targets = ["accountSelect", "ledgerSelect", "dateSelect", "search", "startDate", "endDate"];

  static values = {
    url: String
  };

  declare accountSelectTarget: HTMLSelectElement;
  declare ledgerSelectTarget: HTMLSelectElement;
  declare dateSelectTarget: HTMLSelectElement;
  declare hasDateSelectTarget: boolean;
  declare startDateTarget: HTMLInputElement;
  declare hasStartDateTarget: boolean;
  declare endDateTarget: HTMLInputElement;
  declare hasEndDateTarget: boolean;
  declare searchTarget: HTMLInputElement;
  declare hasSearchTarget: boolean;
  declare urlValue: string;

  ledgerChanged(_event: Event) {
    this.updateTargetStreamsRequest(["bank_register_add_transaction", "bank_register_account_options", "bank_register_date_filter",
      "bank_register_search", "bank_register_account_balance", "bank_register_entries"]);
  }

  accountChanged(_event: Event) {
    this.updateTargetStreamsRequest();
  }

  dateChanged(_event: Event) {
    this.updateTargetStreamsRequest(["bank_register_account_balance", "bank_register_entries"]);
  }

  searchChanged(_event: Event) {
    this.updateTargetStreamsRequest(["bank_register_entries"]);
  }

  updateTargetStreamsRequest(targetStreams: string[] = ["bank_register_add_transaction", "bank_register_date_filter", "bank_register_search",
    "bank_register_account_balance", "bank_register_entries"]) {
    const selectedLedgerId = this.ledgerSelectTarget.selectedOptions[0].value;
    const selectedAccountId = this.accountSelectTarget.selectedOptions[0].value;

    let searchQuery = "";
    if (this.hasSearchTarget) {
      searchQuery = this.searchTarget.value;
    }

    let startDate = "";
    if (this.hasStartDateTarget) {
      startDate = this.startDateTarget.value;
    }

    let endDate = "";
    if (this.hasEndDateTarget) {
      endDate = this.endDateTarget.value;
    }

    let selectedDateRange = "";
    if (this.hasDateSelectTarget) {
      selectedDateRange = this.dateSelectTarget.selectedOptions[0].value;
      if(selectedDateRange === "Custom" && startDate === "" && endDate === "") {
        return;
      }
    }

    const query = {
      ledger_id: selectedLedgerId,
      account_id: selectedAccountId,
      q: searchQuery,
      date_range: selectedDateRange,
      start_date: startDate,
      end_date: endDate
    }

    const targetStreamsQueryString = targetStreams.map(stream => `target_streams[]=${encodeURIComponent(stream)}`).join('&');
    const fullQueryString = `${new URLSearchParams(query).toString()}&${targetStreamsQueryString}`;

    get(`${this.urlValue}?${fullQueryString}`, {
      responseKind: "turbo-stream",
    }).then((response) => {
      const newUrl = response?.response?.url;

      if (newUrl) {
        const [baseUrl, queryParams] = newUrl.split('?');
        const htmlUrl = `${baseUrl.endsWith('.html') ? baseUrl : baseUrl + '.html'}${queryParams ? '?' + queryParams : ''}`;
        history.pushState({}, "", htmlUrl);
        Turbo.navigator.history.push(new URL(htmlUrl));
      }
    });
  }
}
