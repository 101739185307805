import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="ledger-entries"
export default class extends Controller {
  static targets = ["debitsTotal", "creditsTotal", "row", "accountSelect", "accountCodeSelect", "deleteRowButton"];

  declare accountSelectTargets: HTMLInputElement[];
  declare accountCodeSelectTargets: HTMLInputElement[];

  declare debitsTotalTarget: HTMLElement;
  declare hasDebitsTotalTarget: boolean;

  declare creditsTotalTarget: HTMLElement;
  declare hasCreditsTotalTarget: boolean;

  declare rowTargets: HTMLSpanElement[];
  declare deleteRowButtonTargets: HTMLButtonElement[];

  connect() {
  }

  rowTargetConnected(_element: HTMLDivElement) {
    this.handleUpdates();
  }

  rowTargetDisconnected(_element: HTMLDivElement) {
    this.handleUpdates();
  }

  handleUpdates(event?: Event) {
    const targetInput: HTMLInputElement = event?.currentTarget as HTMLInputElement;

    let rowNumber = 1;
    let creditsTotal = 0;
    let debitsTotal = 0;

    // Iterate through all the row elements and update the row number for each row, and keep track
    // of the total credits and debits.
    this.rowTargets.forEach((row: HTMLDivElement) => {
      // If the row contains an element marked for removal, we don't want to include it in the total.
      if (row.querySelector("input[name*='_destroy'][value='1']")) {
        return;
      }

      const counter = row.querySelector(".counter") as HTMLSpanElement;
      counter.innerText = (rowNumber++).toString();

      this.clearContraInput(targetInput, row);

      creditsTotal += this.extractNumber(row, "credits");
      debitsTotal += this.extractNumber(row, "debits");
    });

    if (this.hasCreditsTotalTarget) {
      this.setTotal(this.creditsTotalTarget, creditsTotal);
    }

    if (this.hasDebitsTotalTarget) {
      this.setTotal(this.debitsTotalTarget, debitsTotal);
    }
  }

  clearContraInput(targetInput: HTMLInputElement, row: HTMLDivElement) {
    if (targetInput) {
      const isCreditUpdate = targetInput.name.includes("credits")

      const rowDebitInput = this.getInputByName(row, "debits")
      const rowCreditInput = this.getInputByName(row, "credits")

      if (isCreditUpdate && rowCreditInput.id === targetInput.id && this.extractNumber(row, "credits") !== 0) {
        rowDebitInput.value = "$0.00";
      } else if(!isCreditUpdate && rowDebitInput.id === targetInput.id && this.extractNumber(row, "debits") !== 0) {
        rowCreditInput.value = "$0.00";
      }
    }
  }

  setTotal(target: HTMLElement, total: number) {
    target.innerText = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(total);
  }

  extractNumber(row: HTMLDivElement, name: string): number {
    const input = this.getInputByName(row, name);

    if (input) {
      const numericValue = input.value.replace(/^0+|[^0-9.]/g, '');
      const value = parseFloat(numericValue);

      if (!isNaN(value)) {
        return value;
      }
    }

    return 0;
  }

  getInputByName(row: HTMLDivElement, name: string): HTMLInputElement {
    return row.querySelector(`input[name*='${name}']`) as HTMLInputElement;
  }
}
