import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { post, get } from "@rails/request.js";
import { loadingIcon } from "../helpers";

interface IVendor {
  id: string;
  name: string;
}

// Connects to data-controller="vendor-select"
export default class extends Controller<HTMLSelectElement> {
  declare select: TomSelect;

  static values = {
    vendorsUrl: String,
    classes: Array,
    multi: Boolean,
    closeAfterSelect: { type: Boolean, default: true },
    allowCreate: { type: Boolean, default: false },
  };

  declare vendorsUrlValue: string;
  declare hasVendorsUrlValue: boolean;
  declare allowCreateValue: boolean;
  declare multiValue: boolean;
  declare hasClassesValue: boolean;
  declare classesValue: string[];
  declare hasCloseAfterSelectValue: boolean;
  declare closeAfterSelectValue: boolean;

  connect() {
    let plugins = ["remove_button"];
    if (!this.multiValue) {
      plugins = [];
    }

    this.select = new TomSelect(this.element, {
      plugins: plugins,
      hidePlaceholder: true,
      valueField: "id",
      labelField: "name",
      searchField: ["name"],
      closeAfterSelect: this.closeAfterSelectValue,
      create: this.hasVendorsUrlValue && this.allowCreateValue ? this.createVendor.bind(this) : false,
      load: this.hasVendorsUrlValue ? this.searchVendor.bind(this) : false,
      render: {
        loading: (_data, _escape) => {
          return `
            <div class="p-1 flex flex-row gap-2 items-center">
              ${loadingIcon()}
              <span>Loading...</span>
            </div>
          `;
        },
        option: (data, escape) => {
          if (data.disabled || String(data.disabledLabel).toLowerCase() === "true") {
            return `
              <div class="flex flex-row gap-2 items-center justify-between">
                <span class="text-gray-700">${escape(data.name)}</span>
                <span class="bg-gray-200 rounded px-2 py-0.5 text-gray-600 text-xs font-medium">Deactivated</span>
              </div>
            `;
          } else {
            return `
              <div class="flex flex-row gap-2 items-center">
                <span class="text-gray-700">${escape(data.name)}</span>
              </div>
            `;
          }
        },
      },
    });

    if (this.hasClassesValue) {
      this.classesValue.forEach((className) => {
        this.select.control.classList.add(className);
      });
    }
  }

  async createVendor(input: string, callback: (vendor: IVendor) => void) {
    const response = await post(this.vendorsUrlValue, {
      body: JSON.stringify({ vendor: { name: input } }),
      responseKind: "json",
    });

    if (response.ok) {
      const vendor = await response.json;
      callback(vendor);
    }
  }

  async searchVendor(input: string, callback: (vendors: { id: string; name: string }[]) => void) {
    const url = new URL(this.vendorsUrlValue, window.location.href);
    url.searchParams.append("q", input);

    const response = await get(url.toString(), {
      responseKind: "json",
    });

    if (response.ok) {
      const vendors = (await response.json) as IVendor[];
      callback(vendors);
    }
  }

  disconnect() {
    this.select.destroy();
  }

  unfocusSelect() {
    this.select.blur();
  }
}
