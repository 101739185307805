import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="submit"
export default class extends Controller<HTMLFormElement> {
  static values = {
    frameId: String,
  };

  declare hasFrameIdValue: boolean;
  declare frameIdValue: string | undefined;

  submit() {
    this.element.requestSubmit();
  }

  submitForm() {
    this.element.form.requestSubmit();
  }

  checkResponse(event) {
    if (event.detail.success) {
      this.dispatch("success");
    }
  }

  checkResponseAndRedirect(event) {
    const response = event.detail.fetchResponse.response;

    if (response.ok) {
      const redirectUrl = response.url;

      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
  }

  // This is used to prevent flashing an error message on form submit when the user is redirected
  // Since the redirect response is not a Turbo frame, the frame-missing event is fired, so we just hide the frame
  hideFrame() {
    document.getElementById(this.frameIdValue).hidden = true;
  }
}
