import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  static targets = ["input", "results", "emptyResultMessage"];
  static values = {
    url: String,
    resultCount: Number
  };

  declare inputTarget: HTMLInputElement;
  declare resultsTarget: HTMLElement;
  declare emptyResultMessageTarget: HTMLElement;
  declare urlValue: string;
  declare resultCountValue: number;

  async search() {
    if (this.inputTarget.value.length > 2) {
      const response = await fetch(`${this.urlValue}?q=${encodeURIComponent(this.inputTarget.value)}&count=${this.resultCountValue}`, {
        headers: {
          "Accept": "text/vnd.turbo-stream.html",
        },
      });
      const stream = await response.text();
      this.emptyResultMessageTarget.classList.add("hidden");
      Turbo.renderStreamMessage(stream);
    } else {
      this.emptyResultMessageTarget.classList.remove("hidden");
      this.resultsTarget.innerHTML = "";
    }
  }
}
