import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

import cytoscape, { ElementDefinition } from "cytoscape";

interface Node {
  id: string;
  name: string;
  label: string;
  type: string;
  style: string;
  tax_classification: string;
  tax_classification_style: string;
  incorporated_on: string;
  accessible: boolean;
}

interface Position {
  x: number;
  y: number;
}

interface EdgeData {
  id: string;
  source: string;
  target: string;
  label: string;
  direction: string;
  bendPointPositions: Position[];
}

interface Edge extends ElementDefinition {
  data: EdgeData;
}

// The extended Core module with plugins/extensions
interface ExtendedCore extends cytoscape.Core {
  domNode: () => void | undefined;
  edgeEditing: (input: string | object) => EdgeEditorInstance | undefined;
}

interface EdgeEditorInstance {
  getAnchorsAsArray: (edge: cytoscape.EdgeSingular) => number[];
  initAnchorPoints: (edges: cytoscape.EdgeCollection) => void;
}

// Connects to data-controller="entity-graph"
export default class extends Controller {
  static targets = ["entityContainer", "nodeTemplate"];

  static values = {
    nodes: Array,
    edges: Array,
    turboFrameId: String,
    entitiesUrl: String,
    entityGraphsUrl: String,
    positions: Object,
  };

  declare cy: ExtendedCore;
  declare entityContainerTarget: HTMLDivElement;
  declare entityGraphsUrlValue: string;
  declare positionsValue: object;
  declare edgesValue: Edge[];
  declare nodesValue: Node[];
  declare nodeTemplateTarget: HTMLTemplateElement;
  declare extensionsRegistered: boolean | undefined;

  connect() {
    // @ts-ignore
    this.cy = this.initializeGraph();

    this.cy.domNode(); // initialize cytoscape-dom-node

    this.addEventListeners(this.cy);

    // Initialize the edge editing library
    this.cy.edgeEditing({
      bendRemovalSensitivity: 8,
      initAnchorsAutomatically: true,
      bendPositionsFunction: (element) => {
        return element.data("bendPointPositions");
      },
      // Edge editing is powered by Konva, which is a canvas library. Setting the zIndex to 0 here where the default
      // is 999. Documentation on the zIndex available here https://konvajs.org/docs/groups_and_layers/zIndex.html
      zIndex: 0,
    });
  }

  disconnect(): void {
    this.cy.removeAllListeners();
  }

  addEventListeners(cy) {
    // Events outlined here: https://js.cytoscape.org/#events
    cy.on("layoutready", (_event: cytoscape.EventObject) => {
      this.setEdgePositions();
    });

    cy.on("tap", "node*", (event: cytoscape.EventObject) => {
      const element = event.target.data("dom") as HTMLDivElement;
      // The template(s) defined in the views have anchors with links
      const anchorElements = element.getElementsByTagName("a");

      if (anchorElements.length === 1) {
        anchorElements[0].click();
      }
    });
  }

  initializeGraph() {
    const colorFunction = (element) => {
      if (element.data().direction === "reverse") {
        return "#008698";
      } else {
        return "#888";
      }
    };

    const sourceArrowFunction = (element) => {
      if (element.data().direction === "reverse") {
        return "circle";
      } else {
        return "none";
      }
    };

    return cytoscape({
      container: this.entityContainerTarget,
      elements: [...this.nodes(), ...this.edgesValue],
      style: [
        {
          selector: "node",
          style: {
            "background-color": "transparent",
            "background-opacity": 0,
          },
        },
        {
          selector: "edge",
          style: {
            width: 2,
            "line-color": colorFunction,
            "target-arrow-color": colorFunction,
            "target-arrow-shape": "circle",
            "source-arrow-color": colorFunction,
            "source-arrow-shape": sourceArrowFunction,
            "curve-style": "segments",
          },
        },
        {
          selector: ".view-label",
          style: {
            label: "data(label)",
            color: "white",
            "text-background-opacity": 1,
            "text-background-color": colorFunction,
            "text-background-shape": "roundrectangle",
            "text-background-padding": "4px",
            "font-size": "12px",
          },
        },
      ],
      layout: {
        name: "elk",
        transform: (node, position) => {
          if (Object.keys(this.positionsValue).length) {
            return this.positionsValue[node.id()];
          } else {
            return position;
          }
        },
        // @ts-ignore
        elk: {
          algorithm: "layered",
          "elk.direction": "DOWN",
          "nodeLabels.bk.fixedAlignment": "BALANCED",
          "elk.spacing.nodeNode": 200, // Horizontal Spacing
          "elk.layered.spacing.baseValue": 80, // Vertical spacing
        },
      },
      userZoomingEnabled: true,
      wheelSensitivity: 0.2,
      minZoom: 0.4,
      maxZoom: 2.5
    });
  }

  renderIfExists(content, template, content_tag, visibility_tag) {
    if(content) {
      const regex = new RegExp(content_tag, 'g')
      return template.replace(regex, content)
    } else {
      const regex = new RegExp(visibility_tag, 'g')
      return template.replace(regex, "hidden")
    }
  }

  createDomNodeFromTemplate(node: Node) {
    const div = document.createElement("div");

    div.innerHTML = this.nodeTemplateTarget.innerHTML
      .replace(/ENTITY_NAME/g, node.name)
      .replace(/ENTITY_TYPE/g, node.label)
      .replace(/ENTITY_ID/g, node.id)
      .replace(/TAG_COLORS/g, node.style);

    div.innerHTML = this.renderIfExists(node.incorporated_on, div.innerHTML, "INCORPORATED_ON", "INCORPORATED_VISIBILITY")

    div.innerHTML = this.renderIfExists(node.tax_classification, div.innerHTML, "TAX_CLASSIFICATION", "TAX_VISIBILITY")
    div.innerHTML = div.innerHTML.replace(/TAX_COLORS/g, node.tax_classification_style)

    // Remove links if the user cannot access the entity details
    if (!node.accessible) {
      div.querySelector("a").remove();
    }

    return div;
  }

  nodes() {
    return this.nodesValue.map((node: Node) => {
      return {
        data: {
          id: `${node.id}`,
          dom: this.createDomNodeFromTemplate(node),
        },
      };
    });
  }

  savePositions(event) {
    const currentTarget = event.currentTarget;
    this.showLoader(currentTarget);

    const edgeEditor = this.cy.edgeEditing("get");

    const positions = this.cy.nodes().reduce((obj, node) => {
      const id = node.id();
      return { ...obj, [id]: node.position() };
    }, []);

    const bendPointPositions = this.cy.edges().reduce((obj, edge) => {
      const id = edge.id();
      // As implied in the name, "getAnchorsAsArray" returns the anchor locations
      // as an array, however "initAnchorPoints" expects an array of objects with
      // x and y positions. So before saving positions on the server side, we convert
      // the array of numbers to an array x and y coordiantes.
      // e.g. from [1, 2, 3, 4] to
      // [
      //   { x: 1, y: 2 },
      //   { x: 3, y: 4 }
      // ]
      const positionsArray: number[] = edgeEditor.getAnchorsAsArray(edge);
      const coordinatesArray = this.convertArrayToCoordinates(positionsArray);

      return { ...obj, [id]: coordinatesArray };
    }, []);

    post(this.entityGraphsUrlValue, { body: { entity_graph: { nodes: positions, edges: bendPointPositions } } }).then(
      (response) => {
        this.hideLoader(currentTarget);

        if (response.statusCode === 200) {
          this.showIndicator(currentTarget, true);
        } else {
          this.showIndicator(currentTarget, false);
        }
      },
    );
  }

  toggleLabels(event: Event) {
    event.preventDefault();
    this.cy.edges().toggleClass("view-label");
  }

  setEdgePositions() {
    const edgeEditorInstance = this.cy.edgeEditing("get");

    if (edgeEditorInstance) {
      edgeEditorInstance.initAnchorPoints(this.cy.edges());
    }
  }

  convertArrayToCoordinates(positionsArray: number[]): Position[] {
    const coordinatesArray: Position[] = [];

    for (let idx = 0; idx < positionsArray.length; idx += 2) {
      const x = positionsArray[idx];
      const y = positionsArray[idx + 1];

      if (x && y) {
        coordinatesArray.push({ x, y });
      }
    }

    return coordinatesArray;
  }

  showLoader(currentTarget) {
    currentTarget.querySelector("span").classList.add("hidden");
    currentTarget.querySelector("div#layout_save_spinner").classList.remove("hidden");
  }

  hideLoader(currentTarget) {
    currentTarget.querySelector("div#layout_save_spinner").classList.add("hidden");
  }

  showIndicator(currentTarget, success) {
    const indicator = success ? "layout_save_success" : "layout_save_failure";

    currentTarget.querySelector("div#" + indicator).classList.remove("hidden");
    currentTarget.querySelector("div#" + indicator).classList.add("visible");

    setTimeout(() => {
      this.hideIndicator(currentTarget, success);
    }, 1000);
  }

  hideIndicator(currentTarget, success) {
    const indicator = success ? "layout_save_success" : "layout_save_failure";

    currentTarget.querySelector("div#" + indicator).classList.remove("visible");

    setTimeout(() => {
      currentTarget.querySelector("div#" + indicator).classList.add("hidden");
      currentTarget.querySelector("span").classList.remove("hidden");
    }, 500);
  }
}
