import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="removable"
export default class extends Controller {
  static targets = ["removable", "removeButton"];

  declare removableTargets: HTMLElement[];
  declare removeButtonTargets: HTMLButtonElement[];

  connect(): void {
    this.setButtonStates();
  }

  removeElement(event) {
    event.preventDefault();

    const id = event.params.id;
    const target = this.removableTargets.find((el) => el.id === id);

    if (target) {
      target.remove();

      this.setButtonStates();
    }
  }

  setButtonStates() {
    if (this.removableTargets.length === 1) {
      this.removeButtonTargets.forEach((button) => {
        button.disabled = true;
        button.classList.add("hidden");
      });
    } else {
      this.removeButtonTargets.forEach((button) => {
        button.disabled = false;
        button.classList.remove("hidden");
      });
    }
  }
}
