import { ValueFormatterParams } from "ag-grid-community";
import { DataTypeDefinition } from "ag-grid-enterprise";

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const percentageFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const dataTypeDefinitions = {
  number: {
    baseDataType: "number",
    extendsDataType: "number",
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value == null) {
        return null;
      }

      return numberFormatter.format(params.value);
    },
  } as DataTypeDefinition,
  percentage: {
    baseDataType: "number",
    extendsDataType: "number",
    valueFormatter: (params: ValueFormatterParams) => {
      // for total rows the valueFormatter from the dataTypeDefinitions won't work as the rows have already been formatted.
      // Check the note at the end of this paragraph: https://www.ag-grid.com/javascript-data-grid/cell-data-types/#pre-defined-cell-data-types
      // in order for this to work we set a valueFormatter in the column definition and point it to this function.
      if (isNaN(params.value)) {
        return "N/A";
      } else {
        return percentageFormatter.format(params.value);
      }
    },
  } as DataTypeDefinition,
  dateString: {
    baseDataType: "dateString",
    extendsDataType: "dateString",
    valueFormatter: (params) => {
      if (params.value == null) {
        return null;
      }

      const dateParts = params.value.split("-");

      if (dateParts.length === 3) {
        const date = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]));

        // Export date in the format: Mmm DD, YYYY
        return date.toLocaleDateString("en-US", { year: "numeric", month: "short", day: "2-digit" });
      } else {
        return null;
      }
    },
  } as DataTypeDefinition,
};

export const formatDateForDisplay = (date: string | Date | null): string => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  }).format(new Date(date));
};
