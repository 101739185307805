import { Controller } from "@hotwired/stimulus";

import { post } from "@rails/request.js";

interface ValuesTargets {
  min?: string;
  max?: string;
  value: string | string[];
}

export default class extends Controller {
  static targets = [
    "amountActive",
    "amountOperator",
    "amountValue",
    "amountRangeStart",
    "amountRangeEnd",
    "descriptionActive",
    "descriptionOperator",
    "descriptionValue",
    "dateActive",
    "dateOperator",
    "dateValue",
    "dateRangeStart",
    "dateRangeEnd",
    "ledgerIdActive",
    "ledgerIdOperator",
    "ledgerIdValue",
    "accountTemplateIdActive",
    "accountTemplateIdOperator",
    "accountTemplateIdValue",
    "previewButton"
  ];

  declare amountActiveTarget: HTMLInputElement;
  declare amountOperatorTarget: HTMLSelectElement;
  declare amountValueTarget: HTMLInputElement;
  declare amountRangeStartTarget: HTMLInputElement;
  declare amountRangeEndTarget: HTMLInputElement;

  declare descriptionOperatorTargets: HTMLSelectElement[];
  declare descriptionValueTargets: HTMLInputElement[];

  declare dateActiveTarget: HTMLInputElement;
  declare dateOperatorTarget: HTMLSelectElement;
  declare dateValueTarget: HTMLInputElement;
  declare dateRangeStartTarget: HTMLInputElement;
  declare dateRangeEndTarget: HTMLInputElement;

  declare ledgerIdValueTarget: HTMLSelectElement;

  declare accountTemplateIdValueTarget: HTMLSelectElement;

  declare previewButtonTarget: HTMLButtonElement;
  declare hasPreviewButtonTarget: boolean;

  getPreview() {
    if (this.hasPreviewButtonTarget) {
      this.previewButtonTarget.disabled = true;
    }

    post(
      '/account_reconciliation/rules/rule_preview', 
      {
        body: this.buildConditions(),
        responseKind: "turbo-stream",
      }
    );
  }

  buildConditions() {
    let conditions = [];

    let condition = this.getCondition("amount", this.amountOperatorTarget.value, {min: this.amountRangeStartTarget.value, max: this.amountRangeEndTarget.value, value: this.amountValueTarget.value});
    if (this.amountActiveTarget.checked && condition) {
      conditions.push(condition)
    }

    this.descriptionOperatorTargets.forEach((descriptionOperatorTarget, index) => {
      condition = this.getCondition("description", descriptionOperatorTarget.value, {value: this.descriptionValueTargets[index].value});
      if (condition) {
        conditions.push(condition);
      }
    });

    condition = this.getCondition("date", this.dateOperatorTarget.value, {min: this.dateRangeStartTarget.value, max: this.dateRangeEndTarget.value, value: this.dateValueTarget.value});
    if (this.dateActiveTarget.checked) {
      conditions.push(condition)
    }

    const ledgerSelectedOptions = Array.from(this.ledgerIdValueTarget.selectedOptions).map((option) => option.value);
    if (ledgerSelectedOptions.length > 0) {
      conditions.push(this.getCondition("ledger_ids", null, {value: ledgerSelectedOptions}));
    }

    const acccountSelectedOption = Array.from(this.accountTemplateIdValueTarget.selectedOptions).map((option) => option.value);
    if (acccountSelectedOption.length > 0) {
      conditions = conditions.concat(this.getCondition("account_template_ids", null, {value: acccountSelectedOption}));
    }
    return {
      rule: {
        conditions_attributes: conditions
      }
    }
  }

  getCondition(field, conditionTargetValue, valuesTargets: ValuesTargets) {
    if (this.noValuesForCondition(valuesTargets)) {
      return null;
    }

    if (!conditionTargetValue) {
      return {
        type: field,
        operator: "include",
        value: valuesTargets.value
      }
    } else if (conditionTargetValue === "between") {
      return {
        type: field,
        operator: conditionTargetValue,
        range_start: valuesTargets.min,
        range_end: valuesTargets.max
      }
    } else {
      return {
        type: field,
        operator: conditionTargetValue,
        value: valuesTargets.value,
      }
    }
  }

  noValuesForCondition(valuesTargets: ValuesTargets) {
    return (!valuesTargets.value && !valuesTargets.min && !valuesTargets.max);
  }
}
