import { Controller } from "@hotwired/stimulus";

interface newRuleConditions {
  ledgerId: string | null;
  accountTemplateId: string | null;
  description: string | null;
  amount: string | null;
}

// Connects to data-controller="connected-transaction"
export default class extends Controller {
  declare actionsDropdownTarget: HTMLElement;
  declare hasActionsDropdownTarget: boolean;
  declare categorizationActionButtonTargets: HTMLElement[];
  declare checkboxTarget: HTMLInputElement;
  declare hasCheckboxTarget: boolean;
  declare submitTarget: HTMLButtonElement;
  declare transferTarget: HTMLButtonElement;
  declare hasTransferTarget: boolean;
  declare transactionRowTarget: HTMLDivElement;
  declare accountSelectTarget: HTMLSelectElement;
  declare hasAccountSelectTarget: HTMLSelectElement;
  declare newRuleLinkTriggerTargets: HTMLInputElement[];
  declare hasNewRuleLinkTarget: boolean;
  declare newRuleLinkTarget: HTMLButtonElement;
  declare accountCodeSelectTarget: HTMLSelectElement;
  declare hasAccountCodeSelectTarget: HTMLSelectElement;
  declare tagSelectTarget: HTMLSelectElement;
  declare hasTagSelectTarget: HTMLSelectElement;
  declare hasSuggestionIconTarget: boolean;
  declare suggestionIconTarget: HTMLDivElement;
  declare splitTransactionButtonTarget: HTMLButtonElement;
  declare hasSplitTransactionButtonTarget: boolean;

  declare submitClassesToRemoveValue: string;
  declare submitClassesToAddValue: string;
  declare transferClassesToRemoveValue: string;
  declare transferClassesToAddValue: string;
  declare newRuleConditionsValue: newRuleConditions;
  declare newRuleUrlValue: string;

  declare hasPotentialTransferClasses: Array<string>;

  static targets = [
    "actionsDropdown",
    "categorizationActionButton",
    "checkbox",
    "submit",
    "transactionRow",
    "accountSelect",
    "newRuleLinkTrigger",
    "accountCodeSelect",
    "tagSelect",
    "splitTransactionButton",
    "newRuleLink",
    "suggestionIcon",
  ];

  static values = {
    newRuleConditions: {
      type: Object,
      default: {
        ledgerId: null,
        accountTemplateId: null,
        description: null,
        amount: null,
      },
    },
    newRuleUrl: String,
    submitClassesToAdd: String,
    submitClassesToRemove: String,
  };

  connect() {
    this.changeReviewedStatus();
    this.toggleNewRuleLink();
    this.updateSplitTransactionButton();
  }

  changeReviewedStatus() {
    if (this.hasAccountSelectTarget && (!this.hasCheckboxTarget || !this.checkboxTarget.checked)) {
      const accountSelected = this.accountSelectTarget.value;

      if (accountSelected === "" || accountSelected === undefined) {
        this.markUnreviewed();
      } else {
        this.markReviewed();
      }
    }
  }

  markReviewed() {
    this.submitTarget.disabled = false;
    this.submitTarget.classList.remove(...this.submitClassesToRemoveValue.split(" "));
    this.submitTarget.classList.add(...this.submitClassesToAddValue.split(" "));
  }

  markUnreviewed() {
    this.submitTarget.disabled = true;
    this.submitTarget.classList.add(...this.submitClassesToRemoveValue.split(" "));
    this.submitTarget.classList.remove(...this.submitClassesToAddValue.split(" "));
  }

  toggleActions() {
    if (this.hasCheckboxTarget && this.checkboxTarget.checked) {
      this.categorizationActionButtonTargets.forEach((button) => {
        button.setAttribute("disabled", "");
      });
      
      if (this.hasActionsDropdownTarget) {
        this.actionsDropdownTarget.removeAttribute("data-controller")
        this.actionsDropdownTarget.classList.remove("cursor-pointer", "hover:bg-zinc-200");
        this.actionsDropdownTarget.classList.add("cursor-not-allowed");
        this.actionsDropdownTarget.setAttribute("disabled", "");
      }

      this.markUnreviewed();      
    } else if (this.hasCheckboxTarget && !this.checkboxTarget.checked) {
      this.categorizationActionButtonTargets.forEach((button) => {
        button.removeAttribute("disabled");
      });

      if (this.hasActionsDropdownTarget) {
        this.actionsDropdownTarget.setAttribute("data-controller", "dropdown")
        this.actionsDropdownTarget.classList.add("cursor-pointer", "hover:bg-zinc-200");
        this.actionsDropdownTarget.classList.remove("cursor-not-allowed");
        this.actionsDropdownTarget.removeAttribute("disabled");
      }

      this.changeReviewedStatus();
    }
  }

  toggleNewRuleLink() {
    if (this.hasNewRuleLinkTarget) {
      if (this.newRuleLinkTriggerTargets.some((target) => !!target.value)) {
        this.newRuleLinkTarget.classList.remove("hidden");
      } else {
        this.newRuleLinkTarget.classList.add("hidden");
      }
    }
  }

  handleUpdates(_event) {
    this.toggleNewRuleLink();
    this.removeSuggestionIcon();
    this.updateSplitTransactionButton();
  }

  updateSplitTransactionButton() {
    const queryParams = new URLSearchParams();

    if (this.hasAccountSelectTarget && this.accountSelectTarget.value) {
      queryParams.append("destination_account_id", this.accountSelectTarget.value);
    }

    if (this.hasAccountCodeSelectTarget && this.accountCodeSelectTarget.value) {
      queryParams.append("account_code_id", this.accountCodeSelectTarget.value);
    }

    if (this.hasTagSelectTarget && this.tagSelectTarget.value) {
      Array.from(this.tagSelectTarget.selectedOptions).forEach((option) => {
        queryParams.append("tag_ids[]", option.value);
      });
    }

    if (this.hasSplitTransactionButtonTarget) {
      const card = JSON.parse(this.splitTransactionButtonTarget.dataset.card);

      const urlOrigin = card.turboSrc.split("?")[0];

      card.turboSrc = urlOrigin + "?" + queryParams.toString();

      this.splitTransactionButtonTarget.dataset.card = JSON.stringify(card);
    }
  }

  removeSuggestionIcon() {
    if (this.hasSuggestionIconTarget) {
      this.suggestionIconTarget.classList.add("hidden");
    }
  }

  visitNewRuleLink() {
    const url = new URL(this.newRuleUrlValue);
    const queryParams = new URLSearchParams({
      "ledger_ids[]": this.newRuleConditionsValue.ledgerId,
      "account_template_ids[]": this.newRuleConditionsValue.accountTemplateId,
      q: this.newRuleConditionsValue.description,
      amount: this.newRuleConditionsValue.amount,
    });

    if (this.accountSelectTarget.value) {
      queryParams.append("destination_account_id", this.accountSelectTarget.value);
    }

    if (this.accountCodeSelectTarget.value) {
      queryParams.append("account_code_id", this.accountCodeSelectTarget.value);
    }

    Array.from(this.tagSelectTarget.selectedOptions).forEach((option) => {
      queryParams.append("tag_ids[]", option.value);
    });

    url.search = queryParams.toString();

    window.open(url, "_blank").focus();
  }
}
