// This controller is used to compute and set the left/right positions 
// of all the sticky table-cells. 
// These positions are the cumulative value of the cells siblings, 
// which can change depending on the width of siblings or if the user changes the window size.
// This also hides the shadow over the non-sticky cells when there is no scroll

import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["container", "leftColumn", "rightColumn", "leftShadow", "rightShadow"];

  declare containerTarget: HTMLElement;
  declare leftColumnTargets: HTMLElement[];
  declare rightColumnTargets: HTMLElement[];
  declare leftShadowTarget: HTMLElement;
  declare rightShadowTarget: HTMLElement;

  declare columnPositions: object;
  declare shadowPositions: object;

  declare Z_INDEX_PLANE_FOR_TABLE: number;

  connect() {
    this.Z_INDEX_PLANE_FOR_TABLE = 30;
    this.columnPositions = {
      left: [],
      right: []
    };

    this.shadowPositions = {
      left: 0,
      right: 0,
    }

    for ( const direction of ["left", "right"] ) {
      this.calculateStickyColumnPositions(direction);
      this.setStickyColumnPosition(direction);
      this[`${direction}ShadowTarget`].style.zIndex = this.Z_INDEX_PLANE_FOR_TABLE + 1;
    }

    // this.maybeHideShadowDivs();
  }

  updateShadows() {
    for ( const direction of ["left", "right"] ) {
      this.calculateStickyColumnPositions(direction);
      this.setShadowPosition(direction);
    }

    // this.maybeHideShadowDivs();
  }

  maybeHideShadowDivs() {
    if ( this.isOverflowing() ) {
      this.leftShadowTarget.classList.remove('invisible');
      this.rightShadowTarget.classList.remove('invisible');
    } else {
      this.leftShadowTarget.classList.add('invisible');
      this.rightShadowTarget.classList.add('invisible');
    }
  }

  calculateStickyColumnPositions(direction) {
    const columnTargets = direction == "left" ? this.leftColumnTargets : this.rightColumnTargets.reverse();

    for ( const [index, col] of columnTargets.entries() ) {
      const isFirstColumn = index === 0
      if ( isFirstColumn ) { this.columnPositions[direction].push(0); }

      const cumulativeWidth = this.columnPositions[direction][index] + col.offsetWidth;

      const isLastColumn = index === columnTargets.length - 1

      if ( ! isLastColumn ) { 
        this.columnPositions[direction].push(cumulativeWidth);
      } else {
        this.shadowPositions[direction] = cumulativeWidth;
        continue;
      }
    }
  }

  setStickyColumnPosition(direction) {
    const rows = this.element.querySelectorAll(".table-row");

    for ( const [positionIndex, absolutePosition] of this.columnPositions[direction].entries() ) {
      rows.forEach( (row, rowIndex) => {        
        const cells = row.querySelectorAll<HTMLElement>(".table-cell");

        const positionIndexFromRight = cells.length - 1 - positionIndex;
        const cellIndex = direction == "left" ? positionIndex : positionIndexFromRight;

        const cell = cells[cellIndex];
        cell.classList.add("sticky", "bg-white");
        cell.style[direction] = `${absolutePosition}px`;
        cell.style.zIndex = `${this.Z_INDEX_PLANE_FOR_TABLE - rowIndex}`;
      });
    }

    this.setShadowPosition(direction);
  }

  setShadowPosition(direction) {
    this[`${direction}ShadowTarget`].style[direction] = `${this.shadowPositions[direction]}px`;
  }

  isOverflowing() {
    return this.containerTarget.scrollWidth > this.containerTarget.clientWidth
  }
}