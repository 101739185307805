import {
  ExcelExportParams,
  CellClassParams,
  ProcessCellForExportParams,
  ExcelRow,
  ProcessHeaderForExportParams,
  RowNode,
} from "ag-grid-enterprise";
import { ExcelStyle } from "ag-grid-community";
import { isGroupFooter, isSectionHeader, isSectionFooter, isGroupHeader } from "./node_helpers";
import { formatDateForDisplay } from "./formatters";
import { ServerProvidedColDef, ReportType, ReportOption } from "../../types";

const REPORT_LABELS: Record<ReportType, string> = {
  income_statement: 'Income Statement',
  balance_sheet: 'Balance Sheet',
  consolidated: 'Consolidated Balance Sheet',
  net_worth: 'Net Worth Statement',
  trial_balance: 'Trial Balance',
  cash_flow_statement: 'Cash Flow Statement',
  general_ledger: 'General Ledger',
  "sumit-report": 'Report'
};

export const defaultExportParams: ExcelExportParams = {
  // This callback is called on all cells before they are exported. We use it to format the values based on whether
  // the cell is a group header or footer.
  processCellCallback: (params: ProcessCellForExportParams) => {
    const node = params.node as unknown as RowNode;
    const column = params.column;
    const colDef = column.getColDef();
    const innerRenderer = colDef.cellRendererParams?.innerRenderer;

    if (innerRenderer === "accountCellRenderer" || innerRenderer === "flattenedAccountCellRenderer") {
      // For group headers and footers, AG Grid renders the full path in the value.
      // as an example for the "110000 - Current Assets" header, it renders: "Sources of Cash -> 100000 - Assets -> 110000 - Current Assets".
      // For the footer it renders something like "Total  -> Sources of Cash -> 100000 - Assets -> 110000 - Current Assets"
      // Here we get only the last value. In this example, it would be "110000 - Current Assets"
      const value = params.value ? params.value : node.key;
      const breadcrumbArray = value.split(" -> ");

      if (isGroupFooter(node)) {
        return `Total ${breadcrumbArray[breadcrumbArray.length - 1]}`;
      } else {
        // For group headers, AG Grid sometimes inserts " -> " between the group header and the value. We remove it here.
        return `${breadcrumbArray[breadcrumbArray.length - 1]}`;
      }
    }

    if (!node.expanded && node.displayed) {
      return params.value;
    }

    const number = Number(params.value);

    if (isGroupHeader(node)) {
      const a_one_space_string_so_aggrid_does_not_insert_other_header_value = " ";
      return a_one_space_string_so_aggrid_does_not_insert_other_header_value;
    } else if (isGroupFooter(node)) {
      if (isNaN(number) || params.value?.toString() == "N/A") {
        return params.value;
      } else {
        return number;
      }
    }

    if (isNaN(number) || params.value?.toString() == "N/A") {
      return params.value;
    }

    return params.value;
  },
  // This callback is called on all header cells before they are exported. We use it to format the values based on whether
  // there is consolidation metadata for the column.
  processHeaderCallback: (params: ProcessHeaderForExportParams): string => {
    const consolidationMetadata = (params.column.getColDef() as unknown as ServerProvidedColDef).metadata
      ?.consolidationMetadata;

    if (consolidationMetadata) {
      // iterate over the consolidation metadata and get each key value pair for the export to put together in one cell
      let header = `${params.column.getColDef().headerName} \n`;
      for (const consolidationMetadataEntry of Object.values(consolidationMetadata)) {
        header += `${consolidationMetadataEntry.text}: ${consolidationMetadataEntry.value} \n`;
      }
      header = header.slice(0, -1); // remove the last newline

      return header;
    } else {
      return params.column.getColDef().headerName;
    }
  },
};

export const defaultGLExportParams: ExcelExportParams = {
  // This callback is called on all cells before they are exported. We use it to format the values
  processCellCallback: (params: ProcessCellForExportParams) => {
    const node = params.node as unknown as RowNode;
    const column = params.column;
    const colDef = column.getColDef();
    const innerRenderer = colDef.cellRendererParams?.innerRenderer;
    const field = colDef.field;

    // We don't want to show any values for expanded group headers
    if (node.level === 1 && node.expanded && !isGroupFooter(node)) {
      return " ";
    }

    if (field === "accountCode") {
      if (params.value == null || params.value === undefined) {
        return " ";
      } else {
        return `${params.value.group}: ${params.value.name}`;
      }
    }

    if (field === "tags") {
      if (params.value == null || params.value === undefined) {
        return " ";
      } else {
        if (Array.isArray(params.value)) {
          return params.value.map(tag => `${tag.group}: ${tag.name}`).join("; ");
        } else {
          return params.value.name;
        }
      }
    }

    if (field === "effectiveAt" && params.value === undefined || params.value === null) {
      const value = params.value ? params.value : node.key;

      if ((isGroupFooter(node))) {
        const breadcrumbArray = value.split(" -> ");

        if (breadcrumbArray.length > 1) {
          return `Total ${breadcrumbArray[breadcrumbArray.length - 1]}`;
        }

        return `Total ${value}`;
      }
    }

    if (field === "entity" && (params.value === undefined || params.value === null)) {
      return node.parent.key;
    }

    if (field === "account" && (params.value === undefined || params.value === null)) {
      return node.key;
    }

    if (innerRenderer === "GLAccountCellRenderer") {
      const value = params.value ? params.value : node.key;

        const breadcrumbArray = value.split(" -> ");

        if (breadcrumbArray.length > 1) {
          return `Total ${breadcrumbArray[breadcrumbArray.length - 1]}`;
        }

        return `Total ${value}`;
    }

    if (!node.expanded && node.displayed) {
      return params.value;
    }

    // This number formatting is required for Roo to format Excel cells as floats
    if (colDef.cellDataType === "number") {
      const number = Number(params.value);

      return number;
    } else {
      return params.value;
    }
  },
};

function buildIndentationStyles(level: number): ExcelStyle[] {
  if (level > 0) {
    const styles: ExcelStyle[] = [];

    for (let i = 1; i <= level; i++) {
      styles.push({
        id: `indent-${i}`,
        alignment: {
          indent: i,
        },
        // note, dataType: 'String' required to ensure that numeric values aren't right-aligned
        dataType: "String",
      });
    }

    return styles;
  }

  return [];
}

export const excelStyles: ExcelStyle[] = [
  ...buildIndentationStyles(5),
  {
    id: "header",
    interior: {
      pattern: "Solid",
      color: "#015965",
    },
    font: {
      size: 14,
      color: "#ffffff",
    },
    alignment: {
      vertical: "Center",
    },
  },
  {
    id: "report-header",
    dataType: "String",
    font: {
      bold: true,
      size: 18,
    },
  },
  {
    id: "section-total",
    borders: {
      borderTop: {
        color: "black",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
    font: {
      bold: true,
      size: 13,
    },
  },
  {
    id: "section-subtotal",
    borders: {
      borderTop: {
        color: "black",
        lineStyle: "Continuous",
        weight: 1,
      },
    },
    font: {
      bold: true,
      size: 12,
    },
  },
  {
    id: "section",
    interior: {
      pattern: "Solid",
      color: "#e5e7eb",
    },
    font: {
      bold: true,
      size: 13,
    },
  },
  {
    id: "number-cell",
    dataType: "Number",
    numberFormat: {
      format: "#,##0.00",
    },
  },
  {
    id: "percentage-cell",
    numberFormat: {
      format: "###,##0.00%",
    },
  },
];

export function getClasses(params: CellClassParams): string[] | string {
  const node = params.node as unknown as RowNode;
  const classes = [`indent-${node.level}`];

  const metadata = node.data?.metadata;
  if (metadata?.generated_total_row) {
    classes.push("section-total");
  } else if (isSectionHeader(node)) {
    classes.push("section");
  } else if (isSectionFooter(node) || node.isRowPinned()) {
    classes.push("section-total");
  } else if (node.footer) {
    classes.push("section-subtotal");
  }
  return classes;
}

export function getClassesForAccounts(params: CellClassParams): string[] | string {
  return getClasses(params);
}

export function getClassesForCells(params: CellClassParams): string[] | string {
  const node = params.node as unknown as RowNode;
  const classes = [];

  // Ensure the cell is not a header and is a number or is a number cell of a collapsed row with total for children of them
  const isNumber = !isNaN(Number(params.value)) && params.colDef.cellDataType === "number";
  const isCollapsedRow = !node.expanded && node.displayed;
  if ((isNumber && !isGroupHeader(node)) || (isNumber && isCollapsedRow)) {
    classes.push("number-cell");
  }

  if (!isNaN(Number(params.value)) || params.colDef.cellDataType === "percentage") {
    classes.push("ag-right-aligned-cell");
  }

  if (params.colDef.cellDataType === "percentage") {
    classes.push("percentage-cell");
  }

  if (isGroupFooter(node) || node.isRowPinned()) {
    classes.push("number-cell-bold");
  }

  const metadata = node.data?.metadata;
  if (metadata?.generated_total_row) {
    classes.push("section-total");
  } else if (isSectionHeader(node)) {
    classes.push("section");
  } else if (isSectionFooter(node) || node.isRowPinned()) {
    classes.push("section-total");
  } else if (node.footer) {
    classes.push("section-subtotal");
  }

  return classes;
}

export function headerRows(
  reportType: ReportType,
  reportDate: Date,
  startDate: string | null,
  endDate: string | null,
  entityNames: string[],
  options: ReportOption[]
): ExcelRow[] {
  const reportLabel = REPORT_LABELS[reportType] || 'Report';
  const entityNamesLabel = entityNames.length > 0 ? entityNames.join(', ') : 'All Entities';
  const entitiesLabel = `Entities: ${entityNamesLabel}`;
  const reportDateLabel = formatDateForDisplay(reportDate);

  let periodLabel = 'All Dates';
  if (startDate && endDate) {
    const isPeriodReport = ['income_statement', 'cash_flow_statement', 'general_ledger'].includes(reportType);
    periodLabel = isPeriodReport
      ? `For the period from ${formatDateForDisplay(startDate)} to ${formatDateForDisplay(endDate)}`
      : `As of ${formatDateForDisplay(endDate)}`;
  } else if (endDate) {
    periodLabel = 'Since Inception';
  }

  const rows: ExcelRow[] = [
    { cells: [] },
    { cells: [createCell(reportLabel, 'report-header')] },
    { cells: [createCell(`Generated on ${reportDateLabel}`)] },
    { cells: [createCell(periodLabel)] },
    { cells: [createCell(entitiesLabel)] },
    ...options.map(option => ({
      cells: [createCell(`${option.label}: ${option.value ? 'Yes' : 'No'}`)]
    })),
    { cells: [] }
  ];

  return rows;
}

function createCell(value: string, styleId?: string): { data: { value: string, type: 'String' }, styleId?: string } {
  return {
    data: { value, type: 'String' },
    ...(styleId && { styleId })
  };
}
