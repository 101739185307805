import { Controller } from "@hotwired/stimulus"
import { TomInput } from "tom-select/dist/types/types";

// Connects to data-controller="transaction-transfer"
export default class extends Controller {
  declare submitButtonTarget: HTMLButtonElement;
  declare rowTargets: HTMLElement[]
  declare destinationDescriptionTargets: HTMLElement[]
  declare accountCodeSelectTargets: HTMLInputElement[]
  declare tagSelectTargets: HTMLInputElement[]
  declare intercompanyAccountSelectTargets: HTMLInputElement[]

  static targets = ['submitButton', 'row', 'destinationDescription', 'accountCodeSelect', 'tagSelect', 'intercompanyAccountSelect']

  static values = {
    originalLedgerId: String,
  };
  declare originalLedgerIdValue: string;

  clearNonSelectedRows(event: Event) {
    const target = event.target as HTMLInputElement

    this.destinationDescriptionTargets.forEach((destinationDescription: HTMLElement) => {
      const destinationDescriptionTransactionId = destinationDescription.getAttribute("data-destination-description-id-value");
      const destinationDescriptionValue = destinationDescription.getAttribute("data-destination-description-value");

      if (destinationDescriptionTransactionId !== target.value) {
        const divElement = document.createElement("div");
        divElement.textContent = destinationDescriptionValue;
        divElement.classList.add("p-2");
        destinationDescription.textContent = "";
        destinationDescription.appendChild(divElement);
      } else {
        const textareaElement = document.createElement("textarea");
        textareaElement.setAttribute("name", "destination_transaction_description");
        textareaElement.textContent = destinationDescriptionValue;
        textareaElement.classList.add("flex", "px-2", "border-none", "shadow-none", "rounded-none", "focus:ring-inset", "h-full", "w-full", "text-sm", "font-medium", "text-sumit-primary-gray-dark", "min-h-8");

        destinationDescription.textContent = "";
        destinationDescription.appendChild(textareaElement);
      }
    });

    this.accountCodeSelectTargets.forEach((accountCodeSelect: HTMLElement) => {
      const accountCodeSelectTransactionId = accountCodeSelect.getAttribute("data-account-code-select-id-value");

      const accountCodeSelectElement = accountCodeSelect as TomInput;
      const accountCodeTomSelect = accountCodeSelectElement.tomselect;
      if (accountCodeSelectTransactionId === target.value) {
        accountCodeTomSelect.enable();
        this.toggleAccountCodeSelect(accountCodeSelectTransactionId, true);
      } else {
        accountCodeTomSelect.disable();
        accountCodeTomSelect.clear();
        this.toggleAccountCodeSelect(accountCodeSelectTransactionId, false);
      }
    });

    this.tagSelectTargets.forEach((tagSelect: HTMLElement) => {
      const tagSelectTransactionId = tagSelect.getAttribute("data-tag-select-id-value");

      const tagSelectElement = tagSelect as TomInput;
      const tagTomSelect = tagSelectElement.tomselect;
      if (tagSelectTransactionId === target.value) {
        tagTomSelect.enable();
        this.toggleTagSelect(tagSelectTransactionId, true);
      } else {
        tagTomSelect.disable();
        tagTomSelect.clear();
        this.toggleTagSelect(tagSelectTransactionId, false);
      }
    });

    this.intercompanyAccountSelectTargets.forEach((intercompanyAccountSelect: HTMLInputElement) => {
      const intercompanyAccountSelectTransactionId = intercompanyAccountSelect.getAttribute("data-intercompany-account-select-transaction-id-value");
      const intercompanyAccountSelectLedgerId = intercompanyAccountSelect.getAttribute("data-intercompany-account-select-ledger-id-value");

      if (intercompanyAccountSelectTransactionId === target.value && intercompanyAccountSelectLedgerId !== this.originalLedgerIdValue) {
        intercompanyAccountSelect.disabled = false;
      } else {
        intercompanyAccountSelect.disabled = true;
        intercompanyAccountSelect.value = "";
        intercompanyAccountSelect.dispatchEvent(new Event('change'));
      }
    });

    const targetLedgerId = target.getAttribute("data-transaction-transfer-ledger-id-value");

    if (targetLedgerId !== this.originalLedgerIdValue) {
      this.disableSubmit();
    } else {
      this.enableSubmit();
    }
  }

  toggleAccountCodeSelect(id: string, enabled: boolean) {
    this.dispatch("toggleAccountCodeSelect", { detail: { enabled: enabled, id: id } })
  }

  toggleTagSelect(id: string, enabled: boolean) {
    this.dispatch("toggleTagSelect", { detail: { enabled: enabled, id: id } })
  }

  handleToggleSubmitEvent(event) {
    const isEnabled = event.detail.enabled;
    const submitId = event.detail.id;

    if (this.submitButtonTarget.id === submitId) {
      if (isEnabled) {
        this.enableSubmit();
      } else {
        this.disableSubmit();
      }
    }
  }

  enableSubmit() {
    this.submitButtonTarget.disabled = false;
  }

  disableSubmit() {
    this.submitButtonTarget.disabled = true;
  }
}
