import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="select-toggle"
export default class extends Controller {
  static targets = ["section", "source"];

  declare sourceTarget: HTMLSelectElement;
  declare sectionTargets: HTMLDivElement[];

  connect() {
    const startingValue = this.sourceTarget.value;

    this.toggleVisibility(startingValue);
  }

  handleChange(event) {
    const selectedValue = event.target.value;

    this.toggleVisibility(selectedValue);
  }

  toggleVisibility(value: string) {
    if (value === "") {
      return;
    }

    this.sectionTargets.forEach((section) => {
      const showWhens = section.dataset.showWhen.split(",").map((showWhen) => showWhen.trim());

      showWhens.forEach(() => {
        if (this.willDisplaySection(value, showWhens)) {
          section.classList.remove("hidden");
        } else {
          section.classList.add("hidden");
        }
      });
    });
  }

  willDisplaySection(value: string, showWhens: string[]) {
    return value !== "" && showWhens.includes(value);
  }
}
