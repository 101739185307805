import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="optional-object"
export default class extends Controller<HTMLDivElement> {
  static targets = ["form", "addButton", "template"];

  declare formTarget: HTMLDivElement;
  declare hasFormTarget: boolean;

  declare addButtonTarget: HTMLDivElement;
  declare hasAddButtonTarget: boolean;

  declare templateTarget: HTMLTemplateElement;
  declare hasTemplateTarget: boolean;

  connect() {}

  add(_e: Event): void {
    if (this.hasFormTarget) {
      const divFromTemplate = this.templateTarget.content.cloneNode(true);

      this.formTarget.appendChild(divFromTemplate as Node);
    }

    if (this.hasAddButtonTarget) {
      this.addButtonTarget.classList.add("hidden");
    }
  }

  remove(_e: Event): void {
    if (this.hasFormTarget) {
      this.formTarget.replaceChildren();
    }

    if (this.hasAddButtonTarget) {
      this.addButtonTarget.classList.remove("hidden");
    }
  }
}
