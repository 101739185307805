import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="subtype-dropdown"
export default class extends Controller {
  static targets = ["subtypeSelect", "typeSelect"];

  static values = {
    url: String,
  };

  declare subtypeSelectTargets: HTMLSelectElement[];
  declare typeSelectTarget: HTMLSelectElement;
  declare urlValue: string;

  typeChanged(_event: Event) {
    this.subtypeSelectTargets.forEach((selectTarget: HTMLSelectElement) => {
      this.getSelectTargetTurboFrame(selectTarget);
    });
  }

  // Use turbo streams to update the subtype dropdown. This action disables the drop down to start. The
  // turbo stream response returns a drop down that is enabled and has the correct options (which is why we
  // don't disable it in this function)
  getSelectTargetTurboFrame(element: HTMLSelectElement) {
    element.disabled = true;

    const selectedType = this.typeSelectTarget.selectedOptions[0].value;
    const selectedSubtype = element.hasChildNodes() ? element.selectedOptions[0].value : "";

    if (selectedType === "") {
      element.innerHTML = "";

      return;
    }

    get(this.urlValue, {
      query: {
        type: selectedType,
        target: element.id,
        name: element.name,
        selected_subtype_id: selectedSubtype,
      },
      responseKind: "turbo-stream",
    });
  }
}
