import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["checkboxGroup", "checkbox"];
  static values = { selectedGroup: Number }

  declare checkboxTargets: HTMLInputElement[];
  declare checkboxGroupTargets: HTMLInputElement[];
  declare selectedGroupValue: number;

  toggle(e: Event): void {
    const currentTarget = e.currentTarget as HTMLInputElement;
    let groupId
    this.checkboxGroupTargets.forEach((checkboxGroup) => {
      if (checkboxGroup.contains(currentTarget)) {
        groupId = checkboxGroup.dataset.groupId;
      }
    });
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.dataset.groupId !== groupId) {
        checkbox.checked = false;
        checkbox.indeterminate = false;
      }
    });
  }
}
