import { GridApi, IRowNode } from "ag-grid-enterprise";

// Determine selected rows without using ag grid's getSelectedRows() method
export const getSelectedRows = (gridApi: GridApi) => {
  const selectedRows = [];

  gridApi.forEachNode((node: IRowNode) => {
    if (node.data && node.isSelected()) {
      selectedRows.push(node.data);
    }
  });

  return selectedRows;
};
