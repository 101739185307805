export const toggleButton = (buttonTargets: HTMLButtonElement[], event: object) => {
  let count = 0;
  let removeMiddleBorder = false;
  const totalButtons = buttonTargets.length;

  buttonTargets.forEach(target => {
    // @ts-ignore
    if (target === event.target) {
      target.classList.remove("btn-white");
      target.classList.add("bg-cyan-600", "focus:ring-cyan-500", "text-white", "focus:text-white", "hover:text-white", "hover:bg-sumit-primary-cyan");

      if (count !== 0 && count !== totalButtons - 1) {
        removeMiddleBorder = true;
      }
    } else {
      target.classList.remove("bg-cyan-600", "focus:ring-cyan-500", "text-white", "focus:text-white", "hover:text-white", "hover:bg-sumit-primary-cyan" );
      target.classList.add("btn-white");
    }

    count++;
  });

  if (removeMiddleBorder && totalButtons > 2) {
    for (let i = 1; i < totalButtons - 1; i++) {
      buttonTargets[i].classList.add("border-l-0");
    }

    buttonTargets[totalButtons - 1].classList.remove("border-l-0");
  }
};
