import { Toggle } from "tailwindcss-stimulus-components";

// Connects to data-controller="net-cash-flow-toggle"
export default class extends Toggle {
  static targets = ["postable", "cashAccount"];

  declare postableTarget: HTMLInputElement;
  declare cashAccountTarget: HTMLInputElement;

  toggle() {
    if (this.postableTarget.checked && !this.cashAccountTarget.checked) {
      super.show();
    } else {
      super.hide();
    }
  }
}
