import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ledger-transaction"
export default class extends Controller {
  static targets = ["transactionContainer"];

  declare transactionContainerTarget: HTMLElement;

  handleToggle(_event) {
    if (this.transactionContainerTarget.dataset.toggleOpenValue === "true") {
      this.handleShow(_event);
    } else {
      this.handleHide(_event);
    }
  }

  handleShow(_event) {
    const toRemove = ["border-l", "border-r"];
    const toAdd = ["bg-[#F1FBFC]", "border", "border-cyan-600"];

    this.removeClasses(toRemove);
    this.addClasses(toAdd);
  }

  handleHide(_event) {
    const toRemove = ["bg-[#F1FBFC]", "border", "border-cyan-600"];
    const toAdd = ["border-l", "border-r"];

    this.removeClasses(toRemove);
    this.addClasses(toAdd);
  }

  addClasses(classes) {
    for (const className of classes) {
      this.transactionContainerTarget.classList.add(className);
    }
  }

  removeClasses(classes) {
    for (const className of classes) {
      this.transactionContainerTarget.classList.remove(className);
    }
  }
}
