// This controller is used to manage the content of multiple slideovers
// at the same time. On show, it will show the content with the id
// of the correct contents in a slideover and dispatch a show event.
// The slideover controller listens for this event and will open the
// slideover.

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "overlay"];

  declare contentTargets: HTMLElement[];
  declare overlayTarget: HTMLElement;

  handleShow(event) {
    const contentId = event.params.id || event.detail.id;
    this.contentTargets.forEach((target) => {
      if (target.id == contentId) {
        history.replaceState(null, "", "#");
        target.classList.remove("hidden");
      } else {
        target.classList.add("hidden");
      }
    });
    if (event.params.unhideableOverlay) {
      this.overlayTarget.dataset.action = "";
    } else {
      this.overlayTarget.dataset.action = "click->slideover#toggle";
    }

    this.dispatch("show");
  }

  handleHide() {
    history.replaceState(null, "", "#");
  }

}
