import { Controller } from "@hotwired/stimulus";
import * as math from "mathjs";

// Connects to data-controller="input-currency-formatter"
export default class extends Controller {
  connect() {
    this.formatInput();
  }

  formatInput() {
    const inputValue = (this.element as HTMLInputElement).value;
    // Remove all non-numerical characters except for math operators (+, -, *, /) and decimal point
    const numericValue = inputValue.replace(/,|^0+|[^0-9.+-/*]/g, "");

    let result = "";
    try {
      result = math.evaluate(numericValue);
    } catch {
      // The expressions is invalid, so will be set to 0
    }

    let numberValue = parseFloat(result);
    if (isNaN(numberValue) || numberValue === Infinity) {
      numberValue = 0;
    }

    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(numberValue);

    if (formattedValue !== inputValue) {
      (this.element as HTMLInputElement).value = formattedValue;
    }
  }

  highlight() {
    (this.element as HTMLInputElement).select();
  }

  handleEnterKeyAsTab(event) {
    event.preventDefault();
    this.focusOnNextInput(event.currentTarget);
  }

  focusOnNextInput(currentTarget) {
    const inputs = document.querySelectorAll("input, textarea");
    const currentIndex = Array.from(inputs).indexOf(currentTarget);
    if (inputs[currentIndex]) {
      (inputs[currentIndex + 1] as HTMLElement).focus();
    }
  }
}
