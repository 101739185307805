export function parseDateRangeOption(value): { startDate: Date | null, endDate: Date | null, comparable: string } {
  const today = new Date();
  let startDate: Date, endDate: Date;
  let comparable = 'false';

  switch (value) {
    case "All":
      startDate = null;
      endDate = null;
      break;
    case "since_inception":
      startDate = null;
      endDate = today;
      break;
    case "this_month":
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      comparable = 'month';
      break;
    case "this_month_to_date":
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = today;
      break;
    case "this_quarter":
      startDate = new Date(today.getFullYear(), getQuarterStartMonth(today), 1);
      endDate = new Date(today.getFullYear(), getQuarterStartMonth(today) + 3, 0);
      comparable = 'quarter';
      break;
    case "this_quarter_to_date":
      startDate = new Date(today.getFullYear(), getQuarterStartMonth(today), 1);
      endDate = today;
      break;
    case "this_year":
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = new Date(today.getFullYear(), 11, 31);
      comparable = 'year';
      break;
    case "this_year_to_date":
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = today;
      break;
    case "last_month":
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
      comparable = 'month';
      break;
    case "last_quarter":
      startDate = new Date(today.getFullYear(), getQuarterStartMonth(today) - 3, 1);
      endDate = new Date(today.getFullYear(), getQuarterStartMonth(today), 0);
      comparable = 'quarter';
      break;
    case "last_year":
      startDate = new Date(today.getFullYear() - 1, 0, 1);
      endDate = new Date(today.getFullYear() - 1, 11, 31);
      comparable = 'year';
      break;
    default:
      startDate = new Date(value);
      endDate = today;
  }

  return { startDate, endDate, comparable };
}

export function getQuarterStartMonth(today: Date): number {
  return Math.floor(today.getMonth() / 3) * 3;
}
