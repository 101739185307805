import { Controller } from "@hotwired/stimulus";
import {
  GridOptions,
  createGrid,
  ColumnPinnedType,
  CellClassParams,
  GetRowIdParams,
  ToolPanelDef,
  GetContextMenuItemsParams,
  MenuItemDef,
  GridApi,
} from "ag-grid-enterprise";
import {
  GLAccountCellRenderer,
  GLCellRenderer,
  AccountCodeCellRenderer,
  TagCellRenderer,
} from "../helpers/reports/renderers";
import { processSavedReport, saveReportTemplate, updateSavedReportTemplate } from "../helpers/reports/saved_reports";
import {
  defaultGLExportParams,
  getClassesForAccounts,
  excelStyles,
  headerRows,
  getClassesForCells,
} from "../helpers/reports/excel_options";
import { dataTypeDefinitions, formatDateForDisplay } from "../helpers/reports/formatters";
import { LoadingOverlay, NoRowsOverlay } from "../helpers/reports/overlays";
import { IGridOptionsValue, ReportType, ReportOption } from "../types";
import {
  validateDateSelected,
  setDateAndAlertDisplay,
  requestReportData,
  validateEntitySet,
  getOptionState,
  numberValueGetter,
  processReportDataResponse,
  addAutoLoadParamToUrl,
  updateBreadcrumb,
} from "../helpers/reports/utils";
import { getCheckboxValues, setOptionsCheckboxValue, setSelectValues, getSelectValues } from "../helpers/reports/index";
import { loadDateInputs, prepareDateRangeOptions } from "../helpers/relative_dates";

// Connects to data-controller="general-ledger"
export default class extends Controller {
  totalCols = new Set() as Set<string>;
  consolidatedCols = new Set() as Set<string>;
  options = {};

  static targets = [
    "form",
    "grid",
    "dateRange",
    "startDate",
    "endDate",
    "ledgers",
    "option",
    "account",
    "ledgers",
    "ledgerErrors",
    "dateErrors",
    "exportButton",
    "updateReportButton",
    "saveReportButton",
    "dateDisplay",
    "alertDisplay",
    "saveReportName",
    "saveReportType",
  ];

  static values = {
    gridOptions: Object,
    reportType: String,
    enableGroupSubTotals: {
      type: Boolean,
      default: true,
    },
    reportTemplateId: String,
  };

  // Targets
  declare gridTarget: HTMLDivElement;
  declare formTarget: HTMLFormElement;
  declare ledgersTarget: HTMLSelectElement;
  declare ledgerErrorsTarget: HTMLSpanElement;
  declare dateErrorsTarget: HTMLSpanElement;
  declare optionTargets: HTMLInputElement[];

  declare dateRangeTarget: HTMLSelectElement;
  declare hasDateRangeTarget: boolean;
  declare startDateTarget: HTMLInputElement;
  declare hasStartDateTarget: boolean;
  declare endDateTarget: HTMLInputElement;
  declare hasEndDateTarget: boolean;

  declare accountTargets: HTMLInputElement[];

  declare dateDisplayTarget: HTMLDivElement;
  declare alertDisplayTarget: HTMLSpanElement;

  declare exportButtonTarget: HTMLButtonElement;
  declare hasExportButtonTarget: boolean;

  declare updateReportButtonTarget: HTMLButtonElement;
  declare saveReportButtonTarget: HTMLButtonElement;
  declare hasUpdateReportButtonTarget: boolean;
  declare hasSaveReportButtonTarget: boolean;

  declare saveReportNameTarget: HTMLInputElement;
  declare saveReportTypeTarget: HTMLInputElement;

  // Values
  declare gridOptionsValue: IGridOptionsValue;
  declare reportTypeValue: string;
  declare hasReportTypeValue: boolean;
  declare enableGroupSubTotalsValue: boolean;
  declare reportTemplateIdValue: string;

  // Local variables
  declare gridApi: GridApi;
  declare gridOptions: GridOptions;

  declare asyncInputsLoaded: boolean;

  connect() {
    this.asyncInputsLoaded = false;
    this.gridTarget.classList.remove("loaded");

    // Ensure the export button is disabled until the grid is loaded
    if (this.hasExportButtonTarget) {
      this.exportButtonTarget.disabled = true;
    }
    if (this.hasSaveReportButtonTarget) {
      this.saveReportButtonTarget.disabled = true;
    }

    this.gridOptions = {
      columnDefs: this.columnDefinitions(),
      headerHeight: 40,
      aggFuncs: { runningBalanceAggFunc: this.runningBalanceAggFunc },
      defaultColDef: {
        // allow every column to be aggregated
        enableValue: true,
        // allow every column to be grouped
        enableRowGroup: true,
        filter: false,
        sortable: false,
      },
      getContextMenuItems: (_params: GetContextMenuItemsParams) => {
        // Removing the export option since we have the api call to export
        const result: (string | MenuItemDef)[] = [
          "autoSizeAll",
          "separator",
          "expandAll",
          "contractAll",
          "separator",
          "copy",
          "copyWithHeaders",
          "copyWithGroupHeaders",
        ];

        return result;
      },
      excelStyles: excelStyles,
      suppressAggFuncInHeader: true,
      autoGroupColumnDef: {
        field: "entity_and_account",
        headerName: "Entity & Account",
        minWidth: 300,
        flex: 1,
        pinned: "left" as ColumnPinnedType,
        cellClass: getClassesForAccounts,
        cellRendererParams: {
          suppressCount: true,
          innerRenderer: "GLAccountCellRenderer",
        },
      },
      suppressMoveWhenRowDragging: true,
      rowData: null,
      groupDefaultExpanded: -1, // expand all groups by default
      groupTotalRow: (params) => {
        const node = params.node;
        if (node && node.level === 1) return "bottom";
        return undefined;
      },
      getRowId: (params: GetRowIdParams) => {
        return params.data.id.toString();
      },
      enableRangeSelection: true,
      rowSelection: "multiple" as const,
      statusBar: {
        statusPanels: [
          { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
          { statusPanel: "agTotalRowCountComponent", align: "center" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
            },
          } as ToolPanelDef,
        ],
      },
      allowContextMenuWithControlKey: true,
      dataTypeDefinitions: {
        ...dataTypeDefinitions,
      },
      getRowStyle: (params) => {
        const rowStyles = {};

        if (params.node.footer) {
          const isRootLevel = params.node.level === -1;
          const isAccountLevel = params.node.level === 1;

          if (isRootLevel) {
            return Object.assign(rowStyles, { "background-color": "#CCE7EA", "border-top": "1px solid black" });
          } else if (isAccountLevel) {
            return Object.assign(rowStyles, {
              "background-color": "#e5e7eb",
              "border-top": "1px solid black",
              "border-bottom": "1px solid black",
            });
          } else {
            return Object.assign(rowStyles, { "border-top": "1px solid black", "border-bottom": "1px solid black" });
          }
        }

        return rowStyles;
      },
      noRowsOverlayComponent: NoRowsOverlay,
      loadingOverlayComponent: LoadingOverlay,
      components: {
        GLAccountCellRenderer: GLAccountCellRenderer,
      },
      onRowDataUpdated: () => {
        this.gridTarget.classList.add("loaded");
      },
    };

    // update additional custom options from gridOptions
    for (const option in this.gridOptionsValue) {
      // eslint-disable-next-line no-prototype-builtins
      if (!this.gridOptions.hasOwnProperty(option)) {
        continue;
      }

      this.gridOptions[option] = this.gridOptionsValue[option];
    }

    if (this.reportTemplateIdValue) {
      processSavedReport(this, this.reportTemplateIdValue);
    } else if (this.gridOptionsValue.autoLoadReport) {
      this.createGrid();
      this.loadGridData(new Event("autoload"));
    } else {
      this.createGrid();
    }
  }

  columnDefinitions() {
    const defaultNumberColDef = {
      minWidth: 120,
      cellDataType: "number",
      filter: false,
      sortable: false,
      cellClass: (params: CellClassParams) => this.combineClasses(params, ["ag-right-aligned-cell", "font-mono"]),
      valueGetter: numberValueGetter,
      cellRenderer: GLCellRenderer,
    };

    const columnDefs = [
      {
        field: "entity",
        headerName: "Entity",
        minWidth: 200,
        rowGroup: true,
        hide: true,
        cellClass: getClassesForCells,
      },
      {
        field: "account",
        headerName: "Account",
        minWidth: 150,
        rowGroup: true,
        hide: true,
        cellClass: getClassesForCells,
      },
      {
        field: "effectiveAt",
        headerName: "Effective At",
        minWidth: 140,
        cellClass: (params: CellClassParams) => this.combineClasses(params, ["font-mono"]),
        cellDataType: "dateString",
        cellRenderer: GLCellRenderer,
      },
      {
        field: "description",
        headerName: "Description",
        minWidth: 200,
        cellClass: getClassesForCells,
        cellRenderer: GLCellRenderer,
      },
      {
        field: "amount",
        headerName: "Amount",
        minWidth: 150,
        width: 200,
        aggFunc: "sum",
        ...defaultNumberColDef,
      },
      {
        field: "accountBalance",
        headerName: "Account Balance",
        minWidth: 150,
        width: 200,
        valueGetter: numberValueGetter,
        aggFunc: this.runningBalanceAggFunc,
        ...defaultNumberColDef,
      },
      {
        field: "accountCode",
        headerName: "Account Code",
        minWidth: 160,
        hide: !getOptionState(this, "show_account_codes"),
        cellClass: getClassesForCells,
        cellRenderer: AccountCodeCellRenderer,
        cellDataType: "text",
      },
      {
        field: "tags",
        headerName: "Tags",
        flex: 0,
        cellEditorPopup: true,
        minWidth: 400,
        width: 500,
        hide: !getOptionState(this, "show_tags"),
        cellClass: getClassesForCells,
        cellRenderer: TagCellRenderer,
        cellDataType: "text",
      },
    ];

    return columnDefs;
  }

  combineClasses(params: CellClassParams, customClasses: string[] = []): string[] {
    const baseClasses = getClassesForCells(params);
    const baseClassesArray = Array.isArray(baseClasses) ? baseClasses : [baseClasses];
    return [...new Set([...baseClassesArray, ...customClasses])];
  }

  createGrid() {
    this.gridApi = createGrid(this.gridTarget, this.gridOptions);
    this.gridApi.showNoRowsOverlay();
  }

  // We need to set the grid state before the grid is created in order for it to work.
  // Currently there is no way to update the state after the grid had been initialized.
  // State is set using initialState: https://github.com/ag-grid/ag-grid/issues/7445
  loadAgGridState(agGridState) {
    if (agGridState) {
      this.gridOptions.initialState = agGridState;

      // Rely on the state to expand groups by default
      this.gridOptions.groupDefaultExpanded = 0;
    }
  }

  disconnect() {
    if (this.gridApi) {
      this.gridApi.destroy();
    }
  }

  restoreInitialGridState() {
    this.gridOptions.initialState = null;
    this.gridOptions.groupDefaultExpanded = -1; // reset to start with all rows expanded
  }

  async loadGridData(event: Event) {
    this.gridApi.setGridOption("loading", true);
    this.gridTarget.classList.remove("loaded");

    addAutoLoadParamToUrl();

    if (!this.optionTargets.includes(event.currentTarget as HTMLInputElement)) {
      event.preventDefault();
    }

    this.totalCols.clear();

    const sessionReportOptions = JSON.parse(sessionStorage.getItem(this.sessionStorageKey("report_options")));
    if (event.type == "autoload" && sessionReportOptions) {
      this.loadValuesToInputs(sessionReportOptions);
    }

    if (!validateDateSelected(this)) {
      return;
    }

    if (!validateEntitySet(this)) {
      return;
    }

    // we do this for accounts here because they are loaded asynchronously
    // and might not be available at the time of the run. Then we use the turbo:frame-render event on _account_options_slideover partial to call loadAsyncInputs()
    let accountTemplateIds = [];
    if (this.asyncInputsLoaded) {
      accountTemplateIds = getCheckboxValues(this.accountTargets);
    } else {
      accountTemplateIds = sessionReportOptions?.account_code_ids;
    }

    const body = {
      start_date: this.hasStartDateTarget ? this.startDateTarget.value : null,
      end_date: this.hasEndDateTarget ? this.endDateTarget.value : null,
      date_range: this.hasDateRangeTarget ? this.dateRangeTarget.value : null,
      ledger_ids: getSelectValues(this.ledgersTarget),
      account_template_ids: accountTemplateIds,
      ...this.getOptionsObject(),
    };

    if (event.type == "click") {
      sessionStorage.setItem(this.sessionStorageKey("report_options"), JSON.stringify(body));
    }

    const response = await requestReportData(body);
    processReportDataResponse(this, response, (data) => {
      this.renderGrid(data);
    });
  }

  updateBreadcrumb(event: Event) {
    const element = event.srcElement as HTMLElement;

    updateBreadcrumb(this.reportTemplateIdValue, element);
  }

  renderGrid(data) {
    this.setColumnDefinitionsAndRowData(data);

    this.gridApi.setGridOption("loading", false);
  }

  loadValuesToInputs(values) {
    loadDateInputs(values, this);

    const dateValue = values.date_range;
    if (dateValue === "since_inception") {
      this.startDateTarget.disabled = true;
    } else {
      this.startDateTarget.disabled = false;
    }

    setSelectValues(this.ledgersTarget, values.ledger_ids.map(Number));

    setOptionsCheckboxValue(this.optionTargets, "show_no_activity_accounts", values.show_no_activity_accounts);
    setOptionsCheckboxValue(this.optionTargets, "show_tags", values.show_tags);
    setOptionsCheckboxValue(this.optionTargets, "show_account_codes", values.show_account_codes);
  }

  loadAsyncInputs() {
    const sessionReportOptions = JSON.parse(sessionStorage.getItem(this.sessionStorageKey("report_options")));

    if (sessionReportOptions && sessionReportOptions.account_template_ids) {
      this.clearCheckboxValues(this.accountTargets);
      this.setCheckboxValues(this.accountTargets, sessionReportOptions.account_template_ids.map(Number));
    }

    this.asyncInputsLoaded = true;
    this.dispatch("report-inputs-loaded");
  }

  clearCheckboxValues(checkboxes: HTMLInputElement[]) {
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
      checkbox.indeterminate = false;
      checkbox.dispatchEvent(new Event("change"));
      const customEvent = new CustomEvent("checkbox-updated", { bubbles: true, detail: { checkbox } });
      checkbox.dispatchEvent(customEvent);
    });
  }

  // Custom setCheckboxValues function to handle the custom event
  setCheckboxValues(checkboxes: HTMLInputElement[], selectedCheckboxes: number[]) {
    checkboxes.forEach((checkbox) => {
      if (selectedCheckboxes.includes(parseInt(checkbox.value))) {
        checkbox.checked = true;
        checkbox.dispatchEvent(new Event("change"));
        const customEvent = new CustomEvent("checkbox-updated", { bubbles: true, detail: { checkbox } });
        checkbox.dispatchEvent(customEvent);
      }
    });
  }

  exportReport(event: Event) {
    event.preventDefault();

    const reportType: ReportType = this.hasReportTypeValue ? (this.reportTypeValue as ReportType) : "sumit-report";
    const reportDate = new Date();

    const fileName = `${reportType}-${reportDate.toISOString().replace(/:/g, "-")}.xlsx`;

    const { showNoActivityAccountsValue } = this.getTogglesValues();
    const regularOptions: ReportOption[] = [
      { label: "Showing accounts w/ no activity", value: showNoActivityAccountsValue },
    ];

    // Used to remove the first line between the headers and first set of entries
    let hasSkippedFirstLevel1Row = false;
    let hasSeenFirstLevel1Row = false;

    this.gridApi.exportDataAsExcel({
      prependContent: headerRows(
        reportType,
        reportDate,
        this.startDateTarget.value,
        this.endDateTarget.value,
        this.getSelectedLedgerNames(),
        regularOptions,
      ),
      fileName,
      author: "SumIt Software, Inc.",
      sheetName: reportType,
      rowGroupExpandState: "match",
      headerRowHeight: 30,
      shouldRowBeSkipped: function (params) {
        // If row is skipped it doesn't get processed by the processCellCallback()
        if (params.node.level === 1) {
          if (params.node.expanded && !hasSkippedFirstLevel1Row && !hasSeenFirstLevel1Row) {
            hasSeenFirstLevel1Row = true;
            hasSkippedFirstLevel1Row = true;
            return true;
          } else {
            hasSeenFirstLevel1Row = false;
            return false;
          }
        } else {
          return !params.node.displayed || params.node.level === 0;
        }
      },
      columnKeys: this.getColumnKeysForExport(this.gridApi),
      ...defaultGLExportParams,
    });
  }

  getColumnKeysForExport(gridApi: GridApi): string[] {
    const columnKeys = ["entity", "account"];

    gridApi.getAllGridColumns().forEach((column) => {
      const colDef = column.getColDef();
      const field = colDef.field;

      if (column.isVisible() && field !== "entity_and_account") {
        if (!columnKeys.includes(field)) {
          columnKeys.push(field);
        }
      }
    });

    return columnKeys;
  }

  getReportOptions() {
    const options = JSON.parse(sessionStorage.getItem(this.sessionStorageKey("report_options")));
    options["ag_grid_state"] = this.gridApi.getState();

    return options;
  }

  async saveReportTemplate(e: Event) {
    e.preventDefault();

    const reportTemplateData = {
      report_type: this.saveReportTypeTarget.value,
      report_options: prepareDateRangeOptions(this.getReportOptions()),
    };

    await saveReportTemplate(reportTemplateData, this.saveReportTypeTarget.value, this.saveReportNameTarget.value);
  }

  updateSavedReportTemplate() {
    const reportTemplateData = {
      report_type: this.saveReportTypeTarget.value,
      report_options: prepareDateRangeOptions(this.getReportOptions()),
    };

    updateSavedReportTemplate(reportTemplateData, this.reportTemplateIdValue);
  }

  getOptionsObject() {
    const options = {};
    this.optionTargets.forEach((option) => {
      options[option.name] = option.checked;
    });
    return options;
  }

  setColumnDefinitionsAndRowData(data) {
    setDateAndAlertDisplay(this, data);

    this.gridApi.updateGridOptions({
      columnDefs: this.columnDefinitions(),
    });
    this.gridApi.setGridOption("rowData", data.row_data);

    if (!this.gridOptions.initialState) {
      this.autoSizeColumns();
    }

    if (this.hasExportButtonTarget) {
      this.exportButtonTarget.disabled = false;
    }
    if (this.hasSaveReportButtonTarget) {
      this.saveReportButtonTarget.disabled = false;
    }
    if (this.hasUpdateReportButtonTarget && this.reportTemplateIdValue) {
      this.updateReportButtonTarget.classList.remove("hidden");
    } else {
      this.updateReportButtonTarget.classList.add("hidden");
    }
  }

  reportDateHeader() {
    return `For the period ${formatDateForDisplay(this.startDateTarget.value)} - ${formatDateForDisplay(
      this.endDateTarget.value,
    )}`;
  }

  toggleTagsColumn() {
    const showTags = getOptionState(this, "show_tags");
    this.gridApi.setColumnsVisible(["tags"], showTags);
    setDateAndAlertDisplay(this, {});
  }

  toggleAccountCodesColumn() {
    const showAccountCodes = getOptionState(this, "show_account_codes");
    this.gridApi.setColumnsVisible(["accountCode"], showAccountCodes);
    setDateAndAlertDisplay(this, {});
  }

  runningBalanceAggFunc(values) {
    // Return the last value in the group
    return values.values.length > 0 ? values.values[values.values.length - 1] : 0;
  }

  autoSizeColumns() {
    this.gridApi.sizeColumnsToFit();
  }

  getTogglesValues() {
    const showNoActivityAccountsCheckbox = document.querySelector("#show_no_activity_accounts") as HTMLInputElement;
    const showNoActivityAccountsValue = showNoActivityAccountsCheckbox?.checked;

    return { showNoActivityAccountsValue };
  }

  getSelectedLedgerNames() {
    return getSelectValues(this.ledgersTarget).map(
      (id) => (this.ledgersTarget.querySelector(`option[value="${id}"]`) as HTMLOptionElement).innerText,
    );
  }

  sessionStorageKey(key: string) {
    return `general_ledger_${key}`;
  }
}
