// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application";
import { registerControllers } from "stimulus-vite-helpers";

const controllers = import.meta.glob("./**/*_controller.*", { eager: true });
registerControllers(application, controllers);

import { Dropdown, Tabs, Slideover } from "tailwindcss-stimulus-components";
application.register("dropdown", Dropdown);
application.register("tabs", Tabs);
application.register("slideover", Slideover);

import * as Flowbite from "flowbite/dist/flowbite.turbo.js";

application.register("flowbite", Flowbite);
