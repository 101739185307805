import { Controller } from "@hotwired/stimulus";
import oneschemaImporter, { OneSchemaImporterClass } from "@oneschema/importer";
import { post, get } from "@rails/request.js";
import { Turbo, FrameElement } from "@hotwired/turbo-rails";
import { IOneSchemaOverridesResponseData, IOneSchemaPicklistOption } from "../types";
import { processSchemaOverridesResponse } from "../helpers/one_schema";

// Connects to data-controller="journal-entry-importer"
export default class extends Controller {
  declare importer: OneSchemaImporterClass | undefined;

  static targets = ["importButton"];

  static values = {
    jwtToken: String,
    overridesUrl: String,
    importUrl: String,
    clientId: String,
    sessionToken: {
      type: String,
      default: "",
    },
    devMode: {
      type: Boolean,
      default: false,
    },
  };

  declare hasJwtTokenValue: boolean;
  declare jwtTokenValue: string;

  declare sessionTokenValue: string;

  declare clientIdValue: string;
  declare overridesUrlValue: string;
  declare importUrlValue: string;
  declare devModeValue: boolean;

  declare importButtonTarget: HTMLButtonElement;

  declare accountOverrides: IOneSchemaPicklistOption[];
  declare entityOverrides: IOneSchemaPicklistOption[];
  declare tagOverrides: IOneSchemaPicklistOption[];
  declare accountCodeOverrides: IOneSchemaPicklistOption[];

  connect(): void {
    this.updateButtonStyling();
  }

  async launchImporter(_event: Event): Promise<void> {
    this.showLoader();

    const schemaOverrides = await get(this.overridesUrlValue, { responseKind: "json" });

    if (!this.importer) {
      this.initializeImporter();
    }

    if (schemaOverrides.ok) {
      this.processOverrides(await schemaOverrides.json);
    } else {
      console.warn("error fetching overrides");
    }

    this.importer.launch({
      userJwt: this.userJwt,
      templateKey: "journal_entry_upload",
      sessionToken: this.sessionTokenValue,
      templateOverrides: {
        columns_to_update: [
          {
            key: "ledger_name",
            validation_options: { picklist_options: this.entityOverrides },
          },
          {
            key: "account_name",
            validation_options: { picklist_options: this.accountOverrides },
          },
          {
            key: "tags",
            validation_options: { picklist_options: this.tagOverrides },
          },
          {
            key: "account_code",
            validation_options: { picklist_options: this.accountCodeOverrides },
          },
        ],
      },
    });
  }

  initializeImporter(): void {
    this.importer = oneschemaImporter({
      clientId: this.clientIdValue,
      templateKey: "journal_entry_upload",
      importConfig: { type: "local" },
      devMode: this.devModeValue,
      className: "oneschema-importer",
      styles: {
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        zIndex: "1000",
      },
    });

    this.importer.on("launched", (data: { sessionToken: string }) => {
      this.hideLoader();

      this.sessionTokenValue = data.sessionToken;

      this.updateButtonStyling();
    });

    this.importer.on("success", async (data) => {
      this.showLoader();

      const response = await post(this.importUrlValue, {
        responseKind: "turbo-stream",
        body: JSON.stringify({ one_schema: { session_token: this.sessionTokenValue, ...data } }),
      });

      Turbo.renderStreamMessage(await response.text);

      if (!response.ok) {
        console.error("There was an error importing the file.");
      }

      this.hideLoader();
    });

    this.importer.on("cancel", () => {
      console.warn("Import cancelled");

      this.sessionTokenValue = "";

      this.updateButtonStyling();
    });

    this.importer.on("error", (error) => {
      console.error(error);
    });
  }

  disconnect(): void {
    this.importer?.close(true);
  }

  showLoader() {
    this.importButtonTarget.disabled = true;
    this.importButtonTarget.querySelector("div#processing_spinner").classList.remove("hidden");
    this.formTurboFrame.classList.add(...this.formOverlayClasses);
  }

  hideLoader() {
    this.importButtonTarget.querySelector("div#processing_spinner").classList.add("hidden");
    this.importButtonTarget.disabled = false;
    this.formTurboFrame.classList.remove(...this.formOverlayClasses);
  }

  processOverrides(responseData: IOneSchemaOverridesResponseData): void {
    const processedData = processSchemaOverridesResponse(responseData);

    this.accountOverrides = processedData.accountOverrides;
    this.entityOverrides = processedData.entityOverrides;
    this.tagOverrides = processedData.tagOverrides;
    this.accountCodeOverrides = processedData.accountCodeOverrides;
  }

  updateButtonStyling(): void {
    if (this.sessionTokenValue !== "") {
      this.importButtonTarget.classList.add("btn-tertiary");
      this.importButtonTarget.classList.remove("btn-secondary");
      this.importButtonTarget.querySelector("span#button_copy").textContent = "Review File";
    } else {
      this.importButtonTarget.classList.remove("btn-tertiary");
      this.importButtonTarget.classList.add("btn-secondary");
      this.importButtonTarget.querySelector("span#button_copy").textContent = "Upload File";
    }
  }

  get userJwt(): string {
    return this.hasJwtTokenValue ? this.jwtTokenValue : "unknown";
  }

  get formOverlayClasses(): string[] {
    return ["pointer-events-none", "transition-opacity", "duration-150", "ease-in-out", "opacity-50"];
  }

  get formTurboFrame(): FrameElement {
    return document.querySelector("turbo-frame#form");
  }
}
