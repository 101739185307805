import { Controller } from "@hotwired/stimulus";
import { GridOptions, GetRowIdParams, IRowNode, createGrid, GridApi } from "ag-grid-enterprise";
import { dataTypeDefinitions } from "../helpers/reports/formatters";
import { getSelectedRows } from "../helpers/select_helpers";
import { createServerSideDatasource } from "../helpers/ledger_entry_datasource";
import { post } from "@rails/request.js";

interface IInvestmentTransactionRow {
  id: number;
  selected?: boolean;
  date: string;
  entity: string;
  account: string;
  description: string;
  type: string;
  sub_type: string;
  amount: string;
}

// Connects to data-controller="investment-transactions"
export default class extends Controller {
  declare datasourceUrlValue: string;
  declare hasDatasourceUrlValue: boolean;

  declare gridTarget: HTMLDivElement;
  declare transactionCountTargets: HTMLSpanElement[];

  declare categorizeButtonTarget: HTMLButtonElement;
  declare hasCategorizeButtonTarget: boolean;

  declare bulkRetypeButtonTarget: HTMLButtonElement;
  declare hasBulkRetypeButtonTarget: boolean;

  declare goToButtonTarget: HTMLButtonElement;
  declare hasGoToButtonTarget: boolean;

  declare bulkSubmitButtonTarget: HTMLButtonElement;
  declare hasBulkSubmitButtonTarget: boolean;

  declare transactionIdInputTemplateTarget: HTMLTemplateElement;
  declare transactionIdInputContainerTargets: HTMLDivElement[];
  declare transactionIdInputTargets: HTMLInputElement[];
  declare typeSelectTargets: HTMLSelectElement[];

  declare filterHeaderTarget: HTMLDivElement;
  declare filterInfoTarget: HTMLSpanElement;

  declare gridOptions: GridOptions<IInvestmentTransactionRow>;
  declare gridApi: GridApi;
  declare pageSize: number;

  declare currentlyHighlightedRow: HTMLDivElement;

  static targets = [
    "grid",
    "transactionCount",
    "categorizeButton",
    "bulkRetypeButton",
    "goToButton",
    "transactionIdInputTemplate",
    "form",
    "transactionIdInput",
    "filterHeader",
    "filterInfo",
    "transactionIdInputContainer",
    "bulkSubmitButton",
    "typeSelect",
  ];

  static values = {
    datasourceUrl: String,
    availableTypesMapping: Object,
  };

  connect() {
    this.pageSize = 50;

    const defaultNumberColDef = {
      width: 180,
      cellDataType: "number",
      filter: "agNumberColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        filterOptions: [
          "equals",
          "notEqual",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
          "inRange",
        ],
      },
      cellClass: ["ag-right-aligned-cell", "font-mono"],
      sortable: true,
    };

    const transactionData = this.gridTarget.dataset.investmentTransactionsTransactionDataValue;
    const hasTransactionData = transactionData !== undefined;

    const isClientSideData = hasTransactionData;

    this.gridOptions = {
      columnDefs: [
        {
          field: "selected",
          width: isClientSideData ? 30 : 50,
          sortable: false,
          suppressMovable: true,
          suppressHeaderMenuButton: true,
          resizable: false,
          headerName: "",
          headerCheckboxSelection: !isClientSideData,
          checkboxSelection: !isClientSideData,
          cellRendererParams: {
            checkbox: !isClientSideData,
          },
        },
        {
          field: "date",
          width: 140,
          sortable: true,
          sort: "desc",
          cellClass: "font-mono",
          cellDataType: "dateString",
          filter: "agDateColumnFilter",
          filterParams: {
            maxNumConditions: 1,
          },
        },
        {
          field: "entity",
          filter: "agTextColumnFilter",
          filterParams: {
            defaultOption: "equals",
          },
          width: 200,
        },
        {
          field: "account",
          filter: "agTextColumnFilter",
          filterParams: {
            defaultOption: "equals",
            filterOptions: ["equals", "notEqual"],
          },
          width: 240,
        },
        { field: "description", sortable: true, minWidth: 120, flex: 1 },
        {
          field: "type",
          width: 100,
          sortable: true,
          enableCellChangeFlash: true,
          filter: "agTextColumnFilter",
          filterParams: {
            defaultOption: "equals",
            filterOptions: ["equals", "notEqual"],
          },
        },
        {
          field: "sub_type",
          headerName: "Sub Type",
          width: 100,
          sortable: true,
          enableCellChangeFlash: true,
          filter: "agTextColumnFilter",
          filterParams: {
            defaultOption: "equals",
            filterOptions: ["equals", "notEqual"],
          },
        },
        { field: "amount", ...defaultNumberColDef },
      ],
      defaultColDef: {
        sortable: false,
      },
      getRowId: (params: GetRowIdParams) => params.data.id.toString(),
      statusBar: {
        statusPanels: [{ statusPanel: "agSelectedRowCountComponent" }, { statusPanel: "agAggregationComponent" }],
      },
      allowContextMenuWithControlKey: true,
      rowSelection: "multiple" as const,
      suppressRowClickSelection: true,
      dataTypeDefinitions: {
        ...dataTypeDefinitions
      },
      onRowSelected: (_event) => {
        this.updateCategorizeButton();
        this.updateBulkUpdateButton();

        this.transactionIdInputContainerTargets.forEach((inputContainer) => {
          this.setHiddenTransactionInputs(inputContainer);
        });
      },
      onCellClicked: function (params) {
        if (params.column.getColDef().checkboxSelection) {
          params.node.setSelected(!params.node.isSelected());

          params.api.refreshCells({
            columns: [params.column.getColId()],
            rowNodes: [params.node],
            force: true,
          });
        }
      },
    };

    if (this.hasDatasourceUrlValue) {
      this.gridOptions = {
        ...this.gridOptions,
        rowModelType: "serverSide",
        serverSideDatasource: createServerSideDatasource(
          this.datasourceUrlValue,
          this.getLatestFormData,
          this.pageSize,
        ),
        cacheBlockSize: this.pageSize,
      };
    } else if (hasTransactionData) {
      this.gridOptions.rowData = this.getTransactionData(this.gridTarget);
    }

    this.gridApi = createGrid(this.gridTarget, this.gridOptions);

    const selectAllCheckbox = document.querySelector(
      ".ag-header-select-all .ag-wrapper .ag-checkbox-input",
    ) as HTMLInputElement;
    selectAllCheckbox.addEventListener("change", (event) => {
      const isChecked = (event.target as HTMLInputElement).checked;

      // Iterate through the rows and select/unselect them based on the checkbox state
      this.gridApi.forEachNode((node: IRowNode) => {
        if (node.data) {
          // Check if the row is currently loaded in the grid
          node.setSelected(isChecked);
        }
      });
    });

    this.updateCategorizeButton();
  }

  getTransactionData(gridElement: HTMLDivElement) {
    const transactionData = gridElement.dataset.investmentTransactionsTransactionDataValue;
    return JSON.parse(transactionData);
  }

  getLatestFormData() {
    return new FormData();
  }

  setHiddenTransactionInputs(inputContainer: HTMLDivElement) {
    const selectedRows = getSelectedRows(this.gridApi);

    inputContainer.innerHTML = "";

    selectedRows.forEach((row) => {
      const transactionIdInput = document.importNode(this.transactionIdInputTemplateTarget.content, true);
      const transactionIdInputEl = transactionIdInput.querySelector("input");
      transactionIdInputEl.value = row.id;
      transactionIdInputEl.name = "transaction_ids[]";

      inputContainer.appendChild(transactionIdInput);
    });
  }

  filterTransactions(event: Event) {
    event.preventDefault();
    this.resetFilters(event);

    // show filter header
    this.filterHeaderTarget.classList.remove("hidden");

    // scroll to grid
    this.gridTarget.scrollIntoView({ behavior: "smooth" });

    // get filter params from button that triggered this function
    const currentTarget = event.currentTarget as HTMLButtonElement;
    const ledger = currentTarget.getAttribute("data-investment-transactions-ledger");
    const account = currentTarget.getAttribute("data-investment-transactions-account");
    const type = currentTarget.getAttribute("data-investment-transactions-type");
    const subType = currentTarget.getAttribute("data-investment-transactions-subtype");

    // filter the grid
    this.gridApi.setFilterModel({
      entity: {
        filterType: "text",
        type: "equals",
        filter: ledger,
      },
      account: {
        filterType: "text",
        type: "equals",
        filter: account,
      },
      type: {
        filterType: "text",
        type: "equals",
        filter: type,
      },
      sub_type: {
        filterType: "text",
        type: "equals",
        filter: subType,
      },
    });

    // get total number of rows in grid after applying filter
    const totalRows = this.gridApi.getDisplayedRowCount();

    // show filter info
    this.filterInfoTarget.innerText = `Showing ${totalRows} ${
      totalRows > 1 ? "transactions" : "transaction"
    } for ${type} (${subType})`;

    // highlight the row that was clicked
    this.highlightRow(currentTarget.closest("div.categorization-row") as HTMLDivElement);
  }

  resetFilters(event: Event) {
    event.preventDefault();

    this.gridApi.setFilterModel(null);
    this.filterHeaderTarget.classList.add("hidden");
    this.unhighlightCurrentlyHighlightedRow();
  }

  highlightRow(row: HTMLDivElement) {
    this.currentlyHighlightedRow = row;
    row.classList.add("bg-sky-100");
  }

  unhighlightCurrentlyHighlightedRow() {
    if (this.currentlyHighlightedRow) {
      this.currentlyHighlightedRow.classList.remove("bg-sky-100");
      this.currentlyHighlightedRow = null;
    }
  }

  disconnect() {
    this.gridApi.destroy();
  }

  gridTargetConnected(gridElement: HTMLDivElement) {
    if (this.gridApi !== undefined) {
      this.gridOptions.rowData = this.getTransactionData(gridElement);
      this.gridApi = createGrid(gridElement, this.gridOptions);
    }
  }

  updateCategorizeButton() {
    if (this.hasCategorizeButtonTarget === false) return;

    const selectedRows = getSelectedRows(this.gridApi);
    const count = selectedRows.length;

    this.categorizeButtonTarget.disabled = count === 0;
    this.transactionCountTargets.forEach((span) => {
      span.innerText = count.toString();
      span.classList.toggle("hidden", count === 0);
    });

    if (this.hasGoToButtonTarget) {
      this.goToButtonTarget.classList.toggle("hidden", count > 0);
      this.categorizeButtonTarget.classList.toggle("hidden", count === 0);
    }
  }

  updateBulkUpdateButton() {
    if (this.hasBulkRetypeButtonTarget === false) return;

    const selectedRows = getSelectedRows(this.gridApi);
    const count = selectedRows.length;

    this.bulkRetypeButtonTarget.disabled = count === 0;
  }

  handleTypeChange(_event: Event) {
    const allValid = this.typeSelectTargets.every((select) => {
      return select.value !== "";
    });

    if (allValid) {
      this.bulkSubmitButtonTarget.disabled = false;
    } else {
      this.bulkSubmitButtonTarget.disabled = true;
    }
  }

  handleBulkUpdateSubmit(event: Event) {
    event.preventDefault();

    this.bulkSubmitButtonTarget.disabled = true;

    // The target is a div within the button, so we need to get the form
    const button = (event.target as HTMLDivElement).closest("button") as HTMLButtonElement;
    const form = button.form as HTMLFormElement;

    post(form.action, {
      body: new FormData(form),
      responseKind: "json",
    })
      .then((response) => {
        if (response.ok) {
          return response.json;
        } else {
          throw new Error("Server response was not ok.");
        }
      })
      .then((data) => {
        const updatedTransactions = data.updated_transactions as IInvestmentTransactionRow[];

        updatedTransactions.forEach((updatedTransaction: IInvestmentTransactionRow) => {
          this.gridApi.getRowNode(updatedTransaction.id.toString()).updateData(updatedTransaction);
        });

        this.gridApi.deselectAll();

        this.dispatch("bulkUpdateSuccess");

        form.reset();
      })
      .catch((error) => {
        console.error("Could not update data.", error);
      });
  }
}
