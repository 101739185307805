import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";
import TomSelect from "tom-select";
import { TomInput, TomOption } from "tom-select/dist/types/types";
import { loadingIcon } from "../helpers";

interface optgroup {
  label: string;
  value: string;
}

interface account {
  id: number;
  label: string;
  type: string;
}

// Connects to data-controller="account-select"
export default class extends Controller<HTMLSelectElement> {
  static targets = ["ledger"];

  static values = {
    accountUrl: String,
    classes: Array,
    itemClasses: Array,
    lock: Boolean,
    accountNameAndNumber: String,
    maxOptions: {type: Number, default: 100}
  };

  declare select: TomSelect;
  declare hasClassesValue: boolean;
  declare classesValue: string[];
  declare hasItemClassesValue: boolean;
  declare itemClassesValue: string[];
  declare lockValue: boolean;

  declare accountUrlValue: string;
  declare hasaccountUrlValue: boolean;

  declare ledgerTarget: HTMLSelectElement;

  declare maxOptionsValue: number;

  connect() {
    let plugins = ["remove_button"];

    if (this.lockValue) {
      plugins = [];
    }

    const options = {
      plugins: plugins,
      preload: "focus",
      maxOptions: this.maxOptionsValue,
      load: this.loadOptions.bind(this),
      hidePlaceholder: true,
      valueField: "id",
      labelField: "label",
      searchField: ["label"],
      optgroupField: "type",
      optgroupLabelField: "type",
      onBlur: () => {
        // We want the options to reload when the user focuses on the select
        this.select.wrapper.classList.remove("preloaded");
        this.select.clearOptions();
        this.select.settings.load = this.loadOptions.bind(this);
      },
      sortField: [{ field: "$order" }, { field: "$score" }], // Maintain order of results by number while searching
      render: {
        optgroup_header: (data: optgroup, escape: (value: string) => string) => {
          const style = `style="background-color: #F1F2F5;"`;
          return `
            <div class="optgroup-header" ${style}>
              ${escape(data.value)}
            </div>
          `;
        },
        loading: (_data, _escape) => {
          return `
            <div class="p-1 flex flex-row gap-2 items-center">
              ${loadingIcon()}
              <span>Loading...</span>
            </div>
          `;
        },
      },
      onItemAdd: () => {
        this.select.setTextboxValue("");
      },
    };

    this.select = new TomSelect(this.element as unknown as TomInput, options);

    if (this.hasClassesValue) {
      this.classesValue.forEach((className) => {
        this.select.control.classList.add(className);
      });
    }

    if (this.lockValue) {
      this.select.lock();
    }
  }

  unfocusSelect() {
    this.select.blur();
  }

  disconnect() {
    this.select.destroy();
  }

  // Build out the optgroup data structure for TomSelect
  buildOptGroups(accounts: account[]): TomOption[] {
    const accountGroups = accounts.reduce((groups, account) => {
      if (!groups[account.type]) {
        groups[account.type] = {
          label: account.type,
          value: account.type,
        };
      }
      return groups;
    }, {});
    return Object.values(accountGroups);
  }

  loadOptions(_query: string, callback: (accounts: account[] | void, optionGroups: TomOption[] | void) => void) {
    if (this.select.loading > 1) {
      callback();
      return;
    }

    get(this.accountUrlValue, { responseKind: "json" })
      .then((response: Response) => response.json)
      .then((payload: account[]) => {
        const optionGroups = this.buildOptGroups(payload);

        callback(payload, optionGroups);

        // We only want to load the data once, so we set the load function to null
        this.select.settings.load = null;
      })
      .catch((error) => {
        console.error(error);

        callback();
      });
  }
}
