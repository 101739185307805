import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="connected-transactions"
export default class extends Controller {
  declare transactionRowTargets: HTMLDivElement[];
  declare loaderTarget: HTMLDivElement;
  declare urlValue: string;

  static targets = [
    "accountSelect",
    "transactionRow",
    "loader",
    "bulkApproveButton",
    "bulkIgnoreButton",
    "bulkUpdateButton",
    "bankTransactionCount",
    "bulkEditErrors",
    "checkbox",
    "ignoreTransactionButton",
  ];
  static values = { url: String };

  declare accountSelectTargets: HTMLSelectElement[];
  declare bulkApproveButtonTarget: HTMLButtonElement;
  declare bulkIgnoreButtonTarget: HTMLButtonElement;
  declare bulkUpdateButtonTarget: HTMLButtonElement;
  declare bulkEditErrorsTarget: HTMLDivElement;
  declare bankTransactionCountTargets: HTMLSpanElement[];
  declare checkboxTargets: HTMLInputElement[];
  declare ignoreTransactionButtonTargets: HTMLButtonElement[];

  loadNewTransactionsIfNeeded() {
    const numRows = this.transactionRowTargets.length;

    if (numRows === 1) {
      this.loaderTarget.classList.remove('hidden');
      this.setLastRecordFormParam(this.transactionRowTargets[0]);
    }
  }

  bulkApprove() {
    const selectedRows = this.getSelectedCheckboxes();
    const selectedDestinationAccounts = this.getSelectedDestinationAccounts();
    
    this.disableBulkActionButtons();

    selectedRows.forEach((checkbox) => {
      const accountSelect = selectedDestinationAccounts.find((select) => {
        return select.dataset.connectedTransactionsBankTransactionIdValue === checkbox.value
      });

      if (accountSelect.value !== "") {
        accountSelect.form["bulk_approve"].value = "true";
        accountSelect.form.requestSubmit();
      }
    });
  }

  bulkIgnore() {
    const selectedRows = this.getSelectedCheckboxes();
    const bulkIgnoreInput = document.createElement("input");

    this.disableBulkActionButtons();

    bulkIgnoreInput.name = "bulk_ignore";
    bulkIgnoreInput.type = "text";
    bulkIgnoreInput.value = "true";
    bulkIgnoreInput.classList.add("hidden");

    selectedRows.forEach((checkbox) => {
      const ignoreButtonTarget = this.ignoreTransactionButtonTargets.find((button) => {
        return button.dataset.connectedTransactionsBankTransactionIdValue === checkbox.value
      });

      ignoreButtonTarget.form.appendChild(bulkIgnoreInput);
      ignoreButtonTarget.click();
    });
  }

  disableBulkActionButtons() {
    this.bulkApproveButtonTarget.setAttribute("disabled", "");
    this.bulkIgnoreButtonTarget.setAttribute("disabled", "");
    this.bulkUpdateButtonTarget.setAttribute("disabled", "");
  }

  updateBulkActionButtons() {
    const selectedRows = this.getSelectedCheckboxes();
    const count = selectedRows.length;

    const selectedDestinationAccounts = this.getSelectedDestinationAccounts();
    const selectedDestinationAccountBankTransactionIds = selectedDestinationAccounts.map((select) => {
      return select.dataset.connectedTransactionsBankTransactionIdValue;
    });
    const selectedRowBankTransactionIds = selectedRows.map(checkbox => checkbox.value);
    const allSelectedRowsHaveDestinationAccounts = selectedRowBankTransactionIds.every((id) => {
      return selectedDestinationAccountBankTransactionIds.includes(id)
    });

    this.bulkEditErrorsTarget.classList.add("hidden");
    this.bulkUpdateButtonTarget.disabled = count === 0;
    this.bulkIgnoreButtonTarget.disabled = count === 0;
        
    if (count !== 0 && allSelectedRowsHaveDestinationAccounts) {
      this.bulkApproveButtonTarget.removeAttribute("disabled");
    } else {
      this.bulkApproveButtonTarget.setAttribute("disabled", "");
    }

    this.bankTransactionCountTargets.forEach((el) => {
      el.innerText = count.toString();
      el.classList.toggle("hidden", count === 0);
    });
  }

  getSelectedCheckboxes() {
    return this.checkboxTargets.filter((checkbox) => {
      return checkbox.checked;
    });
  }

  getSelectedDestinationAccounts() {
    return this.accountSelectTargets.filter(select => select.value !== "");
  }

  setLastRecordFormParam(row) {
    const transactionForm = row.querySelector("form");
    const formActionUrl = new URL(transactionForm.action);
    const searchParams = new URLSearchParams(formActionUrl.search);
    searchParams.set('last_record', 'true');
    formActionUrl.search = searchParams.toString();
    transactionForm.action = formActionUrl.toString();
  }
}
