import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { TomInput } from "tom-select/dist/types/types";

// Connects to data-controller="ledger-select"
export default class extends Controller<HTMLSelectElement> {
  declare select: TomSelect;

  static values = {
    ledgerOptions: Array,
    controlClasses: Array,
    maxItems: Number,
  };

  declare controlClassesValue: string[];
  declare maxItemsValue: number;
  declare hasMaxItemsValue: boolean;

  connect() {
    const controlClasses = ["ts-control", ...this.controlClassesValue].join(" ");
    let plugins = [];

    if (this.element.multiple) {
      plugins = ["remove_button"];
    }

    let maxItems = null;
    if (this.hasMaxItemsValue) {
      maxItems = this.maxItemsValue;
    }

    this.select = new TomSelect(this.element as unknown as TomInput, {
      hidePlaceholder: true,
      controlClass: controlClasses,
      plugins: plugins,
      maxItems: this.element.multiple ? maxItems : 1,
      render: {
        option: (data, escape) => {
          if (data.disabled || String(data.disabledLabel).toLowerCase() === "true") {
            return `
              <div class="flex flex-row gap-2 items-center justify-between">
                <span class="text-gray-700">${escape(data.text)}</span>
                <span class="bg-gray-200 rounded px-2 py-0.5 text-gray-600 text-xs font-medium">Deactivated</span>
              </div>
            `;
          } else {
            return `
              <div class="flex flex-row gap-2 items-center">
                <span class="text-gray-700">${escape(data.text)}</span>
              </div>
            `;
          }
        },
      },
    });
  }

  disconnect() {
    this.select.destroy();
  }

  reconnect() {
    this.disconnect();
    this.connect();
  }
}
