import { Controller } from "@hotwired/stimulus";
import { delegate, Instance as TippyInstance } from "tippy.js";

// Connects to data-controller="table"
export default class extends Controller<HTMLElement> {
  declare tippy: TippyInstance;

  static targets = ["cell"];

  declare cellTargets: HTMLElement[];

  connect() {
    this.tippy = delegate(this.element, { target: "span.cell", onShow: this.onShow });
  }

  disconnect() {
    this.tippy.destroy();
  }

  onShow(instance: TippyInstance): void | false {
    const target = instance.reference as HTMLElement;
    const targetHasText = target?.title || target?.innerText?.trim();

    if (target?.title) {
      instance.setContent(target.title);
    } else {
      instance.setContent(target.innerText.trim());
    }

    if (target && targetHasText && target.offsetWidth < target.scrollWidth) {
      return;
    } else {
      // If the content is not truncated, don't show the tooltip
      return false;
    }
  }
}
