import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="autohide"
export default class extends Controller {
  static values = {
    delay: Number,
    class: String,
  }

  declare delayValue: number;
  declare classValue: string;

  connect() {
    setTimeout(() => {
      this.element.classList.add(...this.classValue.split(" "))
      setTimeout(() => {
        this.element.classList.add('hidden');
      }, 500);
    }, this.delayValue || 5000)
  }
}
