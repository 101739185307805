import { Toggle } from "tailwindcss-stimulus-components";

// Connects to data-controller="toggle"
export default class extends Toggle {
  static targets = ["toggleable", "actionIcon"];
  static values = {
    open: { type: Boolean, default: false },
  };

  declare toggleableTargets: HTMLElement[];

  declare actionIconTarget: HTMLElement;
  declare hasActionIconTarget: boolean;

  declare openValue: boolean;

  connect() {
    super.connect();

    if (this.hasActionIconTarget) {
      this.actionIconTarget.classList.add("transition-transform", "duration-200", "ease-in-out");

      if (this.openValue) {
        this.actionIconTarget.classList.add("rotate-0");
      } else {
        this.actionIconTarget.classList.add("-rotate-90");
      }
    }
  }

  animate() {
    if (this.hasActionIconTarget) {
      if (this.openValue) {
        this.actionIconTarget.classList.add("rotate-0");
        this.actionIconTarget.classList.remove("-rotate-90");
      } else {
        this.actionIconTarget.classList.add("-rotate-90");
        this.actionIconTarget.classList.remove("rotate-0");
      }
    }

    super.animate();
  }
}
