import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="dynamic-select"
export default class extends Controller {
  static targets = ["select", "source"];
  static values = {
    url: String,
    selectedAccountId: String,
  };

  declare selectTarget: HTMLSelectElement;
  declare sourceTarget: HTMLSelectElement;
  declare hasSourceTarget: boolean;
  declare selectedAccountIdValue: string;

  declare urlValue: string;

  connect() {
    if (this.hasSourceTarget) {
      this.setSelectOptions({ target: this.sourceTarget });
    }
  }

  setSelectOptions({ target }) {
    get(this.urlValue, {
      query: {
        target: this.selectTarget.id,
        postable: false,
        type: target.value,
        selected_account_id: this.selectedAccountIdValue,
      },
      responseKind: "turbo-stream",
    });
  }
}
