import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="template"
export default class extends Controller {
  static targets = ["template", "target"];

  declare templateTarget: HTMLTemplateElement;
  declare targetTarget: HTMLElement;

  render() {
    this.targetTarget.insertAdjacentHTML("beforebegin", this.templateTarget.innerHTML)
  }
}
