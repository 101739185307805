import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="ledger-transaction-notices"
export default class extends Controller {
  static targets = ["ledgerSelect", "effectiveAt", "effectiveAtWas", "alert", "cashAccountNotice"];

  static values = {
    noticesUrl: String,
    ledgerLastClosedAtDates: Object,
  };

  declare noticesUrlValue: string;

  declare ledgerSelectTargets: HTMLSelectElement[];
  declare effectiveAtTarget: HTMLInputElement;
  declare effectiveAtWasTarget: HTMLInputElement;
  declare alertTarget: HTMLDivElement;

  declare cashAccountNoticeTarget: HTMLDivElement;
  declare hasCashAccountNoticeTarget: boolean;

  declare ledgerLastClosedAtDatesValue: object;

  connect() {
    this.cashAccountNotice();
  }

  effectiveAtTargetConnected() {
    this.effectiveAtNotice();
  }

  getAllAccountSelects() {
    return this.element.querySelectorAll("[data-account-select-container] select");
  }

  handleAccountChange() {
    this.cashAccountNotice();
  }

  handleEffectiveAtChange() {
    this.effectiveAtNotice();
  }

  handleLedgerChange() {
    this.effectiveAtNotice();
  }

  cashAccountNotice() {
    const accountIds = [];
    const selectElements = this.getAllAccountSelects();

    selectElements.forEach((selectTarget: HTMLSelectElement) => {
      if (selectTarget.hasChildNodes()) {
        const selectedAccountId = selectTarget.selectedOptions[0].value;

        if (selectedAccountId !== "") {
          accountIds.push(selectedAccountId);
        }
      }
    });

    if (accountIds.length === 0) {
      if (this.hasCashAccountNoticeTarget) {
        this.cashAccountNoticeTarget.innerHTML = "";
      }
      return;
    }

    const url = new URL(this.noticesUrlValue, window.location.href);

    post(url.toString(), {
      body: {
        account_ids: accountIds,
        notices_to_render: [this.cashAccountNoticeTarget.id],
      },
      responseKind: "turbo-stream",
    });
  }

  effectiveAtNotice() {
    const effectiveDate = new Date(this.effectiveAtTarget.value);
    const effectiveDateWas = this.effectiveAtWasTarget.value ? new Date(this.effectiveAtWasTarget.value) : null;

    if (!effectiveDate) return;

    let hasClosedEntry = false;

    this.ledgerSelectTargets.forEach((select) => {
      const closeDateString = this.ledgerLastClosedAtDatesValue[select.value];
      if (!closeDateString) {
        return;
      }

      const closeDate = new Date(closeDateString);

      const fromNullToOutsideClosedPeriod = !effectiveDateWas && effectiveDate > closeDate;
      const fromOutsideToOutsideClosedPeriod =
        effectiveDateWas && effectiveDateWas > closeDate && effectiveDate > closeDate;
      if (!fromNullToOutsideClosedPeriod && !fromOutsideToOutsideClosedPeriod) {
        hasClosedEntry = true;
      }
    });

    if (hasClosedEntry) {
      this.alertTarget.classList.remove("hidden");

      const input = this.alertTarget.querySelector("input");
      if (input) {
        input.disabled = false;
      }
    } else {
      this.alertTarget.classList.add("hidden");

      // Disabling the input prevents it from being submitted with the form
      const input = this.alertTarget.querySelector("input");
      if (input) {
        input.disabled = true;
      }
    }
  }
}
