import { Controller } from "@hotwired/stimulus";
import { GetRowIdParams, GridOptions, ToolPanelDef, createGrid, GridApi } from "ag-grid-enterprise";
import { HTMLRenderer } from "../helpers/reports/renderers";
import { dataTypeDefinitions } from "../helpers/reports/formatters";
import { createServerSideDatasource } from "../helpers/ledger_entry_datasource";
import { NoRowsOnRegisterOverlay } from "../helpers/reports/overlays";
import { getSelectedRows } from "../helpers/select_helpers";
import { post } from "@rails/request.js";

// Connects to data-controller="register"
export default class extends Controller {
  declare datasourceUrlValue: string;
  declare gridTarget: HTMLDivElement;
  declare gridOptions: GridOptions;
  declare gridApi: GridApi;
  declare startDate: string;
  declare endDate: string;
  declare formData: FormData;
  declare pageSize: number;

  declare bulkEditFormTarget: HTMLFormElement;
  declare bulkEditButtonTarget: HTMLButtonElement;
  declare bulkUpdateButtonTarget: HTMLButtonElement;
  declare hasBulkEditButtonTarget: boolean;
  declare hasBulkUpdateButtonTarget: boolean;
  declare cancelBulkEditButtonTarget: HTMLButtonElement;
  declare bulkEditErrorsTarget: HTMLDivElement;
  declare bulkUpdateModalTarget: HTMLDivElement;
  declare cashFlowAlertTarget: HTMLDivElement;

  declare ledgerEntryIdInputTemplateTarget: HTMLTemplateElement;
  declare ledgerEntryIdInputTargets: HTMLInputElement[];
  declare ledgerEntryCountTargets: HTMLSpanElement[];
  declare ledgerEntryIdInputContainerTarget: HTMLDivElement;

  declare canViewColumnsValue: boolean;

  declare bulkEditMode: boolean;

  static targets = [
    "datepicker",
    "grid",
    "bulkEditForm",
    "bulkEditButton",
    "bulkUpdateButton",
    "cancelBulkEditButton",
    "cashFlowAlert",
    "ledgerEntryIdInput",
    "ledgerEntryIdInputTemplate",
    "ledgerEntryCount",
    "bulkUpdateModal",
    "ledgerEntryModalCount",
    "ledgerEntryIdInputContainer",
    "bulkEditErrors",
  ];

  static values = {
    datasourceUrl: String,
    canViewColumns: Boolean,
  };

  setFilters(event) {
    const form = event.target.form as HTMLFormElement;
    const filterFormData = new FormData(form);

    if (isFormEmpty(filterFormData) || isCustomDateRangeWithoutDates(filterFormData)) {
      return;
    }

    const currentUrl = new URL(window.location.href);
    const currentSearchParams = currentUrl.searchParams;

    const filterModel = this.gridApi.getFilterModel();

    currentSearchParams.set("filterModel", JSON.stringify(filterModel));

    const { mergedSearchParams, mergedFormData } = this.mergeSearchParamsAndFormData(
      filterFormData,
      currentSearchParams,
    );

    currentUrl.search = mergedSearchParams.toString();

    if (window.location.href !== currentUrl.toString()) {
      window.history.pushState({}, "", currentUrl.toString());
    }

    this.formData = mergedFormData;

    // When the form is submitted to add/remove filters, we need to do a full purge of the cache
    this.gridApi.refreshServerSide({ purge: true });
  }

  mergeSearchParamsAndFormData(
    formData: FormData,
    searchParams: URLSearchParams,
  ): { mergedSearchParams: URLSearchParams; mergedFormData: FormData } {
    const mergedSearchParams = new URLSearchParams(searchParams.toString());
    const mergedFormData = new FormData();

    formData.forEach((_value, key) => mergedSearchParams.delete(key));
    formData.forEach((value, key) => {
      if (value !== "") {
        mergedSearchParams.append(key, String(value));
        mergedFormData.append(key, value);
      }
    });

    if (searchParams.get("cash_flow") === "true") {
      mergedFormData.set("cash_flow", "true");
    } else {
      mergedSearchParams.delete("cash_flow");
    }

    return {
      mergedSearchParams,
      mergedFormData,
    };
  }

  connect() {
    this.pageSize = 50;

    const defaultNumberColDef = {
      minWidth: 120,
      cellDataType: "number",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "equals",
          "notEqual",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
          "inRange",
        ],
      },
      cellClass: ["ag-right-aligned-cell", "font-mono"],
      sortable: true,
    };

    const actionItem = this.canViewColumnsValue
      ? {
        field: "actions",
        width: 30,
        flex: 0,
        sortable: false,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        resizable: false,
        headerName: "",
        cellClass: "grid-action-cell",
        cellRenderer: HTMLRenderer,
      } : {
        width: 30,
        flex: 0,
        cellClass: "grid-action-cell",
      };

    // Hide sideBar if the user's role has not allowed
    const sideBarConfig = this.canViewColumnsValue
      ? {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
            },
          } as ToolPanelDef,
          "filters",
        ],
      }
      : null;

    this.gridOptions = {
      columnDefs: [
        {
          field: "bulkEdit",
          width: 30,
          flex: 0,
          sortable: false,
          suppressMovable: true,
          suppressHeaderMenuButton: true,
          resizable: false,
          headerName: "",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          cellRendererParams: {
            checkbox: true,
          },
          cellClass: "grid-action-cell",
          headerClass: "custom-checkbox-header",
          hide: true,
        },
        actionItem,
        {
          field: "effectiveAt",
          minWidth: 140,
          sortable: true,
          initialSort: "desc",
          cellClass: "font-mono",
          cellDataType: "dateString",
        },
        {
          field: "entity",
          sortable: true,
          minWidth: 200,
        },
        {
          field: "account",
          sortable: true,
          minWidth: 150,
        },
        {
          field: "accountCode",
          minWidth: 160,
          cellRenderer: HTMLRenderer,
        },
        {
          field: "debits",
          ...defaultNumberColDef,
        },
        {
          field: "credits",
          ...defaultNumberColDef,
        },
        { field: "description", minWidth: 200 },
        {
          field: "tags",
          flex: 0,
          cellClass: "grid-tag-cell",
          cellEditorPopup: true,
          minWidth: 200,
          width: 300,
          cellRenderer: HTMLRenderer,
        },
      ],
      defaultColDef: {
        sortable: false,
        flex: 1,
      },
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowModelType: "serverSide",
      getRowId: (params: GetRowIdParams) => params.data.id.toString(),
      serverSideDatasource: createServerSideDatasource(this.datasourceUrlValue, this.getLatestFormData, this.pageSize),
      dataTypeDefinitions: {
        ...dataTypeDefinitions,
      },
      cacheBlockSize: this.pageSize,
      enableRangeSelection: true,
      rowSelection: "multiple" as const,
      suppressRowClickSelection: true,
      statusBar: {
        statusPanels: [
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      sideBar: sideBarConfig,
      onFilterChanged: this.filterChanged,
      onRowSelected: (_event) => {
        this.updateBulkEditButton();
        this.setHiddenTransactionInputs();
      },
      allowContextMenuWithControlKey: true,
      noRowsOverlayComponent: NoRowsOnRegisterOverlay,
    };

    this.gridApi = createGrid(this.gridTarget, this.gridOptions);

    // Apply filter model from URL (if present)
    this.applyFilterModel();

    this.setFilters({ target: { form: document.getElementById("filters-form") } });

    this.updateCashFlowAlert();
  }

  enableBulkEditMode(_event: Event) {
    // convert action column in grid to checkbox selection
    this.gridApi.setColumnsVisible(["actions"], false);
    this.gridApi.setColumnsVisible(["bulkEdit"], true);

    this.gridApi.refreshCells({ columns: ["actions", "bulkEdit"] });
    this.gridApi.refreshHeader();

    this.bulkEditButtonTarget.classList.add("hidden");

    this.bulkUpdateButtonTarget.classList.remove("hidden");
    this.cancelBulkEditButtonTarget.classList.remove("hidden");
  }

  cancelBulkEditMode(_event: Event) {
    this.gridApi.setColumnsVisible(["actions"], true);
    this.gridApi.setColumnsVisible(["bulkEdit"], false);

    this.gridApi.refreshCells({ columns: ["actions", "bulkEdit"] });
    this.gridApi.refreshHeader();

    this.bulkEditButtonTarget.classList.remove("hidden");

    this.bulkUpdateButtonTarget.classList.add("hidden");
    this.cancelBulkEditButtonTarget.classList.add("hidden");

    this.bulkEditErrorsTarget.classList.add("hidden");
    this.gridApi.deselectAll();
  }

  updateBulkEditButton() {
    if (this.hasBulkEditButtonTarget === false) return;

    const selectedRows = getSelectedRows(this.gridApi);
    const count = selectedRows.length;

    if (count > 150) {
      this.bulkUpdateButtonTarget.disabled = true;
      this.bulkEditErrorsTarget.innerText =
        "You cannot bulk edit more than 150 transactions at a time, please remove some transactions to continue.";
      this.bulkEditErrorsTarget.classList.remove("hidden");
    } else {
      this.bulkEditErrorsTarget.classList.add("hidden");
      this.bulkUpdateButtonTarget.disabled = count === 0;
    }

    this.ledgerEntryCountTargets.forEach((el) => {
      el.innerText = count.toString();
      el.classList.toggle("hidden", count === 0);
    });
  }

  setHiddenTransactionInputs() {
    const selectedRows = getSelectedRows(this.gridApi);

    if (selectedRows.length <= 150) {
      this.ledgerEntryIdInputContainerTarget.innerHTML = "";

      selectedRows.forEach((row) => {
        const ledgerEntryIdInput = document.importNode(this.ledgerEntryIdInputTemplateTarget.content, true);
        const ledgerEntryIdInputEl = ledgerEntryIdInput.querySelector("input");
        ledgerEntryIdInputEl.value = row.id;

        this.ledgerEntryIdInputContainerTarget.appendChild(ledgerEntryIdInput);
      });
    }
  }

  exportCSV(event: Event) {
    const sizeCheckPath = this.getSizeCheckPath(event);
    const downloadPath = this.getDownloadPath(event);

    const params = this.prepareParams();

    // Checking how many rows the CSV will have
    post(sizeCheckPath, {
      body: params,
      responseKind: "turbo-stream",
    });

    post(downloadPath, {
      body: params,
      headers: { accept: "text/csv" },
    })
      .then(this.handleResponse)
      .then((blob: Blob) => {
        // Kinda hacky way to download the file, but I'm not sure there's a better way
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // Format current time as string to use in filename in the format YYYYMMDDHHMM
        const now = new Date();
        const formattedDate = now.toISOString().replace(/-|:|\.\d\d\d/g, "");
        a.download = `register_export_${formattedDate}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
  }

  disconnect() {
    this.gridApi.destroy();
  }

  applyFilterModel = () => {
    const filterModel = new URLSearchParams(window.location.search).get("filterModel");

    if (!filterModel) {
      return;
    }

    this.gridApi.setFilterModel(JSON.parse(filterModel));
  };

  filterChanged = () => {
    this.setFilters({ target: { form: document.getElementById("filters-form") } });
  };

  getLatestFormData = () => {
    return this.formData;
  };

  // Helper methods for exportCSV
  getDownloadPath(event) {
    return (event.currentTarget as HTMLButtonElement).form.action;
  }

  getSizeCheckPath(event) {
    return event.currentTarget.dataset.sizeCheckUrl;
  }

  prepareParams() {
    const displayedColumns = this.gridApi
      .getAllDisplayedColumns()
      .filter((column) => column.isVisible())
      .map((column) => column.getColId());
    const filterModel = this.gridApi.getFilterModel();
    const sorting = this.gridApi.getColumnState();
    const sortObject = sorting.find((sort) => sort.sort !== null);
    const sortModel = sortObject ? [{ sort: sortObject.sort, colId: sortObject.colId }] : [];
    const params = { displayedColumns, filterModel, sortModel };
    const currentUrlParams = new URLSearchParams(window.location.search);
    const queryParamsMap = new Map();

    for (const [key, value] of currentUrlParams.entries()) {
      // Skip the filter and sort model key as they have already been handled above when setting the params variable
      if (key === "filterModel" || key === "sortModel") {
        continue;
      }

      if (queryParamsMap.has(key)) {
        queryParamsMap.get(key).push(value);
      } else {
        queryParamsMap.set(key, [value]);
      }
    }

    const arrayKeys = new Set(["ledger_ids", "account_template_ids", "account_code_ids", "tag_ids"]);

    for (const [key, values] of queryParamsMap.entries()) {
      // Remove the '[]' suffix from the key if it exists
      const sanitizedKey = key.endsWith("[]") ? key.slice(0, -2) : key;
      if (arrayKeys.has(sanitizedKey) || values.length > 1) {
        params[sanitizedKey] = values;
      } else {
        params[sanitizedKey] = values[0];
      }
    }

    return params;
  }

  handleResponse(response) {
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    return response.response.blob();
  }

  removeCashFilter(event: Event) {
    event.preventDefault();
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("cash_flow");

    window.history.pushState({}, "", currentUrl.toString());

    this.setFilters({ target: { form: document.getElementById("filters-form") } });

    this.updateCashFlowAlert();
  }

  updateCashFlowAlert() {
    if (this.hasCashFlowFilter()) {
      this.cashFlowAlertTarget.classList.remove("hidden");
    } else {
      this.cashFlowAlertTarget.classList.add("hidden");
    }
  }

  hasCashFlowFilter() {
    const cashFlowValue = new URLSearchParams(window.location.search).get("cash_flow");

    return cashFlowValue === "true";
  }
}

function isFormEmpty(formData: FormData): boolean {
  // @ts-ignore
  return Array.from(formData.entries()).length === 0;
}

function isCustomDateRangeWithoutDates(formData: FormData): boolean {
  const isCustomDateRange = formData.get("date_range") === "Custom";
  const startDate = formData.get("start_date");
  const endDate = formData.get("end_date");

  return isCustomDateRange && !startDate && !endDate;
}
