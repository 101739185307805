import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import { Plaid as PlaidTypes } from "plaid-link";

const LINK_TOKEN_LOCAL_STORAGE_KEY = "linkTokenData";

export default class extends Controller<HTMLFormElement> {
  static targets = ["metadataInput"];
  static values = { linkTokenUrl: String };

  declare linkTokenUrlValue: string;
  declare linkHandler: PlaidTypes.LinkHandler;
  declare metadataInputTarget: HTMLInputElement;

  async updateConnection() {
    // @ts-ignore
    this.linkHandler = Plaid.create({
      token: (await this.createLinkToken()).link_token,
      onSuccess: this.onSuccess,
      onExit: this.onExit,
    }) as PlaidTypes.LinkHandler;

    this.linkHandler.open();
  }

  async createLinkToken() {
    const response = await post(this.linkTokenUrlValue);
    const linkTokenData = response.json;

    if (response.ok) {
      localStorage.setItem(LINK_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(linkTokenData));
    }

    return linkTokenData;
  }

  onSuccess = (_publicToken, metadata) => {
    localStorage.removeItem(LINK_TOKEN_LOCAL_STORAGE_KEY);
    this.metadataInputTarget.value = JSON.stringify(metadata);
    this.element.requestSubmit();
  };

  onExit = (_err, _metadata) => {
    localStorage.removeItem(LINK_TOKEN_LOCAL_STORAGE_KEY);
  }
}
